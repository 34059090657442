// import { useState, useEffect } from 'react';
// import { useRef } from 'react';
// import { RavshanHttps } from '../../fetchUrls';
// import customHeaders, { getSpace } from '../../common/headers';
// import { useNavigate, useParams } from 'react-router-dom';
// import { useDataTable } from '../../common/hooks';
// import useGeneralStore from '../../store/general';
// import './CrmTablesList.scss';

// import {
//   Tabs,
//   TableBox,
//   ButtonBasic,
//   DropDownSelector,
// } from '@lk-gtcom/ecomlab-components';
// import { CrmModal } from '../CrmCreateTable/CrmModal';

// const CrmTabs = [
//   {
//     label: 'Все',
//     value: 'all',
//   },
//   {
//     label: 'Активные',
//     value: 'notifications',
//   },
//   {
//     label: 'Неактивные',
//     value: 'unactiv',
//   },
// ];

// const CrmTablesList = () => {
//   const selectedSpace = useGeneralStore((state) => state.selectedSpace);
//   const headers = { ...customHeaders, ...getSpace(selectedSpace) };
//   const navigate = useNavigate();
//   const [tableList, setTableList] = useState([]);
//   const [attributes, setAttributes] = useState([]);
//   const [tabs, setTabs] = useState([]);
//   const [pageValue, setPageValue] = useState(null);
//   const paginatorRef = useRef();
//   const [setTable, tableProps, setTableFuncs] = useDataTable(
//     paginatorRef.current?.setTotal,
//     paginatorRef
//   );
//   const { loading, sort, filters } = tableProps;
//   const { setLoading, setFetchedData, setHeaders, setSort, setFilters } =
//     setTableFuncs;

//   const [date, setDate] = useState([
//     new Date().getTime() - 604800000,
//     new Date().getTime(),
//   ]);

//   const fetchTableData = (params) => {
//     // setLoading(true)
//     // resetTable()

//     let url;
//     if (!window.location.hostname.match('localhost')) {
//       url = `${RavshanHttps}/api/custom_table/get_custom_table_list`;
//     } else {
//       url = `${RavshanHttps}/api/custom_table/get_custom_table_list`;
//     }

//     let [date_from, date_to] = date;
//     date_from = new Date(date_from + 86401000)?.toISOString()?.split('T')?.[0];
//     date_to = new Date(date_to)?.toISOString()?.split('T')?.[0];

//     const body = JSON.stringify({
//       date_from: date_from,
//       date_to: date_to,
//       limit: paginatorRef.current.limit,
//       page: paginatorRef.current.page,
//     });

//     fetch(url, { method: 'POST', headers, body })
//       .then((res) => {
//         if (res.ok) {
//           return res.json();
//         } else {
//           throw new Error('Error: get_tables_list request');
//         }
//       })
//       .then((json) => {
//         setTable(json);
//       })
//       .catch((err) => {
//         // setLoading(false)
//         console.error(err);
//       });
//   };

//   const onChooseHandler = (tableId) => {
//     let url;
//     if (!window.location.hostname.match('localhost')) {
//       url = `${RavshanHttps}/api/crm/get_tables_attr`;
//     } else {
//       url = `${RavshanHttps}/api/crm/get_tables_attr`;
//     }

//     fetch(url, { method: 'GET', headers: { ...headers, 'table-id': tableId } })
//       .then((res) => {
//         if (res.ok) {
//           return res.json();
//         } else {
//           throw new Error('ERROR: get_tables_attr request');
//         }
//       })
//       .then((json) => {
//         setAttributes(json);
//       })
//       .catch((err) => {
//         console.error(err);
//       });
//   };

//   const onDeleteHandler = (table_id) => {
//     let url;
//     if (!window.location.hostname.match('localhost')) {
//       url = `${RavshanHttps}/api/crm/delete_table`;
//     } else {
//       url = `${RavshanHttps}/api/crm/delete_table`;
//     }

//     const body = JSON.stringify({
//       table_id,
//     });

//     fetch(url, { body, method: 'DELETE', headers })
//       .then((res) => {
//         if (res.ok) {
//           return res.json();
//         } else {
//           throw new Error('error: delete_table request');
//         }
//       })
//       .then((json) => {
//         alert(json);
//         fetchTableData();
//       })
//       .catch((err) => {
//         console.error(err);
//         alert(err);
//       });
//   };

//   useEffect(() => {
//     onChooseHandler();
//   }, []);

//   useEffect(() => {
//     fetchTableData();
//   }, []);

//   useEffect(() => {}, [tableProps]);

//   const activeTab = useParams()['*'];
//   useEffect(() => {
//     setPageValue(activeTab);
//   }, [activeTab]);

//   const [isModalOpen, setIsModalOpen] = useState(false);

//   return (
//     <div className="CRM">
//       <h2 className="title_main">Список таблиц</h2>

//       <div className="toolbar-top">
//         <DropDownSelector
//           className="connections_page_selector"
//           placeholder="Фильтр"
//           options_prop={[]}
//           multi
//         />
//         <DropDownSelector
//           className="connections_page_selector"
//           placeholder="Фильтр"
//           multi
//           options_prop={[]}
//         />
//         <DropDownSelector
//           className="connections_page_selector"
//           placeholder="Фильтр"
//           multi
//           options_prop={[]}
//         />

//         {/*<ButtonBasic text={'Создать таблицу'} onClick={() => navigate('../create-table')}/>*/}
//         <ButtonBasic
//           text={'Создать таблицу'}
//           onClick={() => setIsModalOpen(true)}
//         />
//       </div>

//       <Tabs
//         tabs={CrmTabs}
//         pageValue={pageValue}
//         setPageValue={(e) => setPageValue(e)}
//       />

//       {/*<Tabs tabs={CrmTabs} pageValue={pageValue} />*/}
//       <div style={{ minWidth: '100wh' }}>
//         <TableBox
//           {...tableProps}
//           ref={paginatorRef}
//           paginator={true}
//           fetchCallback={(e) => fetchTableData(e)}
//         />
//       </div>
//       <CrmModal
//         isOpen={isModalOpen}
//         fetchTableData={(e) => fetchTableData(e)}
//         close={() => setIsModalOpen(false)}
//       />
//     </div>
//   );
// };

// export { CrmTablesList };

import { useState, useEffect } from 'react';
import { useRef } from 'react';
import { RavshanHttps } from '../../fetchUrls';
import customHeaders, { getSpace } from '../../common/headers';
import { useNavigate, useParams } from 'react-router-dom';
import { useDataTable } from '../../common/hooks';
import useGeneralStore from '../../store/general';
import './CrmTablesList.scss';

import {
  Tabs,
  TableBox,
  ButtonBasic,
  DropDownSelector,
} from '@lk-gtcom/ecomlab-components';
import { CrmModal } from '../CrmCreateTable/CrmModal';
import { logo } from '../../common/svg_img';

const CrmTabs = [
  {
    label: 'Все',
    value: 'all',
  },
  {
    label: 'Активные',
    value: 'notifications',
  },
  {
    label: 'Неактивные',
    value: 'unactiv',
  },
];

const CrmTablesList = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const navigate = useNavigate();
  const [tableList, setTableList] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [pageValue, setPageValue] = useState(null);
  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { loading, sort, filters } = tableProps;
  const { setLoading, setFetchedData, setHeaders, setSort, setFilters } =
    setTableFuncs;

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const contextMenuArr = [
    {
      label: 'Изменить',
      onClick: ({ uid }) => {
        setSelectedRowId(uid);
        setIsModalOpen(true);
        // onGetField(uid);
        // onGetData(uid);
      },
    },
    {
      label: 'Удалить',
      onClick: ({ uid }) => {
        onDeleteHandler(uid);
      },
    },
  ];

  const fetchTableData = (params) => {
    // setLoading(true)
    // resetTable()

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/custom_table/get_custom_table_list`;
    } else {
      url = `${RavshanHttps}/api/custom_table/get_custom_table_list`;
    }

    let [date_from, date_to] = date;
    date_from = new Date(date_from + 86401000)?.toISOString()?.split('T')?.[0];
    date_to = new Date(date_to)?.toISOString()?.split('T')?.[0];

    const body = JSON.stringify({
      // date_from: date_from,
      // date_to: date_to,
      limit: paginatorRef.current.limit,
      page: paginatorRef.current.page,
    });

    fetch(url, { method: 'POST', headers, body })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('Error: get_tables_list request');
        }
      })
      .then((json) => {
        setTable(json);
      })
      .catch((err) => {
        // setLoading(false)
        console.error(err);
      });
  };

  // const onChooseHandler = (tableId) => {
  //   let url;
  //   if (!window.location.hostname.match('localhost')) {
  //     url = `${RavshanHttps}/api/crm/get_tables_attr`;
  //   } else {
  //     url = `${RavshanHttps}/api/crm/get_tables_attr`;
  //   }

  //   fetch(url, { method: 'GET', headers: { ...headers, 'table-id': tableId } })
  //     .then((res) => {
  //       if (res.ok) {
  //         return res.json();
  //       } else {
  //         throw new Error('ERROR: get_tables_attr request');
  //       }
  //     })
  //     .then((json) => {
  //       setAttributes(json);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };

  // const onGetField = (table_uid) => {
  //   let url;
  //   if (!window.location.hostname.match('localhost')) {
  //     url = `${RavshanHttps}/api/custom_table/get_custom_table_list_field`;
  //   } else {
  //     url = `${RavshanHttps}/api/custom_table/get_custom_table_list_field`;
  //   }

  //   const body = JSON.stringify({
  //     table_uid,
  //   });

  //   fetch(url, { method: 'POST', headers, body })
  //     .then((res) => {
  //       if (res.ok) {
  //         return res.json();
  //       } else {
  //         throw new Error('ERROR: get_tables_attr request');
  //       }
  //     })
  //     .then((json) => {
  //       setAttributes(json);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };
  // const onGetData = (table_uid) => {
  //   let url;
  //   if (!window.location.hostname.match('localhost')) {
  //     url = `${RavshanHttps}/api/custom_table/get_custom_table_data`;
  //   } else {
  //     url = `${RavshanHttps}/api/custom_table/get_custom_table_data`;
  //   }

  //   const body = JSON.stringify({
  //     table_uid,
  //   });

  //   fetch(url, { method: 'POST', headers, body })
  //     .then((res) => {
  //       if (res.ok) {
  //         return res.json();
  //       } else {
  //         throw new Error('ERROR: get_tables_attr request');
  //       }
  //     })
  //     .then((json) => {
  //       setAttributes(json);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };

  const onDeleteHandler = (table_uid) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/custom_table/delete_custom_table`;
    } else {
      url = `${RavshanHttps}/api/custom_table/delete_custom_table`;
    }

    const body = JSON.stringify({
      table_uid,
    });

    fetch(url, { body, method: 'DELETE', headers })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('error: delete_table request');
        }
      })
      .then((json) => {
        alert(json);
        fetchTableData();
      })
      .catch((err) => {
        console.error(err);
        alert(err);
      });
  };

  // useEffect(() => {
  //   onChooseHandler();
  // }, []);

  useEffect(() => {
    fetchTableData();
  }, []);

  useEffect(() => {}, [tableProps]);

  const activeTab = useParams()['*'];
  useEffect(() => {
    setPageValue(activeTab);
  }, [activeTab]);

  const handleRowClick = (row) => {
    setSelectedRowId(row.id);
    setIsModalOpen(true);
  };

  return (
    <div className="CRM">
      <h2 className="title_main">Список таблиц</h2>

      <div className="toolbar-top">
        <DropDownSelector
          className="connections_page_selector"
          placeholder="Фильтр"
          options_prop={[]}
          multi
        />
        <DropDownSelector
          className="connections_page_selector"
          placeholder="Фильтр"
          multi
          options_prop={[]}
        />
        <DropDownSelector
          className="connections_page_selector"
          placeholder="Фильтр"
          multi
          options_prop={[]}
        />

        <ButtonBasic
          text={'Создать таблицу'}
          onClick={() => setIsModalOpen(true)}
        />
      </div>

      <Tabs
        tabs={CrmTabs}
        pageValue={pageValue}
        setPageValue={(e) => setPageValue(e)}
      />

      <div style={{ minWidth: '100wh' }}>
        <TableBox
          {...tableProps}
          ref={paginatorRef}
          paginator={true}
          fetchCallback={(e) => fetchTableData(e)}
          onAction={(e) => {
            console.log(e);
          }}
          contextMenuArr={contextMenuArr}
        />
      </div>

      <CrmModal
        isOpen={isModalOpen}
        tableId={selectedRowId}
        setTableId={setSelectedRowId}
        fetchTableData={(e) => fetchTableData(e)}
        close={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export { CrmTablesList };
