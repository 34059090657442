import React, { forwardRef, useEffect, useState, useImperativeHandle } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import { renderToString } from 'react-dom/server'
import quillEmoji from 'quill-emoji';
import 'react-quill/dist/quill.snow.css';
import load from './img/bx-load.svg'
import smile from './img/bx-smile.svg'
import EmojiPicker from 'emoji-picker-react';
import { DownloadComponent } from '../DownloadComponent/DownloadComponent';
import file_icon from './img/bx-file-blank.svg'
import download_icon from './img/bx-download.svg'
import close from './img/close.svg'
import { FileItemComponent } from '../FileItemComponent/FileItemComponent';
import './Editor.scss'
import _ from 'lodash';
const parse = require('html-react-parser')


const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

Quill.register({
    'formats/emoji': EmojiBlot,
    'modules/emoji-shortname': ShortNameEmoji,
    'modules/emoji-toolbar': ToolbarEmoji,
    'modules/emoji-textarea': TextAreaEmoji
}, true);

const editorFormats = ['size', 'bold', 'italic', 'underline', 'blockquote', 'color', 'list', 'bullet', 'indent', 'link', 'image', 'video', 'emoji']
const editorModules = {
    toolbar: [
        [[], [], [], { 'font': [] }, [], [], [], { size: [] }], [], [], [], [], [], [],
        ['link', 'image', 'video', { 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }], [], [],
        ['bold', 'italic', 'underline', 'blockquote', 'code-block', 'color', 'background'],
        ['emoji'],
    ],
    'emoji-toolbar': true,
    "emoji-textarea": true,
    "emoji-shortname": true,
}

const smallEditoModules = {
    toolbar: [
        // [{ 'indent': '+1' }, 'link', 'image', 'video', { 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, 'bold', 'italic', 'underline', 'blockquote', 'code-block', 'color', 'background'],
    ]

}

const Editor = forwardRef(({
    placeholder = '',
    bounds = '',
    hideModules = true,
    noModules = false,
    description = '',
    saveOnBtn = false,
    setDescription = false,
    title = 'Добавьте описание',
    comment = false,
    editValue = false,
    disabled = false,
    filesdata,
    setFiles,
    setFilesData,
    authorEmail,
    isEditComment,
    hideBtn
}, editorRef) => {

    const [value, setValue] = useState(editValue ? editValue : '')
    const [previusDesc, setPreviusDesc] = useState('')
    const [text, setText] = useState('')
    const [theme, setTheme] = useState('snow')
    const [isEdit, setIsEdit] = useState(!!editValue ? !!editValue : false)
    const [showImg, setShowImg] = useState('')

    useImperativeHandle(editorRef, () => ({
        reset() {
            setValue('')
            setText('')
            setPreviusDesc('')
        }

    }))

    const stringToHtml = (str) => {
        return parse(str, {
            replace: (domNode) => {
                if (domNode.name === "img") {
                    return <img src={domNode.attribs.src}
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                            e.stopPropagation()
                            setShowImg(domNode.attribs.src)
                        }}
                    />
                }
            },
        })
    }

    const onSaveDesc = (html) => {
        if (setDescription) setDescription(renderToString(html) ? renderToString(html) : ' ')
    }

    const onSaveHandler = (value) => {
        setValue(value)
        const html = stringToHtml(value)
        setText(html)
        if (saveOnBtn) return
        onSaveDesc(html)
    }

    useEffect(() => {
        if (description?.length > 0 & !isEdit) {
            setText(stringToHtml(description))
            setValue(description)
        }
    }, [description])

    useEffect(() => {
        if (!previusDesc) setPreviusDesc(description ? description : 'none')
    }, [description, isEdit])

    const [isEmoji, setIsEmoji] = useState(false)

    useEffect(() => {
        const hideMenu = (e) => {
            let el = e.target
            if (!el.closest('.redactor-wrapper__emoji-box')) {
                setIsEmoji(false)
            }
        }
        document.addEventListener('click', hideMenu)

        return () => {
            document.addEventListener('click', hideMenu)
        }
    }, [])

    const [isSaveFiles, setIsSaveFiles] = useState(false)

    return (
        <>
            {showImg && <div className='show-img' onClick={(e) => setShowImg('')}>
                <img src={showImg} />
            </div>}

            <div className={disabled ? 'editor-component_disabled' : 'editor-component'}>
                <div className='redactor-wrapper'>
                    {title && <div className='redactor-wrapper__title'>{title}</div>}
                    <div className='redactor-wrapper__field'>
                        {
                            isEdit ?
                                <>
                                    <div className='text-content' style={{ cursor: 'pointer' }} onClick={e => setIsEdit(true)}>
                                        <ReactQuill
                                            tabIndex='0'
                                            value={value}
                                            onChange={onSaveHandler}
                                            placeholder={placeholder}
                                            bounds={'.redactor-wrapper'}
                                            formats={editorFormats}
                                            modules={noModules ? { toolbar: [] } : hideModules ? smallEditoModules : editorModules}
                                            theme={theme}
                                        ></ReactQuill>
                                    </div>
                                </>
                                :
                                ((!comment) ?
                                    <div className='text-content' style={{ cursor: 'pointer' }} onClick={e => {
                                        if (!disabled) {
                                            setIsEdit(true)
                                        }
                                    }}>
                                        <p className='text'
                                            onClick={e => {
                                                if (e?.target?.localName === 'a') {
                                                    e.stopPropagation()
                                                }
                                            }}

                                        >
                                            {text ? text : placeholder}
                                        </p>
                                    </div>
                                    :
                                    <div className='comment__input-field' onClick={(e) => {
                                        e.stopPropagation()
                                        if (!disabled) {
                                            setIsEdit(true)
                                        }
                                    }}>
                                        <input placeholder='Оставьте комментарий' disabled={disabled} />
                                    </div>
                                )
                        }

                        {filesdata?.length > 0 && <div className='redactor-wrapper__files'>
                            {setFilesData && filesdata?.map(({ created_at, file_size_kb, new_filename, url }, ind) => {
                                return <FileItemComponent
                                    isEdit={isEdit}
                                    created_at={created_at}
                                    file_size_kb={file_size_kb}
                                    new_filename={new_filename}
                                    url={url}
                                    authorEmail={authorEmail}
                                    filesdata={filesdata}
                                    setFilesData={(e) => setFilesData(e)}
                                />
                            })}
                        </div>}
                    </div>
                </div >

                {
                    (isEdit || isEditComment) ?
                        <div className='btn-group'>
                            {!hideBtn && <button
                                className='redactor-wrapper__btn'
                                key={'clear-btn'}
                                onClick={e => {
                                    setTimeout(() => setIsEdit(false), 1000)
                                    //setText(previusDesc !== 'none' ? previusDesc : '')
                                    setPreviusDesc(value)
                                }}>Отмена</button>}

                            {!hideBtn && <button
                                className='redactor-wrapper__btn'
                                key={'add-btn'}
                                onClick={e => {
                                    setTimeout(() => setIsEdit(false), 1000)
                                    setIsSaveFiles(true)
                                    setPreviusDesc(value)
                                    if (saveOnBtn) onSaveDesc(text)
                                }}>Сохранить</button>}


                            {isEdit && <div className='redactor-wrapper__toolbar-bottom'>
                                <div className='redactor-wrapper__emoji-box'>
                                    <button className='redactor-wrapper__btn-emoji'
                                        onClick={(e) => setIsEmoji(!isEmoji)}
                                    >
                                        <img src={smile} />
                                    </button>

                                    {isEmoji && <div className='redactor-wrapper__emoji-drop'>
                                        <EmojiPicker
                                            style={{ width: '100%' }}
                                            width={100}
                                            height={300}
                                            //reactionsDefaultOpen={true}
                                            searchDisabled={true}
                                            // skinTonesDisabled
                                            onEmojiClick={(e) => {
                                                const { emoji } = e;
                                                let value_new = value.slice(0, -4)
                                                setValue(value?.length > 0 ? value_new + emoji + '</p>' : `<p>${value_new + emoji}</p>`)
                                                let input = document.querySelector("input");
                                                input.focus();
                                                input.selectionStart = input.value.length;
                                            }}
                                        />
                                    </div>}
                                </div>

                                {(filesdata && setFiles) && <button className='redactor-wrapper__btn-load'>
                                    <img src={load} />
                                    {
                                        // (email == authorEmail) &&
                                        <DownloadComponent
                                            isSaveFiles={isSaveFiles}
                                            setIsSaveFiles={(e) => setIsSaveFiles(e)}
                                            filesdata={filesdata}
                                            // authorEmail={email}
                                            setFilesArr={(e) => setFiles(e)}
                                            setFilesData={(e) => setFilesData(e)}
                                        />}
                                </button>}
                            </div>}
                        </div>
                        :
                        null
                }

            </div>
        </>

    )
})

export { Editor }