import customHeaders, { getSpace } from '../../common/headers';
import { LineChart } from '../../charts/LineChart/LineChart';
import { SliderRange } from '../Slider/Slider';
import { useChartData } from '../../common/hooks';
import { BarChartsVer2 } from '../../charts/BarChartsVer2/BarChartsVer2';
import { AndreySkuHttps, AndreySkuHttp } from '../../fetchUrls';
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { TooltipLightBlue } from '../Tooltip/TooltipLightBlue/TooltipLightBlue';
import {
  Tabs,
  SearchGlobalInput,
  MetricsItem,
  TableBox,
  RangeBox,
  DropContainer,
  DropDownSelector,
  CheckboxToggleBlue,
  Checkbox,
} from '@lk-gtcom/ecomlab-components';
import useGeneralStore from '../../store/general';
import './AnalysisPlatformByBrand.scss';

const btn_tab = [
  {
    label: 'Топ 30',
    value: 'top',
  },
  {
    label: 'Товары',
    value: 'goods',
  },
  {
    label: 'Категории',
    value: 'category',
  },
  {
    label: 'Продавцы',
    value: 'sellers',
  },
  {
    label: 'По дням',
    value: 'days',
  },
  {
    label: 'По типам продаж',
    value: 'working_scheme',
  },
  {
    label: 'Ценовые сегменты',
    value: 'price_segment',
  },
];

const sales_type = [
  { value: 5, label: 'Все' },
  { value: 1, label: 'FBO' },
  { value: 2, label: 'FBS' },
  { value: 3, label: 'Из-за рубежа' },
  { value: 4, label: 'RFBS' },
];

const formateDate = (date) => {
  const timeZone = (new Date().getTimezoneOffset() / 60) * -3600000;
  const formate_date = new Date(
    new Date(date)?.getTime() + timeZone
  )?.toUTCString();
  return new Date(formate_date);
};

const AnalysisPlatformByBrand = () => {
  const selectedSpace = useGeneralStore(state => state.selectedSpace)
  const headers = { ...customHeaders, ...getSpace(selectedSpace) }
  const [query, setQuery] = useState(null);
  const [pageValue, setPageValue] = useState(btn_tab[0].value);
  const [showDropBox, setShowDropBox] = useState(false);

  const [fetchedData, setFetchedData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [sort, setSort] = useState({});
  const [loading, setLoading] = useState(false);
  const [columnSize, setColumnSize] = useState([]);

  const [search, setSearch] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchData, setSearchData] = useState([]);

  const paginatorRef = useRef();
  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);

  const [minMaxRange, setMinMaxRange] = useState([0, 0]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [tableMetrics, setTableMetrics] = useState([]);
  const [tableMetric, setTableMetric] = useState('IDC');
  const [isReverse, setIsReverse] = useState(false);

  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamsLocal, setSearchParamsLocal] = useState();
  const [mp, setMp] = useState('ozon');
  const activeTab = useParams()['*'];
  const [selectedMarketplace, setSelectedMarketplace] = useState([]);

  const [priceRange, setPriceRange] = useState([0, 20000]);
  const [segments, setSegments] = useState(1);

  const [checkedItems, setCheckedItems] = useState([]);

  const [metrics, setMetrics] = useState([]);
  const [selectedMetrics, setSelectedMetrics] = useState([]);

  const [setChartData, chartData, setChartFuncs] = useChartData();
  const { setChartLoading, resetChart } = setChartFuncs;

  const [checkFbs, setCheckFbs] = useState(false);

  const [dateOptions, setDateOptions] = useState([]);
  const [selectedDateOptions, setSelectedDateOptions] = useState({});
  const [selectedSalesType, setSelectedSalesType] = useState(sales_type?.at(0));
  const [choisePrice, setChoisePrice] = useState([]);

  const resetTable = () => {
    paginatorRef.current.reset();
    paginatorRef.current.setTotal(0);
    setSort({});
    setTableHeaders([]);
    setFetchedData([]);
  };

  const fetchChartData = (
    abortController = new AbortController(),
    clearOnSuccess = false
  ) => {
    setChartLoading(true);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${AndreySkuHttps}/api/v1/ecomru/competitors/${mp === 'ozon' ? 'ozon_analysis' : 'platform_analysis'
        }/brands/${pageValue}/graph/`;
    } else {
      url = `${AndreySkuHttp}/api/v1/ecomru/competitors/${mp === 'ozon' ? 'ozon_analysis' : 'platform_analysis'
        }/brands/${pageValue}/graph/`;
    }

    let searchId;
    let [date_from, date_to] = date;
    date_from = new Date(date_from + 86401000)?.toISOString()?.split('T')?.[0];
    date_to = new Date(date_to)?.toISOString()?.split('T')?.[0];
    let seller_data =
      pageValue == 'top'
        ? ''
        : mp == 'ozon'
          ? { brand_name_filter: search }
          : { brand_id_filter: [search] };

    const id_arr = selectedItems?.map((el) => {
      if (el) {
        if (pageValue === 'top' && el?.['Бренд']) {
          if (Array.isArray(el?.['Бренд'])) {
            const [img, name] = el?.['Бренд'];
            return el?.['Бренд'][0];
          }
        }
        if (pageValue === 'goods' && el?.['Товар']) {
          return el?.nm_id;
        }
        if (pageValue === 'working_scheme') {
          return el?.working_scheme;
        }
        if (pageValue === 'sellers') {
          if (mp == 'ozon') return el?.seller_name;
          if (mp == 'wb') return el?.seller_id;
        }
        if (pageValue === 'top' && mp == 'wb') {
          return el?.brand_id;
        }
        if (pageValue === 'category') {
          if (mp == 'ozon') return el?.category_name;
          if (mp == 'wb') return el?.category_id;
        }
      }
    });

    if (pageValue == 'top') {
      if (mp == 'ozon') searchId = { brand_name: id_arr };
      if (mp == 'wb') searchId = { brand_id: id_arr };
    }
    if (pageValue === 'goods') {
      searchId = { nm_id: id_arr };
    }
    if (pageValue === 'working_scheme') {
      searchId = { working_scheme: id_arr };
    }
    if (pageValue === 'sellers') {
      if (mp == 'ozon') searchId = { seller_name: id_arr };
      if (mp == 'wb') searchId = { seller_id: id_arr };
    }
    if (pageValue === 'queries') {
      searchId = { query: id_arr };
    }
    if (pageValue === 'category') {
      if (mp == 'ozon') searchId = { category_name: id_arr };
      if (mp == 'wb') searchId = { category_id: id_arr };
    }

    const priceData =
      pageValue == 'price_segment'
        ? {
          min_price: choisePrice[0],
          max_price: choisePrice[1],
        }
        : {};

    const body = JSON.stringify({
      date_period: selectedDateOptions?.value,
      page: 1,
      limit: 300,
      working_scheme_filter: checkedItems?.working_scheme_filter
        ? checkedItems?.working_scheme_filter
        : 5,
      //fbofbs_filter: checkFbs,
      // initial: !(chartData?.selectedLegend?.length > 0),
      metrics: [],
      metric_name: tableMetric,
      ...searchId,
      ...priceData,
      ...seller_data,
    });

    const body_ozon = JSON.stringify({
      date_period: selectedDateOptions?.value,
      page: 1,
      limit: 300,
      working_scheme_filter: checkedItems?.working_scheme_filter
        ? checkedItems?.working_scheme_filter
        : 5,
      metrics: [],
      metric_name: tableMetric,
      ...searchId,
      ...priceData,
      ...seller_data,
    });

    const body_wb = JSON.stringify({
      page: 1,
      limit: 300,
      date_from,
      date_to,
      fbofbs_filter: checkFbs,
      metric_name: tableMetric,
      metrics: selectedMetrics,
      ...searchId,
      ...priceData,
      ...seller_data,
    });

    const current_body = mp == 'wb' ? body_wb : body_ozon;

    fetch(url, {
      body: current_body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setChartData(json);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) return;
        setChartLoading(false);
      });
  };

  const fetchMetricsList = (
    abortController = new AbortController(),
    clearOnSuccess = false
  ) => {
    if (pageValue != 'working_scheme') {
      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${AndreySkuHttps}/api/v1/ecomru/competitors/${mp === 'wb' ? 'platform_analysis' : 'ozon_analysis'
          }/brands/${pageValue}/metric_list/`;
      } else {
        url = `${AndreySkuHttp}/api/v1/ecomru/competitors/${mp === 'wb' ? 'platform_analysis' : 'ozon_analysis'
          }/brands/${pageValue}/metric_list/`;
      }

      fetch(url, { method: 'GET', headers, signal: abortController.signal })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error(JSON.stringify(res.json()));
          }
        })
        .then((json) => {
          setTableMetrics(json);
          setTableMetric(json[0]?.value);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          if (abortController.signal.aborted) return;
          setChartLoading(false);
        });
    }
  };

  const fetchAllAccountData = (params) => {
    if (pageValue != 'top' && search?.length < 3) {
      resetTable();
      return;
    }
    let sort;
    let filters;

    if (params) {
      [sort, filters] = params;
    }
    setLoading(true);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${AndreySkuHttps}/api/v1/ecomru/competitors/${mp === 'wb' ? 'platform_analysis' : 'ozon_analysis'
        }/brands/${pageValue}/table/`;
    } else {
      url = `${AndreySkuHttp}/api/v1/ecomru/competitors/${mp === 'wb' ? 'platform_analysis' : 'ozon_analysis'
        }/brands/${pageValue}/table/`;
    }

    let date_from = formateDate(date[0]).toISOString().split('T')?.[0];
    let date_to = formateDate(date[1]).toISOString().split('T')?.[0];

    let fbofbs_filter = mp == 'wb' ? { fbofbs_filter: checkFbs } : false;
    let working_scheme_filter =
      mp == 'ozon'
        ? { working_scheme_filter: selectedSalesType?.value }
        : false;

    const price =
      pageValue == 'price_segment'
        ? {
          max_price: priceRange[1],
          min_price: priceRange[0],
          number_of_segments: +segments,
        }
        : {};

    const reverse =
      pageValue == 'days' || pageValue == 'working_scheme'
        ? { reverse: isReverse }
        : '';

    const body_wb = JSON.stringify({
      ...fbofbs_filter, //wb
      brandid_filter: [search], //wb
      limit: paginatorRef.current.limit,
      page: paginatorRef.current.page,
      date_from, //wb
      date_to, //wb
      sort_name: sort?.[0],
      sort_order: sort?.[1],
      ...reverse,
      filters,
      ...price,
    });

    const body_ozon = JSON.stringify({
      ...working_scheme_filter,
      brand_name_filter: [search],
      limit: paginatorRef.current.limit,
      page: paginatorRef.current.page,
      sort_name: sort?.[0],
      sort_order: sort?.[1],
      filters,
      ...reverse,
      date_period: selectedDateOptions?.value,
      ...price,
    });

    const current_body = mp == 'wb' ? body_wb : body_ozon;

    fetch(url, { body: current_body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        const { data, labels, total, sort, column_size } = json;
        if (total) paginatorRef.current.setTotal(total);
        if (sort) setSort(sort);
        if (labels) setTableHeaders(labels);
        if (column_size) setColumnSize(column_size);
        setFetchedData(data?.length > 0 ? data : [[]]);
        setTableUrl(url);
        setTableBody(current_body);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  const fetchSearchValue = (
    [search, page = 1],
    abortController = new AbortController()
  ) => {
    if (search?.length >= 3) {
      setLoading(true);

      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${AndreySkuHttps}/api/v1/ecomru/${mp === 'wb' ? 'keywords' : 'competitors/ozon_analysis'
          }/brands/search_string/`;
      } else {
        url = `${AndreySkuHttp}/api/v1/ecomru/${mp === 'wb' ? 'keywords' : 'competitors/ozon_analysis'
          }/brands/search_string/`;
      }

      let [date_from, date_to] = date;

      date_from = new Date(date_from + 86400000)?.toISOString().split('T')?.[0];
      date_to = new Date(date_to)?.toISOString().split('T')?.[0];

      const body = JSON.stringify({
        date_from,
        date_to,
        page,
        limit: 15,
        brand_search: search, //ozon
        search_text: search, //wb
      });

      fetch(url, {
        body,
        method: 'POST',
        headers,
        signal: abortController.signal,
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error(JSON.stringify(res.json()));
          }
        })
        .then((json) => {
          setSearchData({ brand: Object?.values(json)?.[0] });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          if (abortController.signal.aborted) return;
          setLoading(false);
        });
    }
  };

  const fetchDate = (page = 1) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${AndreySkuHttps}/api/v1/ecomru/competitors/ozon_analysis/date_period/`;
    } else {
      url = `${AndreySkuHttp}/api/v1/ecomru/competitors/ozon_analysis/date_period/`;
    }

    const body = JSON.stringify({
      limit: 50,
      page,
    });

    fetch(url, { body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        const fromattedArr = json?.map(({ period }) => ({
          label: period,
          value: period,
        }));
        setDateOptions(fromattedArr);
        setSelectedDateOptions(fromattedArr?.[0]);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  const fetchMinMaxPrice = () => {
    if (search?.length < 3) return;

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${AndreySkuHttps}/api/v1/ecomru/competitors/${mp === 'wb' ? 'platform_analysis' : 'ozon_analysis'
        }/brands/price_range/`;
    } else {
      url = `${AndreySkuHttp}/api/v1/ecomru/competitors/${mp === 'wb' ? 'platform_analysis' : 'ozon_analysis'
        }/brands/price_range/`;
    }

    const fbofbs_filter = mp == 'wb' ? { fbofbs_filter: checkFbs } : false;
    let [date_from, date_to] = selectedDateOptions?.value?.split(' - ') || [
      new Date(),
      new Date(),
    ];

    const body_wb = JSON.stringify({
      ...fbofbs_filter,
      brandid_filter: [search],
      date_from,
      date_to,
    });

    const body_ozon = JSON.stringify({
      brand_name_filter: [search],
      date_period: selectedDateOptions?.value,
      working_scheme_filter: selectedSalesType?.value,
    });

    const current_body = mp == 'wb' ? body_wb : body_ozon;

    fetch(url, { body: current_body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        if (json) {
          let { min_price, max_price } = json;
          setMinMaxRange([min_price, max_price]);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  // const onCheck = (items) => {
  //     if (pageValue === 'top') {
  //         const categoryIds = items?.map(({ category_id }) => category_id)
  //         const brandIds = items?.map((el) => {
  //             const brandName = el?.['Бренд']
  //             if (Array.isArray(brandName)) {
  //                 return brandName?.join('')
  //             } else {
  //                 return brandName
  //             }
  //         })
  //         setCheckedItems({ brand_name_filter: brandIds, category_id: categoryIds })
  //     }
  //     if (pageValue === 'category') {
  //         const categoryIds = items?.map(({ category_id }) => category_id)
  //         const category_name = items?.map((el) => {
  //             const brandName = el?.['Подкатегория']
  //             if (Array.isArray(brandName)) {
  //                 return brandName?.join('')
  //             } else {
  //                 return brandName
  //             }
  //         })
  //         setCheckedItems({ category_name, category_id: categoryIds })
  //     }

  //     if (pageValue === 'sellers') {
  //         const seller_ids = items?.map(({ seller_id }) => seller_id)
  //         const brandIds = items?.map(({ brand_id }) => brand_id)
  //         setCheckedItems({ brand_id: brandIds, seller_id: seller_ids })
  //     }

  //     if (pageValue === 'goods') {
  //         const nm_ids = items?.map(({ nm_id }) => nm_id)
  //         const brandIds = items?.map(({ brand_id }) => brand_id)
  //         setCheckedItems({ brand_id: brandIds, nm_id: nm_ids })
  //     }
  // }

  const handleCheck = (data) => {
    setSelectedItems(data);
  };

  const onAction = (e) => {
    setChoisePrice([e.min_price, e.max_price]);
  };

  useEffect(() => {
    const abortController = new AbortController();
    fetchMetricsList(abortController);
    return () => abortController.abort();
  }, [pageValue, mp, selectedSpace]);

  // useEffect(() => {
  //     const abortController = new AbortController()
  //     fetchChartData(abortController)
  //     return () => abortController.abort()
  // }, [checkedItems])

  useEffect(() => {
    if (searchParams) {
      setSearchParamsLocal(searchParams);
    }
  }, []);

  useEffect(() => {
    const param = searchParams.get('param');
    const dateFrom = searchParams.get('dateFrom');
    const dateTo = searchParams.get('dateTo');
    const mp = searchParams.get('mp');

    if (mp) setMp(mp);
    if (param) setSearchValue(param);
    if (dateFrom && dateTo) setDate([parseInt(dateFrom), parseInt(dateTo)]);
  }, [searchParams]);

  useEffect(() => {
    if (searchParamsLocal) {
      setSearchParams(searchParamsLocal);
    }
  }, [searchParamsLocal]);

  useEffect(() => {
    if (pageValue == 'price_segment') {
      fetchMinMaxPrice();
    }
  }, [selectedSpace, pageValue, selectedSalesType, selectedDateOptions, search, checkFbs]);

  useEffect(() => {
    setPageValue(activeTab);
    resetTable();
    resetChart();
  }, [activeTab]);



  useEffect(() => {
    if (pageValue !== 'price_segment') {
      fetchAllAccountData();
    }
  }, [
    selectedSpace,
    selectedMarketplace,
    search,
    date,
    pageValue,
    selectedSalesType,
    selectedDateOptions,
    checkFbs,
    selectedDateOptions,
    selectedSalesType,
    isReverse,
  ]);

  //графики
  useEffect(() => {
    if (pageValue == 'top' && selectedItems?.length > 0) {
      fetchChartData();
    }

    if (
      pageValue == 'goods' ||
      pageValue == 'category' ||
      pageValue == 'sellers'
    ) {
      if (selectedItems?.length > 0 && search?.length > 0) {
        fetchChartData();
      }
    }

    if (pageValue == 'days') {
      fetchChartData();
    }

    if (pageValue == 'price_segment') {
      if (choisePrice?.length > 0 && search?.length > 0) {
        fetchChartData();
      }
    }
  }, [
    selectedSpace,
    selectedItems,
    date,
    pageValue,
    search,
    checkFbs,
    tableMetric,
    choisePrice,
    selectedSalesType,
    selectedDateOptions,
  ]);

  useEffect(() => {
    fetchDate();
  }, []);

  useEffect(() => {
    const params = new URL(document.location).searchParams;
    let query = params.get('mp');
    setQuery(query);
    setMp(query);
  }, [pageValue, new URL(document.location)]);

  useEffect(() => {
    setSearch('');
    setSearchValue('');
    setSearchData([]);
    resetTable();
    setSegments(1);
    setMinMaxRange([0, 0]);
    setPageValue(btn_tab[0].value);
  }, [mp]);

  useEffect(() => {
    setChoisePrice([]);
  }, [search, pageValue]);

  useEffect(() => {
    setTableMetric([]);
    setTableMetrics([]);
  }, [pageValue]);

  useEffect(() => {
    resetChart();
  }, [pageValue]);

  return (
    <>
      <div className="analysis-platform-by-brand">
        <h1 className="title_main">Анализ брендов</h1>

        <div className="top-panel">
          <SearchGlobalInput
            {...searchData}
            fetchSearch={(e) => {
              const abortController = new AbortController();
              fetchSearchValue(e, abortController);
              return () => abortController.abort();
            }}
            setSearch={({ brand, extra }) => {
              if (extra) {
                setSearch(extra);
              } else {
                setSearch(brand);
              }
            }}
            value={searchValue}
            setSearchData={(e) => setSearchData(e)}
            onClearDependence={[mp]}
          />

          {mp == 'wb' && (
            <TooltipLightBlue
              text={
                <p className="text_tooltip">
                  FBO <span>(продажи со склада площадки)</span> по умолчанию.
                  <br />
                  <br />
                  Нажмите на переключатель чтобы увидеть FBS{' '}
                  <span>(продажи со склада продавца)</span>
                </p>
              }
              top="32px"
              left="0px"
              maxWidth="110px"
              child={
                <label className="checkbox-container">
                  <CheckboxToggleBlue
                    value={checkFbs}
                    onChange={(e) => setCheckFbs(!checkFbs)}
                  />
                  <p className="text_label">FBS</p>
                </label>
              }
            />
          )}
        </div>

        <div className="toolbar-medium">
          <Tabs tabs={btn_tab} query={`?mp=${query}`} pageValue={pageValue} />

          <div style={{ display: 'flex', gap: '.5em' }}>
            {mp !== 'wb' && (
              <DropDownSelector
                options_prop={dateOptions}
                state={selectedDateOptions}
                setState={(e) => setSelectedDateOptions(e)}
                placeholder="Дата"
                fetchCallback={(e) => fetchDate(e)}
                style={{ minWidth: '275px' }}
              />
            )}
            {mp !== 'wb' && (
              <DropDownSelector
                options_prop={sales_type}
                state={selectedSalesType}
                setState={(e) => setSelectedSalesType(e)}
                placeholder="Тип продаж"
                fetchCallback={(e) => { }}
                setInputChange={(e) => { }}
              />
            )}

            {mp === 'wb' && (
              <RangeBox setDate={(e) => setDate(e)} hideBtnGroup />
            )}
          </div>
        </div>

        {pageValue != 'working_scheme' && (
          <DropContainer
            // name={'Продажи и остатки'}
            showDropBox={showDropBox}
            setShowDropBox={(e) => setShowDropBox(e)}
            name={chartData?.title ? chartData?.title : ''}
            selectedMetric={tableMetric}
            setSelectedMetric={setTableMetric}
            metricsList={tableMetrics}
          >
            <div className="inside-content">
              <div className="chart-box">
                <LineChart {...chartData} />
              </div>
            </div>
          </DropContainer>
        )}

        {pageValue === 'price_segment' && (
          <SliderRange
            minValue={minMaxRange[0]}
            maxValue={minMaxRange[1]}
            setState={setPriceRange}
            sliderRange={priceRange}
            setSegments={(e) => setSegments(e)}
            segments={segments}
            range={priceRange}
            minMax
            callbackHandler={fetchAllAccountData}
          />
        )}

        <TableBox
          loading={loading}
          fetchedData={fetchedData}
          headers={tableHeaders}
          sort={sort}
          paginator={true}
          fetchCallback={(e) => fetchAllAccountData(e)}
          sortingFunc={(e) => fetchAllAccountData(e)}
          onAction={(e) => onAction(e)}
          ref={paginatorRef}
          onEdit={(e) => e}
          onDelete={(e) => e}
          onCheck={(e) => handleCheck(e)}
          onUncheckAllDependence={[pageValue, search, mp]}
          columnSize={columnSize}
          tableUrl={tableUrl}
          tableBody={tableBody}
          // isInsideTable
          child={
            (pageValue === 'days' || pageValue == 'working_scheme') && (
              <Checkbox
                value={isReverse}
                onChange={(checked) => setIsReverse(checked)}
              >Перевернуть</Checkbox>
            )
          }
        />
      </div>
    </>
  );
};

export { AnalysisPlatformByBrand };
