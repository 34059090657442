import customHeaders, { getSpace } from '../../common/headers';
import { useParams, useSearchParams } from 'react-router-dom';
import { LineChart } from '../../charts/LineChart/LineChart';
import { SliderRange } from '../Slider/Slider';
import { BarChartsVer2 } from '../../charts/BarChartsVer2/BarChartsVer2';
import { AlphabeticalSearch } from '../AlphabeticalSearch/AlphabeticalSearch';
import { useState, useEffect, useRef } from 'react';
import { useChartData } from '../../common/hooks';
import { AndreySkuHttps, AndreySkuHttp } from '../../fetchUrls';
import { TooltipLightBlue } from '../Tooltip/TooltipLightBlue/TooltipLightBlue';
import {
  Tabs,
  SearchGlobalInput,
  MetricsItem,
  TableBox,
  RangeBox,
  DropContainer,
  DropDownSelector,
  CheckboxToggleBlue,
  Checkbox,
} from '@lk-gtcom/ecomlab-components';
import useGeneralStore from '../../store/general';
import './AnalysisPlatformBySellers.scss';

// топ 30 выдает не 30 товаров

const btn_tab = [
  {
    label: 'Топ 30',
    value: 'top',
  },
  {
    label: 'Товары',
    value: 'goods',
  },
  {
    label: 'Категория',
    value: 'category',
  },
  {
    label: 'Бренды',
    value: 'brands',
  },
  {
    label: 'По периодам',
    value: 'days',
  },
  {
    label: 'По типам продаж',
    value: 'working_scheme',
  },
  {
    label: 'Ценовые сегменты',
    value: 'price_segment',
  },
];

const sales_type = [
  { value: 5, label: 'Все' },
  { value: 1, label: 'FBO' },
  { value: 2, label: 'FBS' },
  { value: 3, label: 'Из-за рубежа' },
  { value: 4, label: 'RFBS' },
];

const formateDate = (date) => {
  const timeZone = (new Date().getTimezoneOffset() / 60) * -3600000;
  const formate_date = new Date(
    new Date(date)?.getTime() + timeZone
  )?.toUTCString();
  return new Date(formate_date);
};

const AnalysisPlatformBySellers = () => {
  const selectedSpace = useGeneralStore(state => state.selectedSpace)
  const headers = { ...customHeaders, ...getSpace(selectedSpace) }
  const [query, setQuery] = useState(null);
  const [pageValue, setPageValue] = useState(btn_tab[0].value);
  const [showDropBox, setShowDropBox] = useState(false);

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);

  const [fetchedData, setFetchedData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [sort, setSort] = useState({});
  const [loading, setLoading] = useState(false);
  const [columnSize, setColumnSize] = useState([]);

  const [metrics, setMetrics] = useState([]);
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [checkedId, setCheckedId] = useState([]);

  const [selectedSalesType, setSelectedSalesType] = useState(sales_type?.at(0));

  const [searchValue, setSearchValue] = useState('');
  const [search, setSearch] = useState('');
  const [searchData, setSearchData] = useState([]);

  const [metricsList, setMetricsList] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState('IDC');

  const [checkedItems, setCheckedItems] = useState([]);

  const [selectedMarketplace, setSelectedMarketplace] = useState([]);
  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);

  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(20000);
  const [selectedItems, setSelectedItems] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 20000]);
  const [segments, setSegments] = useState(1);

  const [minMaxRange, setMinMaxRange] = useState([0, 0]);
  const [setChartData, chartData, setChartFuncs] = useChartData();
  const { setChartLoading, resetChart } = setChartFuncs;

  const [checkFbs, setCheckFbs] = useState(false);
  // const [chartLoading, setChartLoading] = useState(false)

  const [dateOptions, setDateOptions] = useState([]);
  const [selectedDateOptions, setSelectedDateOptions] = useState([]);

  const [tableMetrics, setTableMetrics] = useState([]);
  const [tableMetric, setTableMetric] = useState('IDC');
  const [isReverse, setIsReverse] = useState(false);
  const [choisePrice, setChoisePrice] = useState([]);

  const paginatorRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [mp, setMp] = useState('ozon');
  const activeTab = useParams()['*'];

  const resetTable = () => {
    paginatorRef.current.reset();
    paginatorRef.current.setTotal(0);
    setSort({});
    setTableHeaders([]);
    setFetchedData([]);
  };

  const fetchChartData = (abortController = new AbortController()) => {
    setChartLoading(true);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${AndreySkuHttps}/api/v1/ecomru/competitors/${mp === 'ozon' ? 'ozon_analysis' : 'platform_analysis'
        }/sellers/${pageValue}/graph/`;
    } else {
      url = `${AndreySkuHttp}/api/v1/ecomru/competitors/${mp === 'ozon' ? 'ozon_analysis' : 'platform_analysis'
        }/sellers/${pageValue}/graph/`;
    }

    let searchId;
    let [date_from, date_to] = date;
    date_from = new Date(date_from + 86401000)?.toISOString()?.split('T')?.[0];
    date_to = new Date(date_to)?.toISOString()?.split('T')?.[0];

    const id_arr = selectedItems?.map((el) => {
      if (el) {
        if (pageValue === 'top' && el?.['Продавец'] && mp == 'ozon') {
          if (Array.isArray(el?.['Продавец'])) {
            const [img, name] = el?.['Продавец'];
            return el?.['Продавец'][0];
          }
        }
        if (pageValue === 'goods') {
          return el?.nm_id;
        }
        if (pageValue === 'working_scheme') {
          return el?.working_scheme;
        }
        if (pageValue === 'top' && mp == 'wb') {
          return el?.seller_id;
        }
        if (pageValue === 'brands') {
          if (mp == 'ozon') return el?.brand_name;
          if (mp == 'wb') return el?.brand_id;
        }
        if (pageValue === 'category') {
          if (mp == 'ozon') return el?.category_name;
          if (mp == 'wb') return el?.category_id;
        }
      }
    });

    searchId =
      pageValue == 'top'
        ? mp == 'ozon'
          ? { seller_name: id_arr }
          : { seller_id: id_arr }
        : '';

    let seller_data =
      pageValue == 'top'
        ? ''
        : mp == 'ozon'
          ? { seller_name_filter: search }
          : { seller_id_filter: search };

    if (pageValue === 'goods') {
      searchId = { nm_id: id_arr };
    }
    if (pageValue === 'working_scheme') {
      searchId = { working_scheme: id_arr };
    }
    if (pageValue === 'brands') {
      if (mp == 'ozon') searchId = { brand_name: id_arr };
      if (mp == 'wb') searchId = { brand_id: id_arr };
    }
    if (pageValue === 'queries') {
      searchId = { query: id_arr };
    }
    if (pageValue === 'category') {
      if (mp == 'ozon') searchId = { category_name: id_arr };
      if (mp == 'wb') searchId = { category_id: id_arr };
    }

    const priceData =
      pageValue == 'price_segment'
        ? {
          min_price: choisePrice[0],
          max_price: choisePrice[1],
        }
        : {};

    const body_ozon = JSON.stringify({
      date_period: selectedDateOptions?.value,
      page: 1,
      limit: 300,
      metric_name: tableMetric,
      working_scheme_filter: selectedSalesType?.value,
      metrics: selectedMetrics,
      ...searchId,
      ...priceData,
      ...seller_data,
    });

    const body_wb = JSON.stringify({
      page: 1,
      limit: 300,
      date_from,
      date_to,
      fbofbs_filter: checkFbs,
      metric_name: tableMetric,
      metrics: selectedMetrics,
      ...searchId,
      ...priceData,
      ...seller_data,
    });

    const current_body = mp == 'wb' ? body_wb : body_ozon;

    fetch(url, {
      body: current_body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setChartData(json);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) return;
        setChartLoading(false);
      });
  };

  const fetchMetricsList = (
    abortController = new AbortController(),
    clearOnSuccess = false
  ) => {
    if (pageValue != 'working_scheme') {
      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${AndreySkuHttps}/api/v1/ecomru/competitors/${mp === 'wb' ? 'platform_analysis' : 'ozon_analysis'
          }/sellers/${pageValue}/metric_list/`;
      } else {
        url = `${AndreySkuHttp}/api/v1/ecomru/competitors/${mp === 'wb' ? 'platform_analysis' : 'ozon_analysis'
          }/sellers/${pageValue}/metric_list/`;
      }

      fetch(url, { method: 'GET', headers, signal: abortController.signal })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error(JSON.stringify(res.json()));
          }
        })
        .then((json) => {
          setTableMetrics(json);
          setTableMetric(json[0]?.value);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          if (abortController.signal.aborted) return;
          setChartLoading(false);
        });
    }
  };

  const AccountData = (
    params,
    abortController = new AbortController()
  ) => {
    if (pageValue != 'top' && search?.length < 3) {
      resetTable();
      return;
    }
    let sort;
    let filters;

    if (params) {
      [sort, filters] = params;
    }
    setLoading(true);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${AndreySkuHttps}/api/v1/ecomru/competitors/${mp === 'wb' ? 'platform_analysis' : 'ozon_analysis'
        }/sellers/${pageValue}/table/`;
    } else {
      url = `${AndreySkuHttp}/api/v1/ecomru/competitors/${mp === 'wb' ? 'platform_analysis' : 'ozon_analysis'
        }/sellers/${pageValue}/table/`;
    }

    let date_from = formateDate(date[0]).toISOString().split('T')?.[0];
    let date_to = formateDate(date[1]).toISOString().split('T')?.[0];

    let fbofbs_filter = mp == 'wb' ? { fbofbs_filter: checkFbs } : false;
    let working_scheme_filter =
      mp == 'ozon'
        ? { working_scheme_filter: selectedSalesType?.value }
        : false;

    const price =
      pageValue == 'price_segment'
        ? {
          max_price: priceRange[1],
          min_price: priceRange[0],
          number_of_segments: +segments,
        }
        : {};

    const reverse =
      pageValue == 'days' || pageValue == 'working_scheme'
        ? { reverse: isReverse }
        : '';

    const body_wb = JSON.stringify({
      ...fbofbs_filter, //wb
      sellerid_filter: [search], //wb
      limit: paginatorRef.current.limit,
      page: paginatorRef.current.page,
      date_from, //wb
      date_to, //wb
      sort_name: sort?.[0],
      sort_order: sort?.[1],
      filters,
      ...reverse,
      ...price,
    });

    const body_ozon = JSON.stringify({
      ...working_scheme_filter,
      seller_name_filter: search,
      limit: paginatorRef.current.limit,
      page: paginatorRef.current.page,
      sort_name: sort?.[0],
      sort_order: sort?.[1],
      filters,
      date_period: selectedDateOptions?.value,
      ...reverse,
      ...price,
    });

    const current_body = mp == 'wb' ? body_wb : body_ozon;

    fetch(url, {
      body: current_body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => res.json())
      .then((json) => {
        const { data, labels, total, sort, column_size } = json;
        if (total) paginatorRef.current.setTotal(total);
        if (sort) setSort(sort);
        if (labels) setTableHeaders(labels);
        if (column_size) setColumnSize(column_size);
        setFetchedData(data?.length > 0 ? data : [[]]);
        setTableUrl(url);
        setTableBody(current_body);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  const resetFilter = () => {
    setSearchData([]);
  };

  const fetchSearchValue = ([search, page]) => {
    if (search?.length >= 3) {
      setLoading(true);

      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${AndreySkuHttps}/api/v1/ecomru/${mp === 'wb' ? 'keywords' : 'competitors/ozon_analysis'
          }/sellers/search_string/`;
      } else {
        url = `${AndreySkuHttp}/api/v1/ecomru/${mp === 'wb' ? 'keywords' : 'competitors/ozon_analysis'
          }/sellers/search_string/`;
      }

      let [date_from, date_to] = date;

      date_from = new Date(date_from + 10)?.toISOString().split('T')?.[0];
      date_to = new Date(date_to)?.toISOString().split('T')?.[0];

      const body = JSON.stringify({
        date_from,
        date_to,
        page: 1,
        limit: 15,
        seller_search: search,
        seller: search,
      });

      fetch(url, { body, method: 'POST', headers })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error(JSON.stringify(res.json()));
          }
        })
        .then((json) => {
          setSearchData({ seller: Object?.values(json)?.[0] });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => setLoading(false));
    }
  };

  const fetchDate = (page = 1) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${AndreySkuHttps}/api/v1/ecomru/competitors/ozon_analysis/date_period/`;
    } else {
      url = `${AndreySkuHttp}/api/v1/ecomru/competitors/ozon_analysis/date_period/`;
    }

    const body = JSON.stringify({
      limit: 50,
      page,
    });

    fetch(url, { body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        const fromattedArr = json?.map(({ period }) => ({
          label: period,
          value: period,
        }));
        setDateOptions(fromattedArr);
        setSelectedDateOptions(fromattedArr?.[0]);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  const fetchMinMaxPrice = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${AndreySkuHttps}/api/v1/ecomru/competitors/${mp === 'wb' ? 'platform_analysis' : 'ozon_analysis'
        }/sellers/price_range/`;
    } else {
      url = `${AndreySkuHttp}/api/v1/ecomru/competitors/${mp === 'wb' ? 'platform_analysis' : 'ozon_analysis'
        }/sellers/price_range/`;
    }

    const fbofbs_filter = mp == 'wb' ? { fbofbs_filter: checkFbs } : false;
    let [date_from, date_to] = selectedDateOptions?.value?.split(' - ') || [
      new Date(),
      new Date(),
    ];

    const body_wb = JSON.stringify({
      ...fbofbs_filter,
      sellerid_filter: [search], //wb []
      date_from,
      date_to,
    });

    const body_ozon = JSON.stringify({
      seller_name_filter: [search],
      date_period: selectedDateOptions?.value,
      working_scheme_filter: selectedSalesType?.value,
    });

    const current_body = mp == 'wb' ? body_wb : body_ozon;

    fetch(url, { body: current_body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        if (json) {
          let { min_price, max_price } = json;
          setMinMaxRange([min_price, max_price]);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  const handleCheck = (data) => {
    setSelectedItems(data);
  };

  const onAction = (e) => {
    setChoisePrice([e.min_price, e.max_price]);
  };
  useEffect(() => {
    const abortController = new AbortController();
    fetchMetricsList(abortController);
    return () => abortController.abort();
  }, [pageValue, selectedSpace]);

  useEffect(() => {
    if (pageValue == 'top' && selectedItems?.length > 0) {
      fetchChartData();
    }

    if (
      pageValue == 'goods' ||
      pageValue == 'category' ||
      pageValue == 'brands'
    ) {
      if (selectedItems?.length > 0 && search?.length > 0) {
        fetchChartData();
      }
    }

    if (pageValue == 'days') {
      fetchChartData();
    }

    if (pageValue == 'price_segment') {
      if (choisePrice?.length > 0 && search?.length > 0) {
        fetchChartData();
      }
    }
  }, [
    selectedSpace,
    chartData?.selectedLegend,
    selectedItems,
    date,
    pageValue,
    search,
    checkFbs,
    tableMetric,
    choisePrice,
    selectedSalesType,
    selectedDateOptions,
  ]);

  useEffect(() => {
    const mp = searchParams.get('mp');

    if (mp) setMp(mp);
  }, [searchParams]);


  useEffect(() => {
    setPageValue(activeTab);
    //  resetChart()
    resetTable();
  }, [activeTab]);

  useEffect(() => {
    if (pageValue == 'price_segment') {
      fetchMinMaxPrice();
    }
  }, [selectedSpace, pageValue, selectedSalesType, selectedDateOptions, search, checkFbs]);

  useEffect(() => {
    const abortController = new AbortController();

    if (pageValue !== 'price_segment') {
      AccountData([], abortController);
    }
    return () => abortController.abort();
  }, [
    selectedSpace,
    selectedMarketplace,
    search,
    date,
    pageValue,
    selectedSalesType,
    selectedDateOptions,
    checkFbs,
    selectedDateOptions,
    selectedSalesType,
    isReverse,
  ]);

  useEffect(() => {
    fetchDate();
  }, []);

  useEffect(() => {
    const params = new URL(document.location).searchParams;
    let query = params.get('mp');
    setQuery(query);
    setMp(query);
  }, [pageValue, new URL(document.location)]);

  useEffect(() => {
    setSearch('');
    setSearchValue('');
    setSearchData([]);
    resetTable();
    setSegments(1);
    setMinMaxRange([0, 0]);
    setPageValue(btn_tab[0].value);
  }, [mp]);

  useEffect(() => {
    resetChart();
    setTableMetric([]);
    setTableMetrics([]);
  }, [pageValue, mp]);

  useEffect(() => {
    setChoisePrice([]);
  }, [mp]);

  return (
    <div className="analysis-platform-by-sellers">
      <h1 className="title_main">Анализ по продавцам</h1>

      <div className="top-panel">
        <SearchGlobalInput
          {...searchData}
          fetchSearch={(e) => {
            const abortController = new AbortController();
            fetchSearchValue(e, abortController);
            return () => abortController.abort();
          }}
          setSearch={({ seller, extra }) => {
            if (extra) {
              setSearch(extra);
            } else {
              setSearch(seller);
            }
          }}
          value={searchValue}
          setSearchData={(e) => setSearchData(e)}
          onClearDependence={[mp]}
        />
        {mp == 'wb' && (
          <TooltipLightBlue
            text={
              <p className="text_tooltip">
                FBO <span>(продажи со склада площадки)</span> по умолчанию.
                <br />
                <br />
                Нажмите на переключатель чтобы увидеть FBS{' '}
                <span>(продажи со склада продавца)</span>
              </p>
            }
            top="32px"
            left="0px"
            maxWidth="110px"
            child={
              <label className="checkbox-container">
                <CheckboxToggleBlue
                  value={checkFbs}
                  onChange={(e) => setCheckFbs(!checkFbs)}
                />
                <p className="text_label">FBS</p>
              </label>
            }
          />
        )}
      </div>

      <div className="analysis-platform-by-sellers">
        <div className="toolbar-medium">
          <Tabs tabs={btn_tab} query={`?mp=${query}`} pageValue={pageValue} />

          <div style={{ display: 'flex', gap: '.5em' }}>
            {mp !== 'wb' && (
              <DropDownSelector
                options_prop={dateOptions}
                state={selectedDateOptions}
                setState={(e) => setSelectedDateOptions(e)}
                placeholder="Дата"
                fetchCallback={(e) => fetchDate(e)}
                style={{ minWidth: '275px' }}
              />
            )}
            {mp !== 'wb' && (
              <DropDownSelector
                options_prop={sales_type}
                state={selectedSalesType}
                setState={(e) => setSelectedSalesType(e)}
                placeholder="Тип продаж"
                fetchCallback={(e) => { }}
                setInputChange={(e) => { }}
              />
            )}

            {mp === 'wb' && (
              <RangeBox setDate={(e) => setDate(e)} hideBtnGroup />
            )}
          </div>
        </div>

        {pageValue != 'working_scheme' && (
          <DropContainer
            // name={'Продажи и остатки'}
            showDropBox={showDropBox}
            setShowDropBox={(e) => setShowDropBox(e)}
            name={chartData?.title ? chartData?.title : ''}
            selectedMetric={tableMetric}
            setSelectedMetric={setTableMetric}
            metricsList={tableMetrics}
          >
            <div className="inside-content">
              <div className="chart-box">
                <LineChart {...chartData} />
              </div>
            </div>
          </DropContainer>
        )}

        {pageValue == 'price_segment' && (
          <SliderRange
            minValue={minMaxRange[0]}
            maxValue={minMaxRange[1]}
            setState={setPriceRange}
            sliderRange={priceRange}
            setSegments={(e) => setSegments(e)}
            segments={segments}
            range={priceRange}
            minMax
            callbackHandler={AccountData}
          />
        )}

        <TableBox
          loading={loading}
          fetchedData={fetchedData}
          headers={tableHeaders}
          sort={sort}
          paginator={true}
          fetchCallback={(e) => AccountData(e)}
          sortingFunc={(e) => AccountData(e)}
          onAction={(e) => onAction(e)}
          onCheck={(e) => handleCheck(e)}
          onUncheckAllDependence={[pageValue, search, mp]}
          ref={paginatorRef}
          onEdit={(e) => e}
          onDelete={(e) => e}
          isInsideTable={false}
          newTable={true}
          columnSize={columnSize}
          tableUrl={tableUrl}
          tableBody={tableBody}
          child={
            (pageValue === 'days' || pageValue == 'working_scheme') && (
              <Checkbox
                value={isReverse}
                onChange={(checked) => setIsReverse(checked)}
              >Перевернуть</Checkbox>
            )
          }
        />
      </div>
    </div>
  );
};

export { AnalysisPlatformBySellers };
