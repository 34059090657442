import {
  OlegHttps,
  OlegHttp,
  KonstantinMasterHttps,
  KonstantinMasterHttp,
  ArtemOrdersHttps,
  ArtemOrdersHttp,
  RavshanHttps,
  RavshanHttp,
} from '../fetchUrls';
import currentHeaders, { getSpace } from './headers';
import dayjs from 'dayjs';

export const setDataFromJson = (
  json,
  page,
  setState,
  withAll = true,
  mapFunc = (el) => ({ label: el.name, value: el.id })
) => {
  const dataArr = json.map(mapFunc);
  if (page > 1) {
    setState((prev) => [...prev, ...dataArr]);
  } else {
    if (withAll) setState([...dataArr]);
    else setState([...dataArr]);
  }
};

export const fetchApiReviewsFilter = ({
  selectedSpace,
  page = 1,
  setApiData,
  selectedMarketplace,
  withAll = true,
  searchParam = null,
  type = 'union',
}) => {
  let url;
  if (!window.location.hostname.match('localhost')) {
    url = `${KonstantinMasterHttps}/feedbacks-and-questions/filters/api-ids`;
  } else {
    url = `${KonstantinMasterHttp}/feedbacks-and-questions/filters/api-ids`;
  }

  const body = JSON.stringify({
    page,
    limit: 50,
    mp_id_list: Array.isArray(selectedMarketplace)
      ? selectedMarketplace?.length === 0
        ? ['all']
        : selectedMarketplace?.map((el) => el?.value)
      : selectedMarketplace?.value
      ? [selectedMarketplace?.value]
      : ['all'],
  });

  const headers = {
    ...currentHeaders,
    space: selectedSpace,
  };

  fetch(url, { body, method: 'POST', headers })
    .then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const err = await res.json();
        throw new Error(JSON.stringify(err));
      }
    })
    .then((json) => setDataFromJson(json, page, setApiData, withAll))
    .catch((err) => console.error(err));
};

export const fetchApiFilter = ({
  selectedSpace,
  page = 1,
  setApiData,
  selectedCategory,
  selectedBrand,
  selectedProduct,
  selectedShop,
  selectedCampaign,
  searchParam = null,
  withAll = true,
  type = 'union',
}) => {
  let url;
  if (!window.location.hostname.match('localhost')) {
    url = `${OlegHttps}/api/v2/filter/product/api_id?filter_type=${type}`;
  } else {
    url = `${OlegHttp}/api/v2/filter/product/api_id?filter_type=${type}`;
  }

  let api_id_list,
    category_id_list,
    brand_id_list,
    product_id_list,
    shop_id_list,
    campaign_id_list;
  api_id_list = ['all'];

  if (selectedCategory?.length > 0) {
    category_id_list = selectedCategory.map((el) => el.value);
  } else {
    category_id_list = ['all'];
  }

  if (selectedBrand?.length > 0) {
    brand_id_list = selectedBrand.map((el) => el.value);
  } else {
    brand_id_list = ['all'];
  }

  if (selectedProduct?.length > 0) {
    product_id_list = selectedProduct.map((el) => el.value);
  } else {
    product_id_list = ['all'];
  }

  if (selectedShop?.length > 0) {
    shop_id_list = selectedShop.map((el) => el.value);
  } else {
    shop_id_list = ['all'];
  }

  if (selectedCampaign?.length > 0) {
    campaign_id_list = selectedCampaign.map((el) => el.value);
  } else {
    campaign_id_list = ['all'];
  }

  const body = JSON.stringify({
    search_param: searchParam.length < 1 ? null : searchParam,
    api_id_list: ['all'],
    category_id_list,
    brand_id_list,
    product_id_list,
    shop_id_list,
    campaign_id_list,
    limit: 50,
    page,
  });

  const headers = {
    ...currentHeaders,
    space: selectedSpace,
  };

  fetch(url, { body, method: 'POST', headers })
    .then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const err = await res.json();
        throw new Error(JSON.stringify(err));
      }
    })
    .then((json) =>
      setDataFromJson(
        json?.map((el) => ({ ...el, id: String(el?.id) })),
        page,
        setApiData,
        withAll
      )
    )
    .catch((err) => console.error(err));
};

export const fetchCategoryFilter = ({
  selectedSpace,
  apiData,
  dateFrom,
  dateTo,
  selectedApi,
  selectedCategory,
  selectedBrand,
  selectedProduct,
  selectedShop,
  selectedCampaign,
  setCategoryData,
  page = 1,
  searchParam = null,
  type = 'union',
}) => {
  if ((apiData?.length > 0) & (dateFrom !== '') & (dateTo !== '')) {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${OlegHttps}/api/v2/filter/product/category?filter_type=${type}`;
    } else {
      url = `${OlegHttp}/api/v2/filter/product/category?filter_type=${type}`;
    }

    let api_id_list,
      category_id_list,
      brand_id_list,
      product_id_list,
      shop_id_list,
      campaign_id_list;

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi.map((el) => el.value);
    } else {
      api_id_list = apiData.map((el) => el.value);
    }

    if (selectedApi?.value) {
      api_id_list = [selectedApi?.value];
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory.map((el) => el.value);
    } else {
      category_id_list = ['all'];
    }

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand.map((el) => el.value);
    } else {
      brand_id_list = ['all'];
    }

    if (selectedProduct?.length > 0) {
      product_id_list = selectedProduct.map((el) => el.value);
    } else {
      product_id_list = ['all'];
    }

    if (selectedShop?.length > 0) {
      shop_id_list = selectedShop.map((el) => el.value);
    } else {
      shop_id_list = ['all'];
    }

    if (selectedCampaign?.length > 0) {
      campaign_id_list = selectedCampaign.map((el) => el.value);
    } else {
      campaign_id_list = ['all'];
    }

    const body = JSON.stringify({
      search_param: searchParam.length < 1 ? null : searchParam,
      api_id_list,
      category_id_list,
      brand_id_list,
      product_id_list,
      shop_id_list,
      campaign_id_list,
      date_from: dayjs(dateFrom).format('YYYY-MM-DD'),
      date_to: dayjs(dateTo).format('YYYY-MM-DD'),
      page,
      limit: 50,
    });

    const headers = {
      ...currentHeaders,
      space: selectedSpace,
    };

    fetch(url, { body, method: 'POST', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        const dataArr = json?.map((el) => ({ label: el.name, value: el.id }));
        if (page > 1) {
          setCategoryData((prev) => [...prev, ...dataArr]);
        } else {
          setCategoryData([...dataArr]);
        }
      })
      .catch((err) => console.error(err));
  }
};

export const fetchBrandFilter = ({
  selectedSpace,
  apiData,
  categoryData,
  dateFrom,
  dateTo,
  selectedApi,
  selectedCategory,
  selectedBrand,
  selectedProduct,
  selectedShop,
  selectedCampaign,
  page = 1,
  setBrandData,
  searchParam = null,
  type = 'union',
}) => {
  if (
    (apiData?.length > 0) &
    (dateFrom !== '') &
    (dateTo !== '') &
    (categoryData?.length > 0)
  ) {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${OlegHttps}/api/v2/filter/product/brand?filter_type=${type}`;
    } else {
      url = `${OlegHttp}/api/v2/filter/product/brand?filter_type=${type}`;
    }

    let api_id_list,
      category_id_list,
      brand_id_list,
      product_id_list,
      shop_id_list,
      campaign_id_list;

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi.map((el) => el.value);
    } else {
      api_id_list = apiData.map((el) => el.value);
    }

    if (selectedApi?.value) {
      api_id_list = [selectedApi?.value];
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory.map((el) => el.value);
    } else {
      category_id_list = ['all'];
    }

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand.map((el) => el.value);
    } else {
      brand_id_list = ['all'];
    }

    if (selectedProduct?.length > 0) {
      product_id_list = selectedProduct.map((el) => el.value);
    } else {
      product_id_list = ['all'];
    }

    if (selectedShop?.length > 0) {
      shop_id_list = selectedShop.map((el) => el.value);
    } else {
      shop_id_list = ['all'];
    }

    if (selectedCampaign?.length > 0) {
      campaign_id_list = selectedCampaign.map((el) => el.value);
    } else {
      campaign_id_list = ['all'];
    }

    const body = JSON.stringify({
      search_param: searchParam.length < 1 ? null : searchParam,
      api_id_list,
      category_id_list,
      brand_id_list,
      product_id_list,
      shop_id_list,
      campaign_id_list,
      date_from: dayjs(dateFrom).format('YYYY-MM-DD'),
      date_to: dayjs(dateTo).format('YYYY-MM-DD'),
      page,
      limit: 50,
    });

    const headers = {
      ...currentHeaders,
      space: selectedSpace,
    };

    fetch(url, { body, method: 'POST', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        const dataArr = json.map((el) => ({ label: el.name, value: el.id }));
        if (page > 1) {
          setBrandData((prev) => [...prev, ...dataArr]);
        } else {
          setBrandData([...dataArr]);
        }
      })
      .catch((err) => console.error(err));
  }
};

export const fetchCampaignFilter = ({
  selectedSpace,
  apiData,
  categoryData,
  brandData,
  selectedApi,
  selectedCategory,
  selectedBrand,
  selectedProduct,
  selectedShop,
  selectedCampaign,
  dateFrom,
  dateTo,
  page = 1,
  setCampaignData,
  searchParam = null,
  type = 'union',
}) => {
  if (
    (apiData?.length > 0) &
    (dateFrom !== '') &
    (dateTo !== '') &
    (categoryData?.length > 0)
  ) {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${OlegHttps}/api/v2/filter/product/campaing_id?filter_type=${type}`;
    } else {
      url = `${OlegHttp}/api/v2/filter/product/campaing_id?filter_type=${type}`;
    }

    let api_id_list,
      category_id_list,
      brand_id_list,
      product_id_list,
      shop_id_list,
      campaign_id_list;

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi.map((el) => el.value);
    } else {
      api_id_list = apiData.map((el) => el.value);
    }

    if (selectedApi?.value) {
      api_id_list = [selectedApi?.value];
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory.map((el) => el.value);
    } else {
      category_id_list = ['all'];
    }

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand.map((el) => el.value);
    } else {
      brand_id_list = ['all'];
    }

    if (selectedProduct?.length > 0) {
      product_id_list = selectedProduct.map((el) => el.value);
    } else {
      product_id_list = ['all'];
    }

    if (selectedShop?.length > 0) {
      shop_id_list = selectedShop.map((el) => el.value);
    } else {
      shop_id_list = ['all'];
    }

    if (selectedCampaign?.length > 0) {
      campaign_id_list = selectedCampaign.map((el) => el.value);
    } else {
      campaign_id_list = ['all'];
    }

    const body = JSON.stringify({
      search_param: searchParam.length < 1 ? null : searchParam,
      api_id_list,
      category_id_list,
      brand_id_list,
      product_id_list,
      shop_id_list,
      campaign_id_list,
      date_from: dayjs(dateFrom).format('YYYY-MM-DD'),
      date_to: dayjs(dateTo).format('YYYY-MM-DD'),
      page,
      limit: 50,
    });

    const headers = {
      ...currentHeaders,
      space: selectedSpace,
    };

    fetch(url, { body, method: 'POST', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        const dataArr = json.map(({ id, name }) => ({
          label: name,
          value: id,
        }));
        if (page > 1) {
          setCampaignData((prev) => [...prev, ...dataArr]);
        } else {
          setCampaignData([...dataArr]);
        }
      })
      .catch((err) => console.error(err));
  }
};

export const fetchWarehouseFilter = ({
  selectedSpace,
  apiData,
  categoryData,
  brandData,
  selectedApi,
  selectedCategory,
  selectedBrand,
  selectedProduct,
  selectedShop,
  selectedCampaign,
  dateFrom,
  dateTo,
  page = 1,
  setWarehouseData,
  searchParam = null,
  type = 'union',
}) => {
  if (
    (apiData?.length > 0) &
    (dateFrom !== '') &
    (dateTo !== '') &
    (categoryData?.length > 0)
  ) {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${OlegHttps}/api/v2/filter/product/warehouse?filter_type=${type}`;
    } else {
      url = `${OlegHttp}/api/v2/filter/product/warehouse?filter_type=${type}`;
    }

    let api_id_list,
      category_id_list,
      brand_id_list,
      product_id_list,
      shop_id_list,
      campaign_id_list;

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi.map((el) => el.value);
    } else {
      api_id_list = apiData.map((el) => el.value);
    }

    if (selectedApi?.value) {
      api_id_list = [selectedApi?.value];
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory.map((el) => el.value);
    } else {
      category_id_list = ['all'];
    }

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand.map((el) => el.value);
    } else {
      brand_id_list = ['all'];
    }

    if (selectedProduct?.length > 0) {
      product_id_list = selectedProduct.map((el) => el.value);
    } else {
      product_id_list = ['all'];
    }

    if (selectedShop?.length > 0) {
      shop_id_list = selectedShop.map((el) => el.value);
    } else {
      shop_id_list = ['all'];
    }

    if (selectedCampaign?.length > 0) {
      campaign_id_list = selectedCampaign.map((el) => el.value);
    } else {
      campaign_id_list = ['all'];
    }

    const body = JSON.stringify({
      search_param: searchParam.length < 1 ? null : searchParam,
      api_id_list,
      category_id_list,
      brand_id_list,
      product_id_list,
      shop_id_list,
      campaign_id_list,
      date_from: dayjs(dateFrom).format('YYYY-MM-DD'),
      date_to: dayjs(dateTo).format('YYYY-MM-DD'),
      page,
      limit: 50,
    });

    const headers = {
      ...currentHeaders,
      space: selectedSpace,
    };

    fetch(url, { body, method: 'POST', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        const dataArr = json.map(({ id, name }) => ({
          label: name,
          value: id,
        }));
        if (page > 1) {
          setWarehouseData((prev) => [...prev, ...dataArr]);
        } else {
          setWarehouseData([...dataArr]);
        }
      })
      .catch((err) => console.error(err));
  }
};

export const fetchProductFilter = ({
  selectedSpace,
  apiData,
  categoryData,
  selectedCategory,
  selectedBrand,
  brandData,
  selectedApi,
  page = 1,
  setProductsData,
  searchParam = null,
}) => {
  let url;
  if (!window.location.hostname.match('localhost')) {
    url = `${OlegHttps}/api/v2/filter/product/product?filter_type=union`;
  } else {
    url = `${OlegHttp}/api/v2/filter/product/product?filter_type=union`;
  }

  let api_id_list;
  let category_id_list;
  let brand_id_list;

  if (selectedApi?.length > 0) {
    api_id_list = selectedApi?.map((el) => el.value);
  } else {
    api_id_list = apiData?.map((el) => el.value);
  }
  if (selectedApi?.value) {
    api_id_list = [selectedApi?.value];
  }

  if (selectedCategory?.length > 0) {
    category_id_list = selectedCategory?.map((el) => el.value);
  } else {
    category_id_list = categoryData?.map((el) => el.value);
  }

  if (selectedBrand?.length > 0) {
    brand_id_list = selectedBrand?.map((el) => el.value);
  } else {
    brand_id_list = brandData?.map((el) => el.value);
  }

  const body = JSON.stringify({
    search_param: searchParam.length < 1 ? null : searchParam,
    api_id_list,
    category_id_list,
    brand_id_list,
    product_id_list: ['all'],
    page,
    limit: 50,
  });

  const headers = {
    ...currentHeaders,
    space: selectedSpace,
  };

  fetch(url, { body, method: 'POST', headers })
    .then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const err = await res.json();
        throw new Error(JSON.stringify(err));
      }
    })
    .then((json) => {
      const dataArr = json?.map((el) => ({ label: el.name, value: el.id }));
      if (page > 1) {
        setProductsData((prev) => [...prev, ...dataArr]);
      } else {
        setProductsData([...dataArr]);
      }
    })
    .catch((err) => console.error(err));
};

export const fetchAdvCampaignFilter = ({
  selectedSpace,
  page = 1,
  apiData,
  selectedApi,
  setAdvCampaignData,
  selectedCategory,
  selectedBrand,
  selectedShop,
  selectedCampaign,
  searchParam = null,
  withAll = true,
}) => {
  let url;
  if (!window.location.hostname.match('localhost')) {
    url = `${OlegHttps}/api/v2/filter/product/adv_campaign`;
  } else {
    url = `${OlegHttp}/api/v2/filter/product/adv_campaign`;
  }

  let api_id_list,
    category_id_list,
    brand_id_list,
    shop_id_list,
    campaign_id_list;
  if (selectedApi?.length > 0) {
    api_id_list = selectedApi.map((el) => el.value);
  } else {
    api_id_list = apiData.map((el) => el.value);
  }

  if (selectedCategory?.length > 0) {
    category_id_list = selectedCategory.map((el) => el.value);
  } else {
    category_id_list = ['all'];
  }

  if (selectedBrand?.length > 0) {
    brand_id_list = selectedBrand.map((el) => el.value);
  } else {
    brand_id_list = ['all'];
  }

  if (selectedShop?.length > 0) {
    shop_id_list = selectedShop.map((el) => el.value);
  } else {
    shop_id_list = ['all'];
  }

  if (selectedCampaign?.length > 0) {
    campaign_id_list = selectedCampaign.map((el) => el.value);
  } else {
    campaign_id_list = ['all'];
  }

  const body = JSON.stringify({
    search_param: searchParam.length < 1 ? null : searchParam,
    category_id_list,
    brand_id_list,
    api_id_list,
    shop_id_list,
    campaign_id_list,
    limit: 50,
    page,
  });

  const headers = {
    ...currentHeaders,
    space: selectedSpace,
  };

  fetch(url, { body, method: 'POST', headers })
    .then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const err = await res.json();
        throw new Error(JSON.stringify(err));
      }
    })
    .then((json) =>
      setDataFromJson(
        json?.map((el) => ({ ...el, id: String(el?.id) })),
        page,
        setAdvCampaignData,
        withAll
      )
    )
    .catch((err) => console.error(err));
};

export const fetchOrderSuperStatusFilter = ({
  selectedSpace,
  page = 1,
  apiData,
  selectedApi,
  setSuperStatusData,
  selectedCategory,
  selectedBrand,
  selectedShop,
  searchParam = null,
  dateFrom,
  dateTo,
}) => {
  let url;
  if (!window.location.hostname.match('localhost')) {
    url = `${OlegHttps}/api/v2/filter/product/super_status`;
  } else {
    url = `${OlegHttp}/api/v2/filter/product/super_status`;
  }

  let api_id_list, category_id_list, brand_id_list, shop_id_list;

  if (selectedApi?.length > 0) {
    api_id_list = selectedApi.map((el) => el.value);
  } else {
    api_id_list = apiData.map((el) => el.value);
  }

  if (selectedCategory?.length > 0) {
    category_id_list = selectedCategory.map((el) => el.value);
  } else {
    category_id_list = ['all'];
  }

  if (selectedBrand?.length > 0) {
    brand_id_list = selectedBrand.map((el) => el.value);
  } else {
    brand_id_list = ['all'];
  }

  if (selectedShop?.length > 0) {
    shop_id_list = selectedShop.map((el) => el.value);
  } else {
    shop_id_list = ['all'];
  }

  const body = JSON.stringify({
    search_param: searchParam.length < 1 ? null : searchParam,
    category_id_list,
    brand_id_list,
    api_id_list,
    shop_id_list,
    warehouse_id: ['all'],
    limit: 50,
    page,
    date_from: dayjs(dateFrom).format('YYYY-MM-DD'),
    date_to: dayjs(dateTo).format('YYYY-MM-DD'),
  });

  const headers = {
    ...currentHeaders,
    space: selectedSpace,
  };

  fetch(url, { body, method: 'POST', headers })
    .then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const err = await res.json();
        throw new Error(JSON.stringify(err));
      }
    })
    .then((json) =>
      setDataFromJson(
        json?.map((el) => ({ ...el, id: String(el?.id) })),
        page,
        setSuperStatusData
      )
    )
    .catch((err) => console.error(err));
};

export const fetchAdvCampaignTypeFilter = ({
  selectedSpace,
  page = 1,
  apiData,
  selectedApi,
  setSuperStatusData,
  selectedCategory,
  selectedBrand,
  selectedShop,
  searchParam = null,
  dateFrom,
  dateTo,
}) => {
  let url;
  if (!window.location.hostname.match('localhost')) {
    url = `${OlegHttps}/api/v2/filter/product/adv_campaign_type`;
  } else {
    url = `${OlegHttp}/api/v2/filter/product/adv_campaign_type`;
  }

  let api_id_list, category_id_list, brand_id_list, shop_id_list;

  if (selectedApi?.length > 0) {
    api_id_list = selectedApi.map((el) => el.value);
  } else {
    api_id_list = apiData.map((el) => el.value);
  }

  if (selectedCategory?.length > 0) {
    category_id_list = selectedCategory.map((el) => el.value);
  } else {
    category_id_list = ['all'];
  }

  if (selectedBrand?.length > 0) {
    brand_id_list = selectedBrand.map((el) => el.value);
  } else {
    brand_id_list = ['all'];
  }

  if (selectedShop?.length > 0) {
    shop_id_list = selectedShop.map((el) => el.value);
  } else {
    shop_id_list = ['all'];
  }

  const body = JSON.stringify({
    search_param: searchParam.length < 1 ? null : searchParam,
    category_id_list,
    brand_id_list,
    api_id_list,
    shop_id_list,
    warehouse_id: ['all'],
    limit: 50,
    page,
    date_from: dayjs(dateFrom).format('YYYY-MM-DD'),
    date_to: dayjs(dateTo).format('YYYY-MM-DD'),
  });

  const headers = {
    ...currentHeaders,
    space: selectedSpace,
  };

  fetch(url, { body, method: 'POST', headers })
    .then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const err = await res.json();
        throw new Error(JSON.stringify(err));
      }
    })
    .then((json) =>
      setDataFromJson(
        json?.map((el) => ({ ...el, id: String(el?.id) })),
        page,
        setSuperStatusData
      )
    )
    .catch((err) => console.error(err));
};
export const fetchAdvStatusFilter = ({
  selectedSpace,
  page = 1,
  apiData,
  selectedApi,
  setSuperStatusData,
  selectedCategory,
  selectedBrand,
  selectedShop,
  searchParam = null,
  dateFrom,
  dateTo,
}) => {
  let url;
  if (!window.location.hostname.match('localhost')) {
    url = `${OlegHttps}/api/v2/filter/product/adv_status`;
  } else {
    url = `${OlegHttp}/api/v2/filter/product/adv_status`;
  }

  let api_id_list, category_id_list, brand_id_list, shop_id_list;

  if (selectedApi?.length > 0) {
    api_id_list = selectedApi.map((el) => el.value);
  } else {
    api_id_list = apiData.map((el) => el.value);
  }

  if (selectedCategory?.length > 0) {
    category_id_list = selectedCategory.map((el) => el.value);
  } else {
    category_id_list = ['all'];
  }

  if (selectedBrand?.length > 0) {
    brand_id_list = selectedBrand.map((el) => el.value);
  } else {
    brand_id_list = ['all'];
  }

  if (selectedShop?.length > 0) {
    shop_id_list = selectedShop.map((el) => el.value);
  } else {
    shop_id_list = ['all'];
  }

  const body = JSON.stringify({
    search_param: searchParam.length < 1 ? null : searchParam,
    category_id_list,
    brand_id_list,
    api_id_list,
    shop_id_list,
    warehouse_id: ['all'],
    limit: 50,
    page,
    date_from: dayjs(dateFrom).format('YYYY-MM-DD'),
    date_to: dayjs(dateTo).format('YYYY-MM-DD'),
  });

  const headers = {
    ...currentHeaders,
    space: selectedSpace,
  };

  fetch(url, { body, method: 'POST', headers })
    .then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const err = await res.json();
        throw new Error(JSON.stringify(err));
      }
    })
    .then((json) =>
      setDataFromJson(
        json?.map((el) => ({ ...el, id: String(el?.id) })),
        page,
        setSuperStatusData
      )
    )
    .catch((err) => console.error(err));
};
// export const fetchOrderSuperStatusFilter = ({
//   selectedSpace,
//   page = 1,
//   apiData,
//   selectedApi,
//   setSuperStatusData,
//   selectedCategory,
//   selectedBrand,
//   selectedShop,
//   searchParam = null,
//   dateFrom,
//   dateTo,
// }) => {
//   let url;
//   if (!window.location.hostname.match('localhost')) {
//     url = `${OlegHttps}/api/v2/filter/product/super_status`;
//   } else {
//     url = `${OlegHttp}/api/v2/filter/product/super_status`;
//   }

//   let api_id_list, category_id_list, brand_id_list, shop_id_list;

//   if (selectedApi?.length > 0) {
//     api_id_list = selectedApi.map((el) => el.value);
//   } else {
//     api_id_list = apiData.map((el) => el.value);
//   }

//   if (selectedCategory?.length > 0) {
//     category_id_list = selectedCategory.map((el) => el.value);
//   } else {
//     category_id_list = ['all'];
//   }

//   if (selectedBrand?.length > 0) {
//     brand_id_list = selectedBrand.map((el) => el.value);
//   } else {
//     brand_id_list = ['all'];
//   }

//   if (selectedShop?.length > 0) {
//     shop_id_list = selectedShop.map((el) => el.value);
//   } else {
//     shop_id_list = ['all'];
//   }

//   const body = JSON.stringify({
//     search_param: searchParam.length < 1 ? null : searchParam,
//     category_id_list,
//     brand_id_list,
//     api_id_list,
//     shop_id_list,
//     warehouse_id: ['all'],
//     limit: 50,
//     page,
//     date_from: dayjs(dateFrom).format('YYYY-MM-DD'),
//     date_to: dayjs(dateTo).format('YYYY-MM-DD'),
//   });

//   const headers = {
//     ...currentHeaders,
//     space: selectedSpace,
//   };

//   fetch(url, { body, method: 'POST', headers })
//     .then(async (res) => {
//       if (res.ok) {
//         return res.json();
//       } else {
//         const err = await res.json();
//         throw new Error(JSON.stringify(err));
//       }
//     })
//     .then((json) =>
//       setDataFromJson(
//         json?.map((el) => ({ ...el, id: String(el?.id) })),
//         page,
//         setSuperStatusData
//       )
//     )
//     .catch((err) => console.error(err));
// };

export const fetchActionFilter = ({
  selectedSpace,
  apiData,
  categoryData,
  selectedCategory,
  selectedBrand,
  brandData,
  selectedApi,
  page = 1,
  setActionData,
  searchParam = null,
}) => {
  let url;
  if (!window.location.hostname.match('localhost')) {
    // url = `${RavshanHttps}/api/action/filter_action`;
    url = `${OlegHttps}/api/v2/filter/promotion/action`;
  } else {
    // url = `${RavshanHttps}/api/action/filter_action`;
    url = `${OlegHttps}/api/v2/filter/promotion/action`;
  }

  let api_id_list;
  let category_id_list;
  let brand_id_list;

  if (selectedApi?.length > 0) {
    api_id_list = selectedApi?.map((el) => el.value);
  } else {
    api_id_list = apiData?.map((el) => el.value);
  }
  if (selectedApi?.value) {
    api_id_list = [selectedApi?.value];
  }

  if (selectedCategory?.length > 0) {
    category_id_list = selectedCategory?.map((el) => el.value);
  } else {
    category_id_list = categoryData?.map((el) => el.value);
  }

  if (selectedBrand?.length > 0) {
    brand_id_list = selectedBrand?.map((el) => el.value);
  } else {
    brand_id_list = brandData?.map((el) => el.value);
  }

  const body = JSON.stringify({
    search_param: searchParam.length < 1 ? null : searchParam,
    api_id_list,
    category_id_list,
    brand_id_list,
    page,
    limit: 50,
  });

  const headers = {
    ...currentHeaders,
    space: selectedSpace,
  };

  fetch(url, { body, method: 'POST', headers })
    // .then((res) => res.json())
    .then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const err = await res.json();
        throw new Error(JSON.stringify(err));
      }
    })
    .then((json) => {
      const dataArr = json?.map((el) => ({ label: el.name, value: el.id }));
      if (page > 1) {
        setActionData((prev) => [...prev, ...dataArr]);
      } else {
        setActionData([...dataArr]);
      }
    })
    .catch((err) => console.error(err));
};

export const fetchMPApiFilter = ({
  selectedSpace,
  abortController = new AbortController(),
  page = 1,
  selectedApi,
  apiData,
  withAll,
  selectedBrand,
  brandData,
  setApiData,
  searchParam = '',
  type = 'union',
}) => {
  let url;
  if (!window.location.hostname.match('localhost')) {
    url = `${KonstantinMasterHttps}/mp-products/filters/api-ids`;
  } else {
    url = `${KonstantinMasterHttp}/mp-products/filters/api-ids`;
  }

  let api_id_list;
  let brand_id_list;

  if (selectedApi?.length > 0) {
    api_id_list = selectedApi?.map((el) => el.value);
  } else {
    api_id_list = ['all'];
  }
  if (selectedApi?.value) {
    api_id_list = [selectedApi?.value];
  }

  if (selectedBrand?.length > 0) {
    brand_id_list = selectedBrand?.map((el) => el.value);
  } else {
    brand_id_list = brandData?.map((el) => el.value);
  }

  const body = JSON.stringify({
    search_param: searchParam.length < 1 ? '' : searchParam,
    api_id_list,
    category_id_list: ['all'],
    brand_id_list,
    product_id_list: ['all'],
    limit: 50,
    page,
  });

  const headers = {
    ...currentHeaders,
    space: selectedSpace,
  };

  fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
    .then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const err = await res.json();
        throw new Error(JSON.stringify(err));
      }
    })
    .then((json) => setDataFromJson(json, page, setApiData, withAll))
    .catch((err) => console.error(err));
};

export const fetchMPCategoryFilter = ({
  selectedSpace,
  abortController = new AbortController(),
  page = 1,
  selectedApi,
  apiData,
  selectedBrand,
  brandData,
  setCategoryData,
  searchParam = '',
  type = 'union',
}) => {
  let url;
  if (!window.location.hostname.match('localhost')) {
    url = `${KonstantinMasterHttps}/mp-products/filters/categories`;
  } else {
    url = `${KonstantinMasterHttp}/mp-products/filters/categories`;
  }

  let api_id_list;
  let brand_id_list;

  if (selectedApi?.length > 0) {
    api_id_list = selectedApi?.map((el) => el.value);
  } else {
    api_id_list = ['all'];
  }
  if (selectedApi?.value) {
    api_id_list = [selectedApi?.value];
  }

  if (selectedBrand?.length > 0) {
    brand_id_list = selectedBrand?.map((el) => el.value);
  } else {
    brand_id_list = ['all'];
  }

  const body = JSON.stringify({
    search_param: searchParam.length < 1 ? '' : searchParam,
    api_id_list,
    category_id_list: ['all'],
    brand_id_list,
    limit: 50,
    page,
  });

  const headers = {
    ...currentHeaders,
    space: selectedSpace,
  };

  fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
    .then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const err = await res.json();
        throw new Error(JSON.stringify(err));
      }
    })
    .then((json) => setDataFromJson(json, page, setCategoryData, true))
    .catch((err) => console.error(err));
};

export const fetchMPBrandFilter = ({
  selectedSpace,
  abortController = new AbortController(),
  page = 1,
  apiData,
  categoryData,
  setBrandData,
  selectedApi,
  searchParam = '',
  type = 'union',
  selectedCategory,
}) => {
  let url;
  if (!window.location.hostname.match('localhost')) {
    url = `${KonstantinMasterHttps}/mp-products/filters/brands`;
  } else {
    url = `${KonstantinMasterHttp}/mp-products/filters/brands`;
  }

  let api_id_list;
  let category_id_list;

  if (selectedApi?.length > 0) {
    api_id_list = selectedApi?.map((el) => el.value);
  } else {
    api_id_list = ['all'];
  }
  if (selectedApi?.value) {
    api_id_list = [selectedApi?.value];
  }

  if (selectedCategory?.length > 0) {
    category_id_list = selectedCategory?.map((el) => el.value);
  } else {
    category_id_list = ['all'];
  }

  const body = JSON.stringify({
    search_param: searchParam.length < 1 ? '' : searchParam,
    api_id_list,
    category_id_list,
    brand_id_list: ['all'],
    product_id_list: ['all'],
    limit: 50,
    page,
  });

  const headers = {
    ...currentHeaders,
    space: selectedSpace,
  };

  fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
    .then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const err = await res.json();
        throw new Error(JSON.stringify(err));
      }
    })
    .then((json) => setDataFromJson(json, page, setBrandData, true))
    .catch((err) => console.error(err));
};

export const fetchMPOfferIdFilter = ({
  selectedSpace,
  abortController = new AbortController(),
  page = 1,
  apiData,
  categoryData,
  brandData,
  selectedBrand,
  selectedApi,
  setOfferIdData,
  searchParam = '',
  type = 'union',
  selectedCategory,
}) => {
  let url;
  if (!window.location.hostname.match('localhost')) {
    url = `${KonstantinMasterHttps}/mp-products/filters/offer-id-name`;
  } else {
    url = `${KonstantinMasterHttp}/mp-products/filters/offer-id-name`;
  }

  let api_id_list;
  let category_id_list;
  let brand_id_list;

  if (selectedApi?.length > 0) {
    api_id_list = selectedApi?.map((el) => el.value);
  } else {
    api_id_list = ['all'];
  }
  if (selectedApi?.value) {
    api_id_list = [selectedApi?.value];
  }

  if (selectedCategory?.length > 0) {
    category_id_list = selectedCategory?.map((el) => el.value);
  } else {
    category_id_list = ['all'];
  }

  if (selectedBrand?.length > 0) {
    brand_id_list = selectedBrand?.map((el) => el.value);
  } else {
    brand_id_list = ['all'];
  }

  const body = JSON.stringify({
    search_param: searchParam.length < 1 ? '' : searchParam,
    api_id_list,
    category_id_list,
    brand_id_list: ['all'],
    product_id_list: ['all'],
    limit: 50,
    page,
  });

  const headers = {
    ...currentHeaders,
    space: selectedSpace,
  };

  fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
    .then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const err = await res.json();
        throw new Error(JSON.stringify(err));
      }
    })
    .then((json) => setDataFromJson(json, page, setOfferIdData, true))
    .catch((err) => console.error(err));
};

export const fetchMasterCategoryFilter = ({
  selectedSpace,
  page = 1,
  selectedApi,
  apiData,
  selectedBrand,
  brandData,
  setCategoryData,
  searchParam = '',
  withAll = true,
  type = 'union',
}) => {
  let url;
  if (!window.location.hostname.match('localhost')) {
    url = `${KonstantinMasterHttps}/master-products/filters/categories`;
  } else {
    url = `${KonstantinMasterHttp}/master-products/filters/categories`;
  }

  let api_id_list;
  let brand_id_list;

  if (selectedApi?.length > 0) {
    api_id_list = selectedApi?.map((el) => el.value);
  } else {
    api_id_list = ['all'];
  }
  if (selectedApi?.value) {
    api_id_list = [selectedApi?.value];
  }

  if (selectedBrand?.length > 0) {
    brand_id_list = selectedBrand?.map((el) => el.value);
  } else {
    brand_id_list = ['all'];
  }

  const body = JSON.stringify({
    search_param: searchParam.length < 1 ? '' : searchParam,
    api_id_list,
    category_id_list: ['all'],
    brand_id_list,
    limit: 50,
    page,
  });

  const headers = {
    ...currentHeaders,
    space: selectedSpace,
  };

  fetch(url, { body, method: 'POST', headers })
    .then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const err = await res.json();
        throw new Error(JSON.stringify(err));
      }
    })
    .then((json) => setDataFromJson(json, page, setCategoryData, withAll))
    .catch((err) => console.error(err));
};

export const fetchMasterBrandFilter = ({
  selectedSpace,
  page = 1,
  apiData,
  categoryData,
  setBrandData,
  selectedApi,
  searchParam = '',
  type = 'union',
  selectedCategory,
}) => {
  let url;
  if (!window.location.hostname.match('localhost')) {
    url = `${KonstantinMasterHttps}/master-products/filters/brands`;
  } else {
    url = `${KonstantinMasterHttp}/master-products/filters/brands`;
  }

  let api_id_list;
  let category_id_list;

  if (selectedApi?.length > 0) {
    api_id_list = selectedApi?.map((el) => el.value);
  } else {
    api_id_list = ['all'];
  }
  if (selectedApi?.value) {
    api_id_list = [selectedApi?.value];
  }

  if (selectedCategory?.length > 0) {
    category_id_list = selectedCategory?.map((el) => el.value);
  } else {
    category_id_list = ['all'];
  }

  const body = JSON.stringify({
    search_param: searchParam.length < 1 ? '' : searchParam,
    api_id_list,
    category_id_list,
    brand_id_list: ['all'],
    product_id_list: ['all'],
    limit: 50,
    page,
  });

  const headers = {
    ...currentHeaders,
    space: selectedSpace,
  };

  fetch(url, { body, method: 'POST', headers })
    .then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const err = await res.json();
        throw new Error(JSON.stringify(err));
      }
    })
    .then((json) => setDataFromJson(json, page, setBrandData, true))
    .catch((err) => console.error(err));
};

export const fetchMasterOfferIdFilter = ({
  selectedSpace,
  page = 1,
  apiData,
  categoryData,
  brandData,
  selectedBrand,
  selectedApi,
  setOfferIdData,
  searchParam = '',
  type = 'union',
  selectedCategory,
}) => {
  let url;
  if (!window.location.hostname.match('localhost')) {
    url = `${KonstantinMasterHttps}/master-products/filters/offer-id-name`;
  } else {
    url = `${KonstantinMasterHttp}/master-products/filters/offer-id-name`;
  }

  let api_id_list;
  let category_id_list;
  let brand_id_list;

  if (selectedApi?.length > 0) {
    api_id_list = selectedApi?.map((el) => el.value);
  } else {
    api_id_list = ['all'];
  }
  if (selectedApi?.value) {
    api_id_list = [selectedApi?.value];
  }

  if (selectedCategory?.length > 0) {
    category_id_list = selectedCategory?.map((el) => el.value);
  } else {
    category_id_list = ['all'];
  }

  if (selectedBrand?.length > 0) {
    brand_id_list = selectedBrand?.map((el) => el.value);
  } else {
    brand_id_list = ['all'];
  }

  const body = JSON.stringify({
    search_param: searchParam.length < 1 ? '' : searchParam,
    api_id_list,
    category_id_list,
    brand_id_list: ['all'],
    product_id_list: ['all'],
    limit: 50,
    page,
  });

  const headers = {
    ...currentHeaders,
    space: selectedSpace,
  };

  fetch(url, { body, method: 'POST', headers })
    .then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const err = await res.json();
        throw new Error(JSON.stringify(err));
      }
    })
    .then((json) => setDataFromJson(json, page, setOfferIdData, true))
    .catch((err) => console.error(err));
};

export const fetchFeedbacksCategoryFilter = ({
  selectedSpace,
  apiData,
  dateFrom,
  dateTo,
  allPeriod = false,
  selectedApi,
  selectedBrand,
  selectedCategory,
  pageValue = 'feedbacks',
  setCategoryData,
  page = 1,
  searchParam = null,
  type = 'union',
}) => {
  if ((dateFrom !== '') & (dateTo !== '')) {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/wb/${pageValue}/filters/categories?filter_type=${type}`;
    } else {
      url = `${KonstantinMasterHttps}/wb/${pageValue}/filters/categories?filter_type=${type}`;
    }
    let api_id_list;
    let brand_id_list;
    let category_id_list;
    let product_valuation_list;

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi.map((el) => el.value);
    } else {
      api_id_list = ['all'];
    }
    if (selectedApi?.value) {
      api_id_list = [selectedApi?.value];
    }

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand.map((el) => el.value);
    } else {
      brand_id_list = ['all'];
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory.map((el) => el.value);
    } else {
      category_id_list = ['all'];
    }

    const body = JSON.stringify({
      search_param: searchParam.length < 1 ? '' : searchParam,
      api_id_list,
      category_id_list,
      brand_id_list,
      product_valuation_list: ['all'],
      date_from: allPeriod ? new Date(0) : dayjs(dateFrom).format('YYYY-MM-DD'),
      date_to: dayjs(dateTo).format('YYYY-MM-DD'),
      page,
      limit: 50,
    });

    const headers = {
      ...currentHeaders,
      space: selectedSpace,
    };

    fetch(url, { body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        const dataArr = json?.map((el) => ({
          label: `${el.name} - ${el.count}`,
          value: el.id,
        }));
        if (page > 1) {
          setCategoryData((prev) => [...prev, ...dataArr]);
        } else {
          setCategoryData([...dataArr]);
        }
      })
      .catch((err) => console.error(err));
  }
};

export const fetchFeedbacksBrandFilter = ({
  selectedSpace,
  dateFrom,
  dateTo,
  allPeriod = false,
  selectedApi,
  selectedCategory,
  selectedBrand,
  setBrandData,
  page = 1,
  searchParam = null,
  pageValue = 'feedbacks',
}) => {
  if ((dateFrom !== '') & (dateTo !== '')) {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/wb/${pageValue}/filters/brands`;
    } else {
      url = `${KonstantinMasterHttps}/wb/${pageValue}/filters/brands`;
    }
    let api_id_list;
    let category_id_list;
    let brand_id_list;

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi.map((el) => el.value);
    } else {
      api_id_list = ['all'];
    }
    if (selectedApi?.value) {
      api_id_list = [selectedApi?.value];
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory.map((el) => el.value);
    } else {
      category_id_list = ['all'];
    }

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand.map((el) => el.value);
    } else {
      brand_id_list = ['all'];
    }

    const body = JSON.stringify({
      search_param: searchParam.length < 1 ? '' : searchParam,
      api_id_list,
      category_id_list,
      brand_id_list,
      product_valuation_list: ['all'],
      date_from: allPeriod ? new Date(0) : dayjs(dateFrom).format('YYYY-MM-DD'),
      date_to: dayjs(dateTo).format('YYYY-MM-DD'),
      page,
      limit: 50,
    });

    const headers = {
      ...currentHeaders,
      space: selectedSpace,
    };

    fetch(url, { body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        const dataArr = json?.map((el) => ({
          label: `${el.name} - ${el.count}`,
          value: el.id,
        }));
        if (page > 1) {
          setBrandData((prev) => [...prev, ...dataArr]);
        } else {
          setBrandData([...dataArr]);
        }
      })
      .catch((err) => console.error(err));
  }
};

// export const fetchFeedbacksProductFilter = ({ dateFrom, dateTo, selectedApi, selectedCategory, selectedBrand, selectedProduct, setProductData, page = 1, searchParam = null,  pageValue = 'feedbacks' }) => {
//     if ( dateFrom !== '' & dateTo !== '' ) {
//         let url
//         if (!window.location.hostname.match('localhost')) {
//             url = `${KonstantinMasterHttps}/wb/feedbacks/filters/offer-id-name`
//         } else {
//             url = `${KonstantinMasterHttps}/wb/feedbacks/filters/offer-id-name`
//         }
//         let api_id_list
//         let category_id_list
//         let brand_id_list
//         let product_valuation_list

//         if (selectedApi.length > 0) {
//             api_id_list = selectedApi.map(el => el.value)
//         } else {
//             api_id_list = ['all']
//         }
//         if(selectedApi?.value){
//             api_id_list = [selectedApi?.value]
//         }

//         if (selectedCategory.length > 0) {
//             category_id_list = selectedCategory.map(el => el.value)
//         } else {
//             category_id_list = ['all']
//         }

//         if (selectedBrand.length > 0) {
//             brand_id_list = selectedBrand.map(el => el.value)
//         } else {
//             brand_id_list = ['all']
//         }

//         if (selectedProduct?.length > 0) {
//             product_valuation_list = selectedProduct.map(el => el.value)
//         } else {
//             product_valuation_list = ['all']
//         }

//         const body = JSON.stringify({
//             search_param: searchParam.length < 1 ? '' : searchParam,
//             api_id_list,
//             category_id_list,
//             brand_id_list,
//             product_valuation_list,
//             date_from: dayjs(dateFrom).format('YYYY-MM-DD'),
//             date_to: dayjs(dateTo).format('YYYY-MM-DD'),
//             page,
//             limit: 50
//         })

//         fetch(url, { body, method: 'POST', headers })
//             .then(res => res.json())
//             .then(json => {
//                 const dataArr = json?.map((el) => ({ label: el.name, value: el.id }))
//                 if (page > 1) {
//                     setProductData(prev => [...prev, ...dataArr])
//                 } else {
//                     setProductData([{ label: 'Все', value: 'all' }, ...dataArr])
//                 }
//             })
//             .catch(err => console.error(err))
//     }
// }

export const fetchReviewsCategoryFilter = ({
  selectedSpace,
  apiData,
  dateFrom,
  dateTo,
  selectedApi,
  setCategoryData,
  page = 1,
  searchParam = null,
  type = 'union',
}) => {
  if ((apiData?.length > 0) & (dateFrom !== '') & (dateTo !== '')) {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${OlegHttps}/api/v1/filter/reviews/category?filter_type=${type}`;
    } else {
      url = `${OlegHttp}/api/v1/filter/reviews/category?filter_type=${type}`;
    }
    let api_id_list;

    if (selectedApi.length > 0) {
      api_id_list = selectedApi.map((el) => el.value);
    } else {
      api_id_list = apiData.map((el) => el.value);
    }
    if (selectedApi?.value) {
      api_id_list = [selectedApi?.value];
    }

    const body = JSON.stringify({
      search_param: searchParam.length < 1 ? null : searchParam,
      api_id_list,
      category_id_list: ['all'],
      brand_id_list: ['all'],
      product_id_list: ['all'],
      date_from: dayjs(dateFrom).format('YYYY-MM-DD'),
      date_to: dayjs(dateTo).format('YYYY-MM-DD'),
      page,
      limit: 50,
    });

    const headers = {
      ...currentHeaders,
      space: selectedSpace,
    };

    fetch(url, { body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        const dataArr = json?.map((el) => ({ label: el.name, value: el.id }));
        if (page > 1) {
          setCategoryData((prev) => [...prev, ...dataArr]);
        } else {
          setCategoryData([...dataArr]);
        }
      })
      .catch((err) => console.error(err));
  }
};

export const fetchReviewsBrandFilter = ({
  selectedSpace,
  apiData,
  categoryData,
  selectedCategory,
  dateFrom,
  dateTo,
  selectedApi,
  page = 1,
  setBrandData,
  searchParam = null,
  type = 'union',
}) => {
  if (
    (apiData?.length > 0) &
    (dateFrom !== '') &
    (dateTo !== '') &
    (categoryData?.length > 0)
  ) {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${OlegHttps}/api/v2/filter/reviews/brand?filter_type=${type}`;
    } else {
      url = `${OlegHttp}/api/v2/filter/reviews/brand?filter_type=${type}`;
    }
    let api_id_list;
    let category_id_list;

    if (selectedApi.length > 0) {
      api_id_list = selectedApi.map((el) => el.value);
    } else {
      api_id_list = apiData.map((el) => el.value);
    }
    if (selectedApi?.value) {
      api_id_list = [selectedApi?.value];
    }

    if (selectedCategory.length > 0) {
      category_id_list = selectedCategory.map((el) => el.value);
    } else {
      category_id_list = categoryData.map((el) => el.value);
    }

    const body = JSON.stringify({
      search_param: searchParam.length < 1 ? null : searchParam,
      api_id_list,
      category_id_list,
      brand_id_list: ['all'],
      product_id_list: ['all'],
      date_from: dayjs(dateFrom).format('YYYY-MM-DD'),
      date_to: dayjs(dateTo).format('YYYY-MM-DD'),
      page,
      limit: 50,
    });

    const headers = {
      ...currentHeaders,
      space: selectedSpace,
    };

    fetch(url, { body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        const dataArr = json.map((el) => ({ label: el.name, value: el.id }));
        if (page > 1) {
          setBrandData((prev) => [...prev, ...dataArr]);
        } else {
          setBrandData([...dataArr]);
          setBrandData([...dataArr]);
        }
      })
      .catch((err) => console.error(err));
  }
};

export const fetchCompetitorsFilter = ({
  selectedSpace,
  type,
  page = 1,
  setApiData,
  searchParam = null,
}) => {
  let url;
  if (!window.location.hostname.match('localhost')) {
    url = `${OlegHttps}/api/v1/filter/competitors/type_${type}/shop`;
  } else {
    url = `${OlegHttp}/api/v1/filter/competitors/type_${type}/shop`;
  }

  const body = JSON.stringify({
    shop_id_list: ['all'],
    brand_id_list: ['all'],
    category_id_list: ['all'],
    seller_id_list: ['all'],
    super_id_list: ['all'],
  });

  const headers = {
    ...currentHeaders,
    space: selectedSpace,
  };

  fetch(url, { body, method: 'POST', headers })
    .then((res) => res.json())
    .then((json) => {
      const dataArr = json.map((el) => ({
        label: el.name ? el.name : 'Не указано',
        value: el.id,
      }));
      if (page > 1) {
        setApiData((prev) => [...prev, ...dataArr]);
      } else {
        setApiData([...dataArr]);
      }
    })
    .catch((err) => console.error(err));
};

export const fetchCompetitorCategoryFilter = ({
  selectedSpace,
  type,
  apiData,
  selectedApi,
  setCategoryData,
  page = 1,
  searchParam = null,
}) => {
  if (apiData.length > 0) {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${OlegHttps}/api/v1/filter/competitors/type_${type}/super/category`;
    } else {
      url = `${OlegHttp}/api/v1/filter/competitors/type_${type}/super/category`;
    }

    let shop_id_list;

    if (selectedApi.length > 0) {
      shop_id_list = selectedApi.map((el) => el.value);
    } else {
      shop_id_list = apiData.map((el) => el.value);
    }

    const body = JSON.stringify({
      shop_id_list,
      category_id_list: ['all'],
      brand_id_list: ['all'],
      seller_id_list: ['all'],
      super_id_list: ['all'],
    });

    const headers = {
      ...currentHeaders,
      space: selectedSpace,
    };

    fetch(url, { body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        const dataArr = json.map((el) => ({
          label: el.name ? el.name : 'Не указано',
          value: el.id,
        }));
        if (page > 1) {
          setCategoryData((prev) => [...prev, ...dataArr]);
        } else {
          setCategoryData([...dataArr]);
        }
      })
      .catch((err) => console.error(err));
  }
};

export const fetchCompetitorBrandFilter = ({
  selectedSpace,
  type,
  apiData,
  categoryData,
  selectedCategory,
  selectedApi,
  page = 1,
  setBrandData,
  searchParam = null,
}) => {
  if ((apiData.length > 0) & (categoryData.length > 0)) {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${OlegHttps}/api/v1/filter/competitors/type_${type}/super/brand`;
    } else {
      url = `${OlegHttp}/api/v1/filter/competitors/type_${type}/super/brand`;
    }
    let shop_id_list;
    let category_id_list;

    if (selectedApi.length > 0) {
      shop_id_list = selectedApi.map((el) => el.value);
    } else {
      shop_id_list = apiData.map((el) => el.value);
    }

    if (selectedCategory.length > 0) {
      category_id_list = selectedCategory.map((el) => el.value);
    } else {
      category_id_list = categoryData.map((el) => el.value);
    }

    const body = JSON.stringify({
      shop_id_list,
      category_id_list,
      brand_id_list: ['all'],
      seller_id_list: ['all'],
      super_id_list: ['all'],
    });

    const headers = {
      ...currentHeaders,
      space: selectedSpace,
    };

    fetch(url, { body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        const dataArr = json.map((el) => ({
          label: el.name ? el.name : 'Не указано',
          value: el.id,
        }));
        if (page > 1) {
          setBrandData((prev) => [...prev, ...dataArr]);
        } else {
          setBrandData([...dataArr]);
        }
      })
      .catch((err) => console.error(err));
  }
};

export const fetchCompetitorProductFilter = ({
  selectedSpace,
  type,
  apiData,
  categoryData,
  selectedCategory,
  selectedBrand,
  brandData,
  selectedApi,
  page = 1,
  setProductsData,
  searchParam = null,
}) => {
  if (
    (apiData.length > 0) &
    (categoryData.length > 0) &
    (selectedBrand.length > 0)
  ) {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${OlegHttps}/api/v1/filter/competitors/type_${type}super/seller`;
    } else {
      url = `${OlegHttp}/api/v1/filter/competitors/type_${type}/super/seller`;
    }

    let shop_id_list;
    let category_id_list;
    let brand_id_list;

    if (selectedApi.length > 0) {
      shop_id_list = selectedApi.map((el) => el.value);
    } else {
      shop_id_list = apiData.map((el) => el.value);
    }

    if (selectedCategory.length > 0) {
      category_id_list = selectedCategory.map((el) => el.value);
    } else {
      category_id_list = categoryData.map((el) => el.value);
    }

    if (selectedBrand.length > 0) {
      brand_id_list = selectedBrand.map((el) => el.value);
    } else {
      brand_id_list = brandData.map((el) => el.value);
    }

    const body = JSON.stringify({
      shop_id_list,
      brand_id_list,
      category_id_list,
      seller_id_list: ['all'],
      super_id_list: ['all'],
    });

    const headers = {
      ...currentHeaders,
      space: selectedSpace,
    };

    fetch(url, { body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        const dataArr = json.map((el) => ({
          label: el.name ? el.name : 'Не указано',
          value: el.id,
        }));
        if (page > 1) {
          setProductsData((prev) => [...prev, ...dataArr]);
        } else {
          setProductsData([...dataArr]);
        }
      })
      .catch((err) => console.error(err));
  }
};

export const fetchCompetitorSuperFilter = ({
  selectedSpace,
  type,
  apiData,
  categoryData,
  selectedCategory,
  selectedBrand,
  brandData,
  selectedApi,
  page = 1,
  setSuperData,
  productsData,
  selectedProduct,
  searchParam = null,
}) => {
  if (
    (apiData.length > 0) &
    (categoryData.length > 0) &
    (selectedBrand.length > 0)
  ) {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${OlegHttps}/api/v1/filter/competitors/type_${type}/super/prouct`;
    } else {
      url = `${OlegHttp}/api/v1/filter/competitors/type_${type}/super/product`;
    }

    let shop_id_list;
    let category_id_list;
    let brand_id_list;
    let seller_id_list;

    if (selectedApi.length > 0) {
      shop_id_list = selectedApi.map((el) => el.value);
    } else {
      shop_id_list = apiData.map((el) => el.value);
    }

    if (selectedCategory.length > 0) {
      category_id_list = selectedCategory.map((el) => el.value);
    } else {
      category_id_list = categoryData.map((el) => el.value);
    }

    if (selectedBrand.length > 0) {
      brand_id_list = selectedBrand.map((el) => el.value);
    } else {
      brand_id_list = brandData.map((el) => el.value);
    }

    if (selectedProduct.length > 0) {
      seller_id_list = selectedProduct.map((el) => el.value);
    } else {
      seller_id_list = productsData.map((el) => el.value);
    }

    const body = JSON.stringify({
      shop_id_list,
      brand_id_list,
      category_id_list,
      seller_id_list,
      super_id_list: ['all'],
    });

    const headers = {
      ...currentHeaders,
      space: selectedSpace,
    };

    fetch(url, { body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        const dataArr = json.map((el) => ({
          label: el.name ? el.name : 'Не указано',
          value: el.id,
        }));
        if (page > 1) {
          setSuperData((prev) => [...prev, ...dataArr]);
        } else {
          setSuperData([...dataArr]);
        }
      })
      .catch((err) => console.error(err));
  }
};

export const fetchCompetitorsCategoryMasterFilter = ({
  selectedSpace,
  page = 1,
  setApiData,
  searchParam = null,
}) => {
  let url;
  if (!window.location.hostname.match('localhost')) {
    url = `${OlegHttps}/api/v1/filter/competitors/category_master`;
  } else {
    url = `${OlegHttp}/api/v1/filter/competitors/category_master`;
  }
  const body = JSON.stringify({
    search_param: searchParam.length < 1 ? null : searchParam,
    limit: 50,
    page,
  });

  const headers = {
    ...currentHeaders,
    space: selectedSpace,
  };

  fetch(url, { body, method: 'POST', headers })
    .then((res) => res.json())
    .then((json) => {
      const dataArr = json.map((el) => ({ label: el.name, value: el.id }));
      if (page > 1) {
        setApiData((prev) => [...prev, ...dataArr]);
      } else {
        setApiData([...dataArr]);
      }
    })
    .catch((err) => console.error(err));
};

export const fetchCompetitorsCategorySlaveFilter = ({
  selectedSpace,
  page = 1,
  setApiData,
  searchParam = null,
  master_category_id_list,
}) => {
  let url;
  if (!window.location.hostname.match('localhost')) {
    url = `${OlegHttps}/api/v1/filter/competitors/category_slave`;
  } else {
    url = `${OlegHttp}/api/v1/filter/competitors/category_slave`;
  }
  const body = JSON.stringify({
    master_category_id_list,
    search_param: searchParam.length < 1 ? null : searchParam,
    limit: 50,
    page,
  });

  const headers = {
    ...currentHeaders,
    space: selectedSpace,
  };

  fetch(url, { body, method: 'POST', headers })
    .then((res) => res.json())
    .then((json) => {
      const dataArr = json.map((el) => ({ label: el.name, value: el.id }));
      if (page > 1) {
        setApiData((prev) => [...prev, ...dataArr]);
      } else {
        setApiData([...dataArr]);
      }
    })
    .catch((err) => console.error(err));
};

const fetchCompetitorsFilterInternal = ({
  selectedSpace,
  url,
  body,
  page = 1,
  setData,
  mapFunc,
  errorCallback,
  cleanupCallback,
  emptyResponseCallback,
}) => {
  const headers = {
    ...currentHeaders,
    space: selectedSpace,
  };

  fetch(url, { headers, method: 'POST', body })
    .then((response) => {
      if (response.status === 204) {
        if (typeof emptyResponseCallback === 'function')
          emptyResponseCallback();
        return [];
      }
      if (response.status !== 200)
        throw new Error('bad competitors brand response');
      return response.json();
    })
    .then((json) =>
      json.length ? setDataFromJson(json, page, setData, true, mapFunc) : {}
    )
    .catch((error) => {
      if (typeof errorCallback === 'function') errorCallback(error);
      else console.error(error);
    })
    .finally(() =>
      typeof cleanupCallback === 'function' ? cleanupCallback() : {}
    );
};

export const fetchCompetitorsBrandFilter = ({
  selectedSpace,
  page = 1,
  limit = 50,
  searchParam = null,
  setData,
  mapFunc,
  errorCallback,
  cleanupCallback,
  emptyResponseCallback,
}) => {
  const body = JSON.stringify({
    search_param: searchParam,
    page,
    limit,
  });

  fetchCompetitorsFilterInternal({
    selectedSpace,
    url: `${OlegHttps}/api/v1/filter/competitors/brand`,
    page,
    body,
    setData,
    mapFunc,
    errorCallback,
    cleanupCallback,
    emptyResponseCallback,
  });
};

export const fetchCompetitorsSellerFilter = ({
  selectedSpace,
  page = 1,
  limit = 50,
  searchParam,
  setData,
  mapFunc,
  errorCallback,
  cleanupCallback,
  emptyResponseCallback,
}) => {
  const body = JSON.stringify({
    search_param: searchParam,
    page,
    limit,
  });

  fetchCompetitorsFilterInternal({
    selectedSpace,
    url: `${OlegHttps}/api/v1/filter/competitors/seller`,
    page,
    body,
    setData,
    mapFunc,
    errorCallback,
    cleanupCallback,
    emptyResponseCallback,
  });
};

export const fetchCompetitorsLinkBrandFilter = ({
  selectedSpace,
  page = 1,
  limit = 50,
  searchParam = null,
  shopData,
  selectedShop,
  categoryData,
  selectedCategory,
  setData,
  mapFunc,
  errorCallback,
  cleanupCallback,
}) => {
  if (!shopData?.length || !categoryData.length) {
    console.warn('no shop or category data for brand filter is provided');
    return;
  }

  const body = JSON.stringify({
    search_param: searchParam,
    page,
    limit,
    shop_name_list: selectedShop?.length
      ? selectedShop.map((el) => (el.label === 'Все' ? 'all' : el.label))
      : shopData.map((el) => (el.label === 'Все' ? 'all' : el.value)),
    category_name_list: selectedCategory?.length
      ? selectedCategory.map((el) => (el.label === 'Все' ? 'all' : el.label))
      : categoryData.map((el) => (el.label === 'Все' ? 'all' : el.value)),
    brand_name_list: ['all'],
  });

  fetchCompetitorsFilterInternal({
    selectedSpace,
    url: `${OlegHttps}/api/v1/filter/competitors/link/brand`,
    page,
    body,
    setData,
    mapFunc,
    errorCallback,
    cleanupCallback,
  });
};

export const fetchCompetitorsLinkCategoryFilter = ({
  selectedSpace,
  page = 1,
  limit = 50,
  searchParam = null,
  shopData,
  selectedShop,
  setData,
  mapFunc,
  errorCallback,
  cleanupCallback,
}) => {
  if (!shopData.length) {
    console.warn('no shop data for category filter is provided');
    return;
  }

  const body = JSON.stringify({
    search_param: searchParam,
    page,
    limit,
    shop_name_list: selectedShop?.length
      ? selectedShop.map((el) => (el.label === 'Все' ? 'all' : el.label))
      : shopData.map((el) => (el.label === 'Все' ? 'all' : el.value)),
    category_name_list: ['all'],
    brand_name_list: ['all'],
  });

  fetchCompetitorsFilterInternal({
    selectedSpace,
    url: `${OlegHttps}/api/v1/filter/competitors/link/category`,
    page,
    body,
    setData,
    mapFunc,
    errorCallback,
    cleanupCallback,
  });
};

export const fetchCompetitorsLinkShopFilter = ({
  selectedSpace,
  page = 1,
  limit = 50,
  searchParam = null,
  setData,
  mapFunc,
  errorCallback,
  cleanupCallback,
}) => {
  const body = JSON.stringify({
    search_param: searchParam,
    page,
    limit,
    shop_name_list: ['all'],
    category_name_list: ['all'],
    brand_name_list: ['all'],
  });

  fetchCompetitorsFilterInternal({
    selectedSpace,
    url: `${OlegHttps}/api/v1/filter/competitors/link/shop`,
    page,
    body,
    setData,
    mapFunc,
    errorCallback,
    cleanupCallback,
  });
};

export const fetchCompetitor2CategoryFilter = ({
  selectedSpace,
  type,
  apiData,
  selectedApi,
  setCategoryData,
  page = 1,
  searchParam = null,
}) => {
  if (apiData.length > 0) {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${OlegHttps}/api/v1/filter/competitors/type_${type}/category`;
    } else {
      url = `${OlegHttp}/api/v1/filter/competitors/type_${type}/category`;
    }
    let shop_id_list;

    if (selectedApi.length > 0) {
      shop_id_list = selectedApi.map((el) => el.value);
    } else {
      shop_id_list = apiData.map((el) => el.value);
    }

    const body = JSON.stringify({
      shop_id_list,
      category_id_list: ['all'],
      brand_id_list: ['all'],
      seller_id_list: ['all'],
      page,
      limit: 50,
    });

    const headers = {
      ...currentHeaders,
      space: selectedSpace,
    };

    fetch(url, { body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        const dataArr = json.map((el) => ({
          label: el.name ? el.name : 'Не указано',
          value: el.id,
        }));
        if (page > 1) {
          setCategoryData((prev) => [...prev, ...dataArr]);
        } else {
          setCategoryData([...dataArr]);
        }
      })
      .catch((err) => console.error(err));
  }
};

export const fetchCompetitor2BrandFilter = ({
  selectedSpace,
  type,
  apiData,
  categoryData,
  selectedCategory,
  selectedApi,
  page = 1,
  setBrandData,
  searchParam = null,
}) => {
  if ((apiData.length > 0) & (categoryData.length > 0)) {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${OlegHttps}/api/v1/filter/competitors/type_${type}/brand`;
    } else {
      url = `${OlegHttp}/api/v1/filter/competitors/type_${type}/brand`;
    }
    let shop_id_list;
    let category_id_list;

    if (selectedApi.length > 0) {
      shop_id_list = selectedApi.map((el) => el.value);
    } else {
      shop_id_list = apiData.map((el) => el.value);
    }

    if (selectedCategory.length > 0) {
      category_id_list = selectedCategory.map((el) => el.value);
    } else {
      category_id_list = categoryData.map((el) => el.value);
    }

    const body = JSON.stringify({
      shop_id_list,
      category_id_list: ['all'],
      brand_id_list: ['all'],
      seller_id_list: ['all'],
    });

    const headers = {
      ...currentHeaders,
      space: selectedSpace,
    };

    fetch(url, { body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        const dataArr = json.map((el) => ({
          label: el.name ? el.name : 'Не указано',
          value: el.id,
        }));
        if (page > 1) {
          setBrandData((prev) => [...prev, ...dataArr]);
        } else {
          setBrandData([...dataArr]);
        }
      })
      .catch((err) => console.error(err));
  }
};

export const fetchCompetitor2ProductFilter = ({
  selectedSpace,
  type,
  apiData,
  categoryData,
  selectedCategory,
  selectedBrand,
  brandData,
  selectedApi,
  page = 1,
  setProductsData,
  searchParam = null,
}) => {
  if (apiData.length > 0) {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${OlegHttps}/api/v1/filter/competitors/type_${type}/seller`;
    } else {
      url = `${OlegHttp}/api/v1/filter/competitors/type_${type}/seller`;
    }
    let shop_id_list;
    let category_id_list;
    let brand_id_list;

    if (selectedApi.length > 0) {
      shop_id_list = selectedApi.map((el) => el.value);
    } else {
      shop_id_list = apiData.map((el) => el.value);
    }

    if (selectedCategory.length > 0) {
      category_id_list = selectedCategory.map((el) => el.value);
    } else {
      category_id_list = categoryData.map((el) => el.value);
    }

    if (selectedBrand.length > 0) {
      brand_id_list = selectedBrand.map((el) => el.value);
    } else {
      brand_id_list = brandData.map((el) => el.value);
    }

    const body = JSON.stringify({
      shop_id_list,
      brand_id_list: brand_id_list.length == 0 ? ['all'] : brand_id_list,
      category_id_list:
        category_id_list.length == 0 ? ['all'] : category_id_list,
      seller_id_list: ['all'],
      page,
      limit: 50,
    });

    const headers = {
      ...currentHeaders,
      space: selectedSpace,
    };

    fetch(url, { body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        const dataArr = json.map((el) => ({
          label: el.name ? el.name : 'Не указано',
          value: el.id,
        }));
        if (page > 1) {
          setProductsData((prev) => [...prev, ...dataArr]);
        } else {
          setProductsData([...dataArr]);
        }
      })
      .catch((err) => console.error(err));
  }
};

// export const fetchWarehouseFilter = ({ setApiData, page = 1, limit = 50, searchParam = null, }) => {
//     let url
//         if (!window.location.hostname.match('localhost')) {
//             url = `${OlegHttps}/api/v1/filter/warehouse/api_id?filter_type=union`
//         } else {
//             url = `${OlegHttp}/api/v1/filter/warehouse/api_id?filter_type=union`
//         }

//         const body = JSON.stringify({
//             page,
//             limit
//         })

//         fetch(url, { body, method: 'POST', headers })
//             .then(res => res.json())
//             .then(json => setDataFromJson(json, page, setApiData, false))
//             .catch(err => console.error(err))
// }

export const fetchWarehouseWarehouseFilter = ({
  selectedSpace,
  apiData,
  selectedApi,
  setWarehouseData,
  page = 1,
}) => {
  if (apiData.length > 0) {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${OlegHttps}/api/v1/filter/warehouse/warehouse?filter_type=union`;
    } else {
      url = `${OlegHttp}/api/v1/filter/warehouse/warehouse?filter_type=union`;
    }
    let api_id_list;

    if (selectedApi.length > 0) {
      api_id_list = selectedApi.map((el) => el.value);
    } else {
      api_id_list = apiData.map((el) => el.value);
    }

    const body = JSON.stringify({
      api_id_list,
      limit: 50,
      page,
    });

    const headers = {
      ...currentHeaders,
      space: selectedSpace,
    };

    fetch(url, { body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => setDataFromJson(json, page, setWarehouseData, true))
      .catch((err) => console.error(err));
  }
};

// Заказы

export const fetchOrdersFilter = ({
  selectedSpace,
  page = 1,
  setData,
  searchParam = null,
  type = 'ozon',
  target = 'api_id',
  apiData,
  selectedApi,
  brandData,
  selectedBrand,
  categoryData,
  selectedCategory,
  warehouseData,
  selectedWarehouse,
  dateFrom,
  dateTo,
  withAll = true,
  abortController = new AbortController(),
}) => {
  let url;
  if (!window.location.hostname.match('localhost')) {
    url = `${ArtemOrdersHttps}/get-postings/filter/${target}?filter_type=${type}`;
  } else {
    url = `${ArtemOrdersHttp}/get-postings/filter/${target}?filter_type=${type}`;
  }

  let api_id;
  let category;
  let brand;
  let warehouse;

  if (selectedApi?.value) {
    api_id = selectedApi?.value;
  }

  if (selectedCategory?.length > 0) {
    category = selectedCategory?.value;
  } else {
    category = 'all';
  }

  if (selectedBrand?.length > 0) {
    brand = selectedBrand?.value;
  } else {
    brand = 'all';
  }

  if (selectedWarehouse?.length > 0) {
    warehouse = selectedWarehouse.value;
  } else {
    warehouse = 'all';
  }

  const body = JSON.stringify({
    search_param: searchParam.length < 1 ? null : searchParam,
    api_id,
    brand,
    category,
    warehouse,
    limit: 50,
    page,
    date_from: dateFrom
      ? new Date(dateFrom)?.toISOString().split('T')?.[0]
      : null,
    date_to: dateTo ? new Date(dateTo)?.toISOString().split('T')?.[0] : null,
  });

  const headers = {
    ...currentHeaders,
    space: selectedSpace,
  };

  fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
    .then((res) => res.json())
    .then(({ options }) => {
      setDataFromJson(options, page, setData, withAll, ({ name, id }) => ({
        label: name,
        value: id,
      }));
    })
    .catch((err) => console.error(err));
};

// Заказы

export const fetchOrdersFilterNew = ({
  selectedSpace,
  page = 1,
  setData,
  searchParam = null,
  type = 'ozon',
  target = 'api_id',
  apiData,
  selectedApi,
  brandData,
  selectedBrand,
  categoryData,
  selectedCategory,
  warehouseData,
  selectedWarehouse,
  dateFrom,
  dateTo,
  withAll = true,
  abortController = new AbortController(),
}) => {
  let url;
  if (!window.location.hostname.match('localhost')) {
    url = `${ArtemOrdersHttps}/orders/get-filter-options/${target}?filter_type=${type}`;
  } else {
    url = `${ArtemOrdersHttp}/orders/get-filter-options/${target}?filter_type=${type}`;
  }

  let api_id;
  let category;
  let brand;
  let warehouse;

  if (selectedApi?.value) {
    api_id = selectedApi?.value;
  }

  if (selectedCategory?.value) {
    category = selectedCategory?.value;
  } else {
    category = 'all';
  }

  if (selectedBrand?.value) {
    brand = selectedBrand?.value;
  } else {
    brand = 'all';
  }

  if (selectedWarehouse?.value) {
    warehouse = selectedWarehouse?.value;
  } else {
    warehouse = 'all';
  }

  const body = JSON.stringify({
    search_param: searchParam.length < 1 ? null : searchParam,
    api_id,
    brand,
    category,
    warehouse,
    limit: 50,
    page,
    date_from: dateFrom
      ? new Date(dateFrom)?.toISOString().split('T')?.[0]
      : null,
    date_to: dateTo ? new Date(dateTo)?.toISOString().split('T')?.[0] : null,
  });

  const headers = {
    ...currentHeaders,
    space: selectedSpace,
  };

  fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
    .then((res) => res.json())
    .then(({ options }) => {
      setDataFromJson(options, page, setData, withAll, ({ name, id }) => ({
        label: name,
        value: id,
      }));
    })
    .catch((err) => console.error(err));
};
