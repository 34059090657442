import { useDataTable } from '../../common/hooks';
import { FilterContainer } from '../../components/FilterContainer/FilterContainer';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect, useCallback, useRef } from 'react';
import { BtnDropCheckbox } from '../../components/BtnDropCheckbox/BtnDropCheckbox';
import {
  Tabs,
  TableBox,
  DropDownSelector,
  ButtonBasic,
  RangeBox,
} from '@lk-gtcom/ecomlab-components';
import './CalculationDelivery.scss';
import useGeneralStore from '../../store/general';
import customHeaders, { getSpace } from '../../common/headers';
import { OlegHttp, OlegHttps } from '../../fetchUrls';
import {
  fetchApiFilter,
  fetchBrandFilter,
  fetchCategoryFilter,
} from '../../common/filtersFunc';

const list_select = [
  {
    label: 'Площадка',
  },
  {
    label: 'Магазин',
  },
  {
    label: 'Бренд',
  },
  {
    label: 'Категория',
  },
];

const btn_tab = [
  {
    label: 'Рассчет',
    value: 'calculation',
  },
  {
    label: 'Параметры',
    value: 'parameters',
  },
  {
    label: 'Интеллектуальный рассчет',
    value: 'intelligent_calculation',
  },
];

const options_prop = [
  {
    label: 'Ozon',
    value: { name: 'ozon', id: 1 },
  },
  {
    label: 'Wildberries',
    value: { name: 'wb', id: 3 },
  },
  {
    label: 'Yandex',
    value: { name: 'yandex', id: 2 },
  },
];

const CalculationDelivery = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [pageValue, setPageValue] = useState('');
  const [checkboxValues, setCheckboxValues] = useState([
    'Площадка',
    'Магазин',
    'Категория',
    'Бренд',
  ]);

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);

  const [dateFrom, dateTo] = date;
  const [apiErr, setApiErr] = useState(false);

  const [selectedMarketplace, setSelectedMarketplace] = useState([
    options_prop[0],
  ]);

  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);

  const [selectedApi, setSelectedApi] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);

  const [apiData, setApiData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);

  const [apiPage, setApiPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);

  const [apiQuery, setApiQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');

  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { loading, sort, filters } = tableProps;
  const { setLoading, setFetchedData, setHeaders, setSort, setFilters } =
    setTableFuncs;

  const activeTab = useParams()['*'];

  const fetchTableData = (params) => {
    let sort, filters;
    if (params) {
      [sort, filters] = params;
    }

    setLoading(true);

    const url = !window.location.hostname.match('localhost')
      ? `${OlegHttps}/api/v1/supply/calculation/table/days${
          sort?.length > 0
            ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
            : ''
        }`
      : `${OlegHttp}/api/v1/supply/calculation/table/days${
          sort?.length > 0
            ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
            : ''
        }`;

    const api_id_list =
      selectedApi?.length > 0
        ? selectedApi.map((el) => String(el.value))
        : [''];

    const category_id_list =
      selectedCategory?.length > 0
        ? selectedCategory.map((el) => el.value)
        : [];

    const brand_id_list =
      selectedBrand?.length > 0 ? selectedBrand.map((el) => el.value) : [];

    const body = JSON.stringify({
      page: paginatorRef.current?.page,
      limit: paginatorRef.current?.limit,
      category_id_list: category_id_list,
      brand_id_list: brand_id_list,
      api_id_list: api_id_list,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json, 'json');
        setTable(json);
        setTableUrl(url);
        setTableBody(body);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchApiFilter({
      selectedSpace: selectedSpace?.value,
      page: apiPage,
      setApiData: (e) => setApiData(e),
      searchParam: apiQuery,
      type: selectedMarketplace?.value,
      selectedShop: [
        {
          label: selectedMarketplace?.value?.name,
          value: selectedMarketplace?.value?.id,
        },
      ],
    });
  }, [selectedSpace, apiPage, apiQuery, selectedMarketplace]);

  useEffect(() => {
    fetchCategoryFilter({
      selectedSpace: selectedSpace?.value,
      page: categoryPage,
      apiData,
      dateFrom,
      dateTo,
      selectedApi,
      setCategoryData: (e) => setCategoryData(e),
      searchParam: categoryQuery,
    });
  }, [
    selectedSpace,
    apiData,
    dateFrom,
    dateTo,
    selectedApi,
    categoryPage,
    categoryQuery,
  ]);

  useEffect(() => {
    fetchBrandFilter({
      selectedSpace: selectedSpace?.value,
      page: brandPage,
      apiData,
      categoryData,
      selectedCategory,
      dateFrom,
      dateTo,
      searchParam: brandQuery,
      selectedApi,
      setBrandData: (e) => setBrandData(e),
    });
  }, [selectedSpace, categoryData, selectedCategory, brandPage, brandQuery]);

  useEffect(() => {
    setPageValue(activeTab);
  }, [activeTab]);

  useEffect(() => {
    fetchTableData();
  }, [selectedCategory, selectedApi, selectedBrand]);

  return (
    <div className="calculation-delivery">
      <h1 className="calculation-delivery__title-main">Расчет поставки</h1>
      <div className="calculation-delivery__toolbar-top">
        <FilterContainer
          onReset={(e) => {
            setSelectedApi([]);
            setSelectedCategory([]);
            setSelectedBrand([]);
          }}
        >
          {checkboxValues.includes('Площадка') && (
            <DropDownSelector
              options_prop={options_prop}
              state={selectedMarketplace}
              setState={(e) => {
                setSelectedMarketplace(e);
              }}
              className="connections_page_selector"
              placeholder="Площадка"
              defaultValue={options_prop[0]}
            />
          )}
          {checkboxValues.includes('Магазин') && (
            <DropDownSelector
              err={apiErr}
              state={selectedApi}
              options_prop={apiData}
              setState={(e) => setSelectedApi(e)}
              className="connections_page_selector"
              placeholder="Магазин"
              fetchCallback={(e) => setApiPage(e)}
              setInputChange={(e) => setApiQuery(e)}
              multi
            />
          )}

          {checkboxValues.includes('Категория') && (
            <DropDownSelector
              options_prop={categoryData}
              state={selectedCategory}
              setState={(e) => setSelectedCategory(e)}
              placeholder="Категория"
              fetchCallback={(e) => setCategoryPage(e)}
              setInputChange={(e) => setCategoryQuery(e)}
              defaultValue={[{ label: 'Все', value: 'all' }]}
              multi
            />
          )}

          {checkboxValues.includes('Бренд') && (
            <DropDownSelector
              options_prop={brandData}
              state={selectedBrand}
              setState={(e) => setSelectedBrand(e)}
              placeholder="Бренд"
              fetchCallback={(e) => setBrandPage(e)}
              setInputChange={(e) => setBrandQuery(e)}
              defaultValue={[{ label: 'Все', value: 'all' }]}
              multi
            />
          )}
          <div className="calculation-delivery__toolbar-top_right">
            {(pageValue == 'intelligent_calculation' ||
              pageValue == 'parameters') && (
              <RangeBox setDate={(e) => setDate(e)} hideBtnGroup />
            )}
            <BtnDropCheckbox
              checkboxValues={checkboxValues}
              setCheckboxValues={(e) => setCheckboxValues(e)}
              list={list_select}
            />
          </div>
        </FilterContainer>
      </div>
      <Tabs tabs={btn_tab} pageValue={pageValue} />
      <TableBox
        {...tableProps}
        paginator={true}
        ref={paginatorRef}
        onCheck={(e) => e}
        fetchCallback={(e) => fetchTableData(e)}
        sortingFunc={(e) => {
          fetchTableData(e);
        }}
        tableUrl={`/supply/calculation/extract/to_excel/table/${'days'}`}
        tableBody={tableBody}
        child={
          <>
            {pageValue == 'calculation' && (
              <div className="calculation-delivery__header-table">
                <label className="calculation-delivery__label">
                  <span>Кол-во дней до поставки:</span>
                  <input
                    type="number"
                    className="calculation-delivery__input"
                  ></input>
                </label>
                <ButtonBasic
                  green
                  maxWidth="270px"
                  width="100%"
                  text="Рассчитать"
                />
              </div>
            )}

            {pageValue == 'intelligent_calculation' && (
              <div className="calculation-delivery__header-table">
                <div className="calculation-delivery__info-content">
                  <h4 className="calculation-delivery__title-tooltip">
                    Мы рассчитываем когда вам наиболее выгодно везти товар на
                    склад
                  </h4>
                  <p className="calculation-delivery__text-tooltip">
                    Например – ваши потери продаж могут составлять 100 руб. А
                    себестоимость доставки 10 000 руб. По этому мы указываем
                    дату когда наиболее эффективно везти товар
                  </p>
                </div>
                <ButtonBasic
                  green
                  maxWidth="270px"
                  width="100%"
                  text="Рассчитать"
                />
              </div>
            )}
          </>
        }
      />
    </div>
  );
};

export { CalculationDelivery };
