import { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { FilterContainer } from '../../components/FilterContainer/FilterContainer';
import {
  DropDownSelector,
  Checkbox,
  ButtonBasic,
} from '@lk-gtcom/ecomlab-components';
import { JoinedPromotions } from '../../components/JoinedPromotions/JoinedPromotions';
import { ListPromotionVer2 } from '../../components/ListPromotionVer2/ListPromotionVer2';
import { PromotionRules } from '../../components/PromotionRules/PromotionRules';
import useGeneralStore from '../../store/general';
import customHeaders, { getSpace } from '../../common/headers';
import './PromotionPage.scss';
import { OlegHttps } from '../../fetchUrls';

const options_prop = [
  { label: 'Ozon', value: 1 },
  { label: 'Wildberries', value: 3 },
  { label: 'Yandex', value: 2 },
];

const transformToOldFormat = (data) => {
  return data.map((item) => ({
    label: item.name,
    value: item.id,
  }));
};

const fetchFilterData = async ({
  filterName,
  headers,
  page = 1,
  searchParam = '',
  selectedFilters = {},
}) => {
  const payload = {
    search_param: searchParam,
    page: page,
    limit: 10,
    category_id_list: selectedFilters.category?.map(
      (category) => category.value
    ) || ['all'],
    brand_id_list: selectedFilters.brand?.map((brand) => brand.value) || [
      'all',
    ],
    api_id_list: selectedFilters.api?.map((api) => api.value) || ['all'],
    shop_id_list:
      selectedFilters.shop?.map((shop) => shop.value.toString()) || [],
    campaign_id_list: selectedFilters.campaign || [],
    selected_id_list: selectedFilters.selected_id_list || [],
  };

  try {
    const response = await fetch(`${OlegHttps}/api/v3/filter/${filterName}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      const data = await response.json();
      console.log(data);
      return data || [];
    } else {
      console.error(`Failed to fetch ${filterName}:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching ${filterName}:`, error);
  }
  return [];
};

const PromotionsPage = () => {
  const location = useLocation();
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };

  const [apiData, setApiData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [actionData, setActionData] = useState([]);

  const [selectedMarketplace, setSelectedMarketplace] = useState([]);
  const [selectedApi, setSelectedApi] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedAction, setSelectedAction] = useState([]);

  const [apiPage, setApiPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);
  const [actionPage, setActionPage] = useState(1);

  const [apiQuery, setApiQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');
  const [actionQuery, setActionQuery] = useState('');

  const [check, setCheck] = useState(false);
  const [isModal, setIsModal] = useState(false);

  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return {
      action: params.get('action'),
      api_id: params.get('api_id'),
    };
  };
  const { action, api_id } = getQueryParams();

  useEffect(() => {
    const selectedApiIds = api_id ? api_id.split(',') : [];
    console.log(selectedApiIds);

    if (selectedMarketplace?.length > 0) {
      fetchFilterData({
        filterName: 'api_id',
        headers,
        page: apiPage,
        searchParam: apiQuery,
        selectedFilters: {
          shop: selectedMarketplace,
          selected_id_list: api_id ? selectedApiIds : '',
        },
      }).then((data) => {
        console.log('work');

        console.log(transformToOldFormat(data?.filter_data), 'asdasd');
        setApiData(transformToOldFormat(data?.filter_data));
        if (data?.selected.length) {
          setSelectedApi(transformToOldFormat(data?.selected));
        }
      });
    }
  }, [api_id, apiPage, selectedMarketplace, apiQuery, selectedSpace]);

  useEffect(() => {
    fetchFilterData({
      filterName: 'category',
      headers,
      page: categoryPage,
      searchParam: categoryQuery,
      selectedFilters: {
        api: selectedApi,
        shop: selectedMarketplace,
      },
    }).then((data) => {
      setCategoryData(transformToOldFormat(data?.filter_data));
      if (data?.selected.length) {
        setSelectedCategory(transformToOldFormat(data?.selected));
      }
    });
  }, [
    categoryPage,
    selectedApi,
    selectedMarketplace,
    categoryQuery,
    selectedSpace,
  ]);

  useEffect(() => {
    fetchFilterData({
      filterName: 'brand',
      headers,
      page: brandPage,
      searchParam: brandQuery,
      selectedFilters: {
        api: selectedApi,
        category: selectedCategory,
        shop: selectedMarketplace,
      },
    }).then((data) => {
      setBrandData(transformToOldFormat(data?.filter_data));
      if (data?.selected.length) {
        setSelectedBrand(transformToOldFormat(data?.selected));
      }
    });
  }, [
    brandPage,
    selectedApi,
    selectedCategory,
    selectedMarketplace,
    brandQuery,
    selectedSpace,
  ]);

  useEffect(() => {
    fetchFilterData({
      filterName: 'action',
      headers,
      page: actionPage,
      searchParam: actionQuery,
      selectedFilters: {
        api: selectedApi,
        category: selectedCategory,
        brand: selectedBrand,
        shop: selectedMarketplace,
        selected_id_list: action ? [action] : '',
      },
    }).then((data) => {
      setActionData(transformToOldFormat(data.filter_data));
      if (data?.selected?.length) {
        setSelectedAction(transformToOldFormat(data?.selected));
      }
    });
  }, [
    action,
    actionPage,
    selectedApi,
    selectedCategory,
    selectedBrand,
    selectedMarketplace,
    actionQuery,
    selectedSpace,
  ]);

  const getTitle = () => {
    if (location.pathname.includes('joined-promotions')) {
      return 'Товары в акции';
    } else if (location.pathname.includes('list-promotion')) {
      return 'Список акций';
    } else if (location.pathname.includes('promotion-rules')) {
      return 'Правила';
    }
  };

  const filters = (
    <>
      <FilterContainer>
        <DropDownSelector
          options_prop={options_prop}
          state={selectedMarketplace}
          setState={(e) => setSelectedMarketplace(Array.isArray(e) ? e : [e])}
          placeholder="Площадка"
          defaultValue={options_prop[0]}
          multi
        />

        <DropDownSelector
          options_prop={apiData}
          state={selectedApi}
          setState={(e) => setSelectedApi(e)}
          placeholder="Магазин"
          fetchCallback={(e) => setApiPage(e)}
          setInputChange={(e) => setApiQuery(e)}
          multi
        />
        <DropDownSelector
          options_prop={categoryData}
          state={selectedCategory}
          setState={(e) => setSelectedCategory(e)}
          placeholder="Категория"
          fetchCallback={(e) => setCategoryPage(e)}
          setInputChange={(e) => setCategoryQuery(e)}
          defaultValue={[{ label: 'Все', value: 'all' }]}
          multi
        />
        <DropDownSelector
          options_prop={brandData}
          state={selectedBrand}
          setState={(e) => setSelectedBrand(e)}
          placeholder="Бренд"
          fetchCallback={(e) => setBrandPage(e)}
          setInputChange={(e) => setBrandQuery(e)}
          defaultValue={[{ label: 'Все', value: 'all' }]}
          multi
        />
        {location.pathname.includes('joined-promotions') && (
          <DropDownSelector
            options_prop={actionData}
            state={selectedAction}
            setState={(e) => setSelectedAction(e)}
            placeholder="Акции"
            fetchCallback={(e) => setActionPage(e)}
            setInputChange={(e) => setActionQuery(e)}
            defaultValue={[{ label: 'Все', value: 'all' }]}
            multi
          />
        )}
        {location.pathname.includes('joined-promotions') && (
          <Checkbox value={check} onChange={() => setCheck(!check)}>
            <p className="label-check">Без 0 остатков</p>
          </Checkbox>
        )}
      </FilterContainer>

      {location.pathname.includes('promotion-rules') && (
        <ButtonBasic
          violet
          onClick={() => setIsModal(true)}
          text={
            <p
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'center',
                gap: '8px',
              }}
            >
              Добавить правило +
            </p>
          }
        />
      )}
    </>
  );

  return (
    <div className="settings__content">
      <div className="promotion_page">
        <h1 className="title_main">{getTitle()}</h1>
        {filters}
        <Routes>
          <Route
            path="joined-promotions/*"
            element={
              <JoinedPromotions
                selectedMarketplace={selectedMarketplace}
                selectedApi={selectedApi}
                selectedCategory={selectedCategory}
                selectedBrand={selectedBrand}
                selectedAction={selectedAction}
                check={check}
              />
            }
          />
          <Route
            path="list-promotion/*"
            element={
              <ListPromotionVer2
                setShowTooltipLauncAdvertisingCampaign={() => {}}
                selectedMarketplace={selectedMarketplace}
                selectedApi={selectedApi}
                selectedCategory={selectedCategory}
                selectedBrand={selectedBrand}
              />
            }
          />
          <Route
            path="promotion-rules/*"
            element={
              <PromotionRules
                isModal={isModal}
                setIsModal={setIsModal}
                selectedMarketplace={selectedMarketplace}
                selectedApi={selectedApi}
                selectedCategory={selectedCategory}
                selectedBrand={selectedBrand}
              />
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export { PromotionsPage };
