import { useEffect, useState } from 'react';
import headers from '../../common/headers';
import { TableTop5 } from '../../components/TableTop5/TableTop5';
import { LineChart } from '../../charts/LineChart/LineChart';
import { useChartData } from '../../common/hooks';
import { DoughnutChart } from '../../charts/DoughnutChart/DoughnutChart';
import { BarCharts } from '../../charts/BarCharts/BarCharts';
import { CardMetricVersion2 } from '../../components/CardMetricVersion2/CardMetricVersion2';
import { KonstantinMasterHttps } from '../../fetchUrls';
import product from './img/product.png';
import ozon from './img/Ozon.png';
import { WrapperLineChart } from '../../charts/WrapperCharts/WrapperLineChart/WrapperLineChart';
import { WrapperDoughnutChart } from '../../charts/WrapperCharts/WrapperDoughnutChart/WrapperDoughnutChart';
import './AdvancedAnalytics.scss';

const data_1 = {
  header: ['Ключевая фраза', 'Кол-во упоминаний'],
  type: ['', 'dynamic'],
  data: [
    ['включение', [555555, 100]],
    ['зима', [12, -100]],
    ['нарекание', [55, -20]],
    ['плотно', [5555, -30]],
    ['хватают', [5, 40]],
  ],
};

const data_2 = {
  header: ['Товар', 'К-во отрицательных оценок', 'Магазин'],
  type: ['imgWithText', 'dynamic', 'brand'],
  data: [
    [
      [
        product,
        'Наушники беспроводные Honor Choice Earbuds X5 Pro-Eurasia BTV-ME10, серый',
      ],
      [100, -30],
      [ozon, 'Длинное название бренда'],
    ],
    [
      [
        product,
        'Наушники беспроводные Honor Choice Earbuds X5 Pro-Eurasia BTV-ME10, серый',
      ],
      [100, -30],
      [ozon, 'Длинное название бренда'],
    ],
    [
      [
        product,
        'Наушники беспроводные Honor Choice Earbuds X5 Pro-Eurasia BTV-ME10, серый',
      ],
      [100, -30],
      [ozon, 'Длинное название бренда'],
    ],
    [
      [
        product,
        'Наушники беспроводные Honor Choice Earbuds X5 Pro-Eurasia BTV-ME10, серый',
      ],
      [100, -30],
      [ozon, 'Длинное название бренда'],
    ],
    [
      [
        product,
        'Наушники беспроводные Honor Choice Earbuds X5 Pro-Eurasia BTV-ME10, серый',
      ],
      [100, -30],
      [ozon, 'Длинное название бренда'],
    ],
  ],
};

const AdvancedAnalytics = ({
  date,
  selectedBrand,
  selectedCategory,
  selectedApi,
  selectedMarketplace,
  pageValue,
  pageContent,
  allPeriod,
}) => {
  const [fivePositivePhrase, setFivePositivePhrase] = useState([]);
  const [fiveNegativePhrase, setFiveNegativePhrase] = useState([]);
  const [top5NegRating, setTop5NegRating] = useState([]);
  const [top5WithoutFeedbacks, setTop5WithoutFeedbacks] = useState([]);

  const body_obj = () => {

    let api_id_list;
    let mp_id_list;
    let category_id_list;
    const [date_from, date_to] = date;

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi?.map((el) => el.value);
    }

    if (selectedMarketplace?.length > 0) {
      mp_id_list = selectedMarketplace?.map((el) => el.value);
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    const body = JSON.stringify({
      mp_id_list,
      api_id_list,
      category_id_list,
      date_from: allPeriod
        ? new Date(0)
        : new Date(date_from + 10)?.toISOString().split('T')?.[0],
      date_to: new Date(date_to)?.toISOString().split('T')?.[0],
    });
    if(selectedApi?.length > 0) {
      return body
    } else {
      return null
    } 
  }

  useEffect(() => {
    if(selectedApi?.length > 0) {
      body_obj()
    } 
  }, [
    date,
    selectedBrand,
    selectedCategory,
    selectedApi,
    selectedMarketplace,
    allPeriod,
  ]);

  const fetchTopNegativePhrases = async () => {
    if (!selectedApi.length) {
      return;
    }
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/wb/feedbacks/analytics/tables/top-5-negative-phrases`;
    } else {
      url = `${KonstantinMasterHttps}/wb/feedbacks/analytics/tables/top-5-negative-phrases`;
    }

    let api_id_list;
    let mp_id_list;
    let category_id_list;
    let brand_id_list;
    const [date_from, date_to] = date;

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi?.map((el) => el.value);
    }

    if (selectedMarketplace?.length > 0) {
      mp_id_list = selectedMarketplace?.map((el) => el.value);
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }

    const body = JSON.stringify({
      mp_id_list,
      api_id_list,
      category_id_list,
      brand_id_list,
      date_from: allPeriod
        ? new Date(0)
        : new Date(date_from + 10)?.toISOString().split('T')?.[0],
      date_to: new Date(date_to)?.toISOString().split('T')?.[0],
    });

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body,
      });

      if (!response.ok) {
        const err = await response.json();
        throw new Error(JSON.stringify(err));
      }

      const json = await response.json();
      setFiveNegativePhrase(json);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchTopPositivePhrases = async () => {
    if (!selectedApi.length) {
      return;
    }
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/wb/feedbacks/analytics/tables/top-5-positive-phrases`;
    } else {
      url = `${KonstantinMasterHttps}/wb/feedbacks/analytics/tables/top-5-positive-phrases`;
    }

    let api_id_list;
    let mp_id_list;
    let category_id_list;
    let brand_id_list;
    const [date_from, date_to] = date;

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi?.map((el) => el.value);
    }

    if (selectedMarketplace?.length > 0) {
      mp_id_list = selectedMarketplace?.map((el) => el.value);
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }

    const body = JSON.stringify({
      mp_id_list,
      api_id_list,
      category_id_list,
      brand_id_list,
      date_from: allPeriod
        ? new Date(0)
        : new Date(date_from + 10)?.toISOString().split('T')?.[0],
      date_to: new Date(date_to)?.toISOString().split('T')?.[0],
    });

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body,
      });

      if (!response.ok) {
        const err = await response.json();
        throw new Error(JSON.stringify(err));
      }

      const json = await response.json();
      setFivePositivePhrase(json);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchAdvAnalyticsTop5NegRating = async () => {
    if (!selectedApi.length) {
      return;
    }
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/wb/feedbacks/analytics/tables/adv-analytics-top-5-neg-rating`;
    } else {
      url = `${KonstantinMasterHttps}/wb/feedbacks/analytics/tables/adv-analytics-top-5-neg-rating`;
    }

    let api_id_list;
    let mp_id_list;
    let category_id_list;
    let brand_id_list;
    const [date_from, date_to] = date;

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi?.map((el) => el.value);
    }

    if (selectedMarketplace?.length > 0) {
      mp_id_list = selectedMarketplace?.map((el) => el.value);
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }

    const body = JSON.stringify({
      filters: [],
      mp_id_list,
      api_id_list,
      category_id_list,
      brand_id_list,
      date_from: allPeriod
        ? new Date(0)
        : new Date(date_from + 10)?.toISOString().split('T')?.[0],
      date_to: new Date(date_to)?.toISOString().split('T')?.[0],
      page: 1,
      limit: 5,
    });

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body,
      });

      if (!response.ok) {
        const err = await response.json();
        throw new Error(JSON.stringify(err));
      }

      const json = await response.json();
      setTop5NegRating(json);
      console.log(json);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchAdvAnalyticsTop5WithoutFeedbacks = async () => {
    if (!selectedApi.length) {
      return;
    }
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/wb/feedbacks/analytics/tables/adv-analytics-top-5-without-feedbacks`;
    } else {
      url = `${KonstantinMasterHttps}/wb/feedbacks/analytics/tables/adv-analytics-top-5-without-feedbacks`;
    }

    let api_id_list;
    let mp_id_list;
    let category_id_list;
    let brand_id_list;
    const [date_from, date_to] = date;

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi?.map((el) => el.value);
    }

    if (selectedMarketplace?.length > 0) {
      mp_id_list = selectedMarketplace?.map((el) => el.value);
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }

    const body = JSON.stringify({
      filters: [],
      mp_id_list,
      api_id_list,
      category_id_list,
      brand_id_list,
      date_from: allPeriod
        ? new Date(0)
        : new Date(date_from + 10)?.toISOString().split('T')?.[0],
      date_to: new Date(date_to)?.toISOString().split('T')?.[0],
      page: 1,
      limit: 5,
    });

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body,
      });

      if (!response.ok) {
        const err = await response.json();
        throw new Error(JSON.stringify(err));
      }

      const json = await response.json();
      setTop5WithoutFeedbacks(json);
      console.log(json);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchTopNegativePhrases();
    fetchTopPositivePhrases();
    fetchAdvAnalyticsTop5NegRating();
    fetchAdvAnalyticsTop5WithoutFeedbacks();
  }, [
    date,
    selectedBrand,
    selectedCategory,
    selectedApi,
    selectedMarketplace,
    allPeriod,
  ]);

  return (
    <div className="advanced-analytics">
      <div className="advanced-analytics__table-chart-cards-group">
        <WrapperDoughnutChart
          url={`${KonstantinMasterHttps}/wb/${pageValue}/analytics/plots/grades-received`}
          body={body_obj()}
          nameChart="Полученые оценки"
        />

        <WrapperLineChart
          url={`${KonstantinMasterHttps}/wb/${pageValue}/analytics/plots/feedback-received`}
          body={body_obj()}
          nameChart="Полученые отзывы"
        />

        <div className="advanced-analytics__table-chart-cards-group_cards">
          <CardMetricVersion2
            title="Средний рейтинг"
            value={[4.5, 3.5, 100]}
            type={'rating'}
          />

          <CardMetricVersion2 title="Всего отзывов" value={[9300, 5321, 100]} />
        </div>
      </div>

      <div className="advanced-analytics__chart-group">
        <div className="advanced-analytics__chart-group_line">
          <WrapperLineChart
            url={`${KonstantinMasterHttps}/wb/${pageValue}/analytics/plots/reviews-per-day`}
            body={body_obj()}
            nameChart="Отзывов за день"
          />
        </div>
        <div className="advanced-analytics__chart-group_doughnut">

          <WrapperDoughnutChart
            url={`${KonstantinMasterHttps}/wb/${pageValue}/analytics/plots/reviews-on-marketplaces`}
            body={body_obj()}
            nameChart="Отзывы по маркетплейсам"
          />
        </div>
      </div>

      <div className="advanced-analytics__table-and-chart-group">

        <WrapperLineChart
          url={`${KonstantinMasterHttps}/wb/${pageValue}/analytics/plots/graph-sentiments`}
          body={body_obj()}
          nameChart="График положительных и отрицательных тональностей"
        />

        <div className="advanced-analytics__table-group">
          <TableTop5
            title="ТОП-5 положительных фраз"
            data={fivePositivePhrase}
          />
          <TableTop5
            title="ТОП-5 отрицательных фраз"
            data={fiveNegativePhrase}
          />
        </div>
      </div>

      <div className="advanced-analytics__table-group">
        <TableTop5
          title="ТОП-5 товаров, по которым были отрицательные оценки"
          data={top5NegRating}
        />
        <TableTop5
          title="ТОП-5 товаров, по которым долгое время не было отзывов"
          data={top5WithoutFeedbacks}
        />
      </div>
    </div>
  );
};

export { AdvancedAnalytics };
