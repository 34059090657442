import customHeaders, {getSpace} from '../../common/headers'
import ModalError from '../Modal/ModalError/ModalError'
import { BASE_ROUTE } from '../../App'
import { RavshanArticlesHttps } from '../../fetchUrls'
import { useParams, useNavigate } from 'react-router-dom'
import { handleApiResponseOrThrow } from '../../common/utils'
import { DropDownSelector, ArticleView, ARTICLE_TYPES, ListOfContent, Tabs, TableBox, ButtonBasic } from '@lk-gtcom/ecomlab-components'
import { useState, useEffect, useLayoutEffect, useCallback, useRef } from 'react'
import '../../common/styles/buttons.scss'
import useGeneralStore from '../../store/general'
import './Article.scss'

export const STATUSES = {
    active: 'active',
    update: 'update',
    redact: 'redact'
}

// 30 минут
const TIME_TO_INACTIVE_MS = 1000 * 60 * 30

const Article = ({ activeLang, isNew, pathBread, setCurrentArticle, setTags, tags, setPaths, setPathsWebName, setTitle, setDescription }) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const navigate = useNavigate()
    const params = useParams()
    const articleId = params['*'].split('/').at(-1)
    const [apiData, setApiData] = useState({})
    const [portals, setPortals] = useState([])
    const [portalLevels, setPortalLevels] = useState([])
    const [allTags, setAllTags] = useState([])
    const [loading, setLoading] = useState(false)
    const portalId = "articles-portal"
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [errorHeader, setErrorHeader] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [publishInProgress, setPublishInProgress] = useState(false)
    const [tagCreationInProgress, setTagCreationInProgress] = useState(false)
    const [contentChanged, setContentChanged] = useState(false)
    const publishButtonRef = useRef(null)
    const [contentLinks, setContentLinks] = useState([])

    const dataHasBeenFetched = Object.keys(apiData).length !== 0

    const [pageValue, setPageValue] = useState('article')

    // metadata
    const [author, setAuthor] = useState("")
    const [articleTitle, setArticleTitle] = useState("")
    const articleTitleRef = useRef()
    const [publicationDate, setPublicationDate] = useState(new Date())
    const [readingTime, setReadingTime] = useState(0)
    const [views, setViews] = useState(0)
    const [readonly, setReadonly] = useState(false)
    const [initialStatus, setInitialStatus] = useState("")
    const [statusText, setStatusText] = useState("")
    const [breadcrumbs, setBreadcrumbs] = useState({})
    const [seoTitle, setSeoTitle] = useState('')
    const [seoDescription, setSeoDescription] = useState('')

    const [tableData, setTableData] = useState([])
    const [tableHeaders, setTableHeaders] = useState([])
    const [columnSize, setColumnSize] = useState([])

    const [tableLoading, setTableLoading] = useState(false)

    const [successArticle, setSuccessArticle] = useState('')
    const [redactorUrl, setRedactorUrl] = useState('')

    const [selectedPortal, setSelectedPortal] = useState([])
    const [selectedTopLevel, setSelectedTopLevel] = useState({})
    const availableSecondLevels = portalLevels.find(l => l.value === selectedTopLevel.value)?.subItems
    const [selectedSecondLevel, setSelectedSecondLevel] = useState({})
    const portalLevelsSelectRef = useRef()
    const secondLevelSelectRef = useRef()

    const [userActive, setUserActive] = useState(true)
    const inactivityTimeoutRef = useRef()

    const [contentData, setContentData] = useState([])
    const setContentDataCallback = useCallback(e => setContentData(e), [])

    const setArrayResponseToFilterData = (data, set) => {
        const idIndex = data.label?.header.findIndex(h => h === "id")
        const nameIndex = data.label?.header.findIndex(h => h === "name")
        set(data.data?.map(el => ({ label: el[nameIndex], value: el[idIndex] })))
    }

    const onAddTagClick = (tag) => {
        setTagCreationInProgress(true)
        const body = JSON.stringify({ tag_name: tag })
        fetch(`${RavshanArticlesHttps}/post_tag`, { body, method: 'POST', headers })
            .then(res => handleApiResponseOrThrow(res))
            .then(id => {
                fetchAllTags()
                setTags(prev => {
                    prev.push({ label: tag, value: id })
                    return prev
                })
            })
            .catch(error => console.error(error))
            .finally(() => {
                setTagCreationInProgress(false)
            })
    }


    const fetchAllTags = () => {
        fetch(`${RavshanArticlesHttps}/get_all_tag`, { headers })
            .then(response => handleApiResponseOrThrow(response))
            .then(json => setArrayResponseToFilterData(json, setAllTags))
            .catch(error => console.error(error))
    }

    const fetchAllPortals = () => {

        const body = JSON.stringify({
            language: activeLang.toLowerCase()
        })

        fetch(`${RavshanArticlesHttps}/get_portals_by_lang`, { body, method: 'POST', headers })
            .then(response => handleApiResponseOrThrow(response))
            .then(json => {
                setPortals(json)
            })
            .catch(error => console.error(error))
    }

    const fetchPortalLevels = () => {
        if (!selectedPortal?.value) return

        const getHeaders = {
            ...headers,
            "portal-id": selectedPortal.value,
            "language": activeLang.toLowerCase()
        }
        fetch(`${RavshanArticlesHttps}/get_portals_levels`, { headers: getHeaders })
            .then(response => handleApiResponseOrThrow(response))
            .then(json => setPortalLevels(json))
            .catch(error => console.error(error))
    }

    const fetchTableData = (params) => {

        setTableLoading(true)

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanArticlesHttps}/get_seo_analysis`
        } else {
            url = `${RavshanArticlesHttps}/get_seo_analysis`
        }


        const body = JSON.stringify({
            article_id: articleId,
            metric: "common"
        })


        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => {
                const { data, labels, total, sort, column_size } = json
                // if (total) paginatorRef.current?.setTotal(total)
                if (data) setTableData(data)
                if (labels) setTableHeaders(labels)
                if (column_size) setColumnSize(column_size)
                // if (sort) setSort(sort)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => setTableLoading(false))
    }



    const fetchDataById = () => {
        setLoading(true)
        const url = `${RavshanArticlesHttps}/get_article`
        const getHeaders = {
            ...headers,
            'article-id': articleId,
            'Accept': 'application/json',
            'active': true,
            'redactor': true
        }
        fetch(url, { headers: getHeaders })
            .then(async response => {
                if (response.ok) {
                    return handleApiResponseOrThrow(response)
                } else {
                    const err = await response.json()
                    throw new Error(err)
                }
            })
            .then(json => {
                if (Object.keys(json).length === 0) {
                    // navigate('main/' + `${pathBread}/${BASE_ROUTE}`)
                }
                setApiData(json)
                setCurrentArticle({ title: json.name, breadcrumbs: json.breadCrumbs })
                setBreadcrumbs(json.breadCrumbs)
            })
            .catch(error => console.error(error))
            .finally(() => setLoading(false))
    }

    const sendStatus = status => {
        const url = `${RavshanArticlesHttps}/post_status`
        const body = JSON.stringify({
            id: articleId,
            status
        })
        // не ждём ответ чтобы не блокировать поток
        fetch(url, { headers, method: 'POST', body, keepalive: true })
        if (status === STATUSES.redact) setStatusText('Статья редактируется')
        if (status === STATUSES.active) setStatusText('')
    }

    const handleStatus = status => {
        setInitialStatus(status)
        if (status === STATUSES.active) return
        if (status === STATUSES.redact) {
            setStatusText('Статья редактируется')
            return
        }
        if (status === STATUSES.update) {
            setStatusText('Статья обновляется')
            setReadonly(true)
        }
    }

    const mapContentToApiRequest = content => {
        const mappedContentData = []
        let imageCounter = 0
        const arrImg = [...document.querySelectorAll('.img-container__img')]
        const arrSrc = arrImg.map((el) => el?.src)

        let countAttr = 0
        const arrAttr = [...document.querySelectorAll('[data-align]')]
        const arrValue = arrAttr.map((el) => el?.getAttribute('data-align'))

        let alignValue = (align) => {
            if (!align) return 'center'
            if (align == 'align-right') {
                return 'right'
            } else return 'left'
        }

        content.forEach((element, index) => {
            const { type, id, data } = element
            const place = index + 1

            switch (type) {
                case ARTICLE_TYPES.header: {
                    mappedContentData.push({ id, place, type, value: data, preview: data, mobile: data, align: alignValue(arrValue[countAttr]) })
                    countAttr++
                    break
                }
                case ARTICLE_TYPES.description: {
                    const data = element.data
                    mappedContentData.push({ id, place, type, value: data, align: alignValue(arrValue[countAttr]) })
                    countAttr++
                    break
                }
                case ARTICLE_TYPES.image: {
                    mappedContentData.push({ id, place, type, value: data?.value, alt: data?.alt })
                    imageCounter++
                    break
                }
                case ARTICLE_TYPES.formBtn: {
                    mappedContentData.push({ id, place, type, value: data })
                    break
                }
                case ARTICLE_TYPES.warningBlock: {
                    mappedContentData.push({ id, place, type, value: data, align: alignValue(arrValue[countAttr]) })
                    countAttr++
                    break
                }
                default:
                    throw new Error('Unknown article element type')
            }
        })
        return mappedContentData
    }

    const publish = () => {
        setPublishInProgress(true)

        const content = mapContentToApiRequest(contentData)
        const url = isNew ? `${RavshanArticlesHttps}/api/test/post_article` : `${RavshanArticlesHttps}/api/test/put_article`
        const body = JSON.stringify({
            article_id: isNew ? undefined : articleId,
            date: publicationDate,
            tag_ids: tags?.map(t => t.value),
            name: articleTitle,
            short_name: articleTitle,
            reading_time_min: readingTime,
            content,
            active: true,
            portal_id: isNew ? selectedPortal?.value : undefined,
            parent_id: isNew ? (selectedSecondLevel ? selectedSecondLevel.value : selectedTopLevel.value) : undefined,
            language: activeLang,
            title: seoTitle,
            description: seoDescription
        })


        const requestHeaders = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Cache-control': 'no-cache',
            'Authorization': headers.Authorization
        }

        fetch(url, { body, method: isNew ? 'POST' : 'PUT', headers: requestHeaders })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const json = await res.json()
                    throw new Error(json)
                }
            })
            .then((json) => {
                const { portalUrl, redactorUrl } = json
                if (isNew) {
                    navigate(redactorUrl, { replace: true })
                }
                setSuccessArticle(portalUrl)
                setRedactorUrl(redactorUrl)
                setErrorHeader(`Статья успешно ${isNew ? 'создана' : 'отредактирована'}`)
                setShowErrorModal(true)
            })
            .catch(error => {
                console.log(error);
                if (error) {
                    setErrorHeader('Произошла ошибка во время сохранения статьи')
                    setErrorMessage(error.reason)
                    setShowErrorModal(true)
                    console.error(error.reason)
                }
            })
            .finally(() => {
                setPublishInProgress(false)
            })
    }

    useEffect(() => {
        const onUnload = () => sendStatus(STATUSES.active)

        const goInactive = () => {
            sendStatus(STATUSES.active)
            setUserActive(false)
        }

        const startTimer = () => {
            inactivityTimeoutRef.current = setTimeout(goInactive, TIME_TO_INACTIVE_MS)
        }

        const resetTimer = () => {
            clearTimeout(inactivityTimeoutRef.current)
            setUserActive(true)
            startTimer()
        }

        // для отправки статуса по закрытию вкладки
        window.addEventListener('beforeunload', onUnload)

        // взаимодействие со страницей
        document.addEventListener("mousemove", resetTimer, false);
        document.addEventListener("mousedown", resetTimer, false);
        document.addEventListener("keypress", resetTimer, false);
        document.addEventListener("DOMMouseScroll", resetTimer, false);
        document.addEventListener("mousewheel", resetTimer, false);
        document.addEventListener("touchmove", resetTimer, { passive: true });
        document.addEventListener("MSPointerMove", resetTimer, false);

        // если пользователь перешёл на другую вкладку
        window.addEventListener("blur", startTimer, false);
        window.addEventListener("focus", resetTimer, false);

        startTimer()

        return () => {
            window.removeEventListener('beforeunload', onUnload)

            document.removeEventListener("mousemove", resetTimer, false);
            document.removeEventListener("mousedown", resetTimer, false);
            document.removeEventListener("keypress", resetTimer, false);
            document.removeEventListener("DOMMouseScroll", resetTimer, false);
            document.removeEventListener("mousewheel", resetTimer, false);
            document.removeEventListener("touchmove", resetTimer, { passive: true });
            document.removeEventListener("MSPointerMove", resetTimer, false);

            window.removeEventListener("blur", startTimer, false);
            window.removeEventListener("focus", resetTimer, false);
        }
    }, [])

    useEffect(() => {
        return () => {
            setCurrentArticle({})
        }
    }, [])

    useEffect(() => {
        if (pageValue === 'seo') {
            fetchTableData()
        }
    }, [pageValue, selectedSpace]);


    useEffect(() => {
        if (contentChanged && userActive && dataHasBeenFetched && !isNew) sendStatus(STATUSES.redact)
    }, [userActive, contentChanged])

    useEffect(() => {
        return () => {
            // сбросить статус может только тот, кто изначально перевёл его из active в redact
            if (initialStatus === STATUSES.active) sendStatus(STATUSES.active)
        }
    }, [initialStatus])

    useLayoutEffect(() => {
        if (articleTitleRef.current) {
            const maxNumberOfCharacters = 29
            const add = Math.floor(articleTitle.length / maxNumberOfCharacters)
            const numberOfLineBreaks = (articleTitle.match(/\n/g) || []).length + add;
            // min-height + lines x line-height + padding
            const newHeight = 1 + numberOfLineBreaks * 1 + 0.5
            articleTitleRef.current.style.height = newHeight + 'em'
        }
    }, [articleTitle])

    useEffect(() => {
        if (portalLevelsSelectRef.current) {
            portalLevelsSelectRef.current.clearValue()
        }
        fetchPortalLevels()
    }, [selectedPortal])

    useEffect(() => {
        if (secondLevelSelectRef.current) {
            secondLevelSelectRef.current.clearValue()
        }
    }, [selectedTopLevel])

    // эффект для фетчинга контента статьи
    useEffect(() => {
        if (isNew) {
            setCurrentArticle({})
            fetchAllPortals()
        } else {
            fetchDataById()
        }
        fetchAllTags()
    }, [isNew])

    useEffect(() => {
        if (!apiData) { return }
        const { author, date, name, reading_time_min, friendlyURL, tags, views, status, title, description, breadCrumbs, preview } = apiData
        console.log(apiData)
        if (author) setAuthor(author)
        if (date) setPublicationDate(new Date(date))
        if (name) setArticleTitle(name)
        if (reading_time_min) setReadingTime(reading_time_min)
        if (tags) setTags(tags.map(t => ({ label: t.name, value: t.id })))
        if (views) setViews(views)
        // if (friendlyURL) navigate('/wiki' + friendlyURL, { replace: true })
        if (friendlyURL) navigate(preview[0]?.portal_id == 6 ? '/wiki' + '/ru' + friendlyURL : '/wiki' + friendlyURL, { replace: true })
        if (status) handleStatus(status)
        if (title) {
            setTitle(title)
            setSeoTitle(title)

        }
        if (description) {
            setDescription(description)
            setSeoDescription(description)
        }
        if (breadCrumbs?.length > 0) {
            setPaths(breadCrumbs?.map(el => el?.label))
            setPathsWebName(breadCrumbs?.map(el => el?.web_name))
        }
    }, [apiData])

    useEffect(() => {
        if (isNew) {
            setAuthor("")
            setPublicationDate(new Date())
            setArticleTitle("")
            setReadingTime(0)
            setTags([])
            setViews(0)
            setContentData([])
            setTitle('New article')
            setDescription('Create new article')
        }
    }, [isNew])


    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Tabs
                        tabs={[{ label: 'Статья', value: 'article' }, { label: 'Проверка SEO', value: 'seo' }, { label: 'Ключевики', value: 'keyword' }, { label: 'Реклама', value: 'adv' }]}
                        stopRedirect
                        pageValue={pageValue}
                        setPageValue={e => setPageValue(e)}
                    />
                    <ButtonBasic
                        width='180px'
                        whiteGreen
                        text='Создать из файла'
                    />
                </div>
                <div className='action-panel-top'>
                    <span className='text status'>{statusText}</span>
                    <button onClick={() => navigate(-1)}
                        style={{ minHeight: '40px' }}
                        className='btn-basic white-green-btn'>Отменить изменения</button>
                    <button ref={publishButtonRef} onClick={publish} disabled={publishInProgress || readonly} className='btn-publish btn__green'>Опубликовать</button>
                </div>
            </div>

            {/* Статья */}
            {
                pageValue === 'article' &&
                <div id={portalId} className='article-template article-template__content'>
                    {showErrorModal && <ModalError headerText={errorHeader} messageText={errorMessage} setShowModal={setShowErrorModal} redactorUrl={redactorUrl} articleLink={successArticle} />}
                    <div className='article-template__content-article'>

                        {
                            isNew
                            &&
                            <div className='toolbar-filter'>
                                <div className='select-group'>
                                    <DropDownSelector
                                        options_prop={portals}
                                        state={selectedPortal}
                                        setState={setSelectedPortal}
                                        placeholder='Выбрать портал'
                                    />
                                    <DropDownSelector
                                        ref={portalLevelsSelectRef}
                                        options_prop={portalLevels}
                                        state={selectedTopLevel}
                                        setState={setSelectedTopLevel}
                                        placeholder='Выбрать раздел'
                                    />
                                    <DropDownSelector
                                        ref={secondLevelSelectRef}
                                        state={selectedSecondLevel}
                                        options_prop={availableSecondLevels}
                                        setState={setSelectedSecondLevel}
                                        placeholder='Выбрать подраздел'
                                    />
                                </div>
                            </div>
                        }

                        <ArticleView
                            isNew={isNew}
                            articleTitle={articleTitle}
                            articleTitleRef={articleTitleRef}
                            setArticleTitle={(e) => setArticleTitle(e)}
                            setContentChanged={(e) => setContentChanged(e)}
                            readonly={readonly}
                            loading={loading}
                            apiData={apiData}
                            contentData={contentData}
                            setContentData={setContentDataCallback}
                            setListOfContent={setContentLinks}
                            portalId={portalId}
                            publishEventSource={publishButtonRef.current}
                            setChanged={setContentChanged}
                            title={seoTitle}
                            setTitle={e => setSeoTitle(e)}
                            description={seoDescription}
                            setDescription={e => setSeoDescription(e)}
                            contentLinks={contentLinks}
                            pathBread={pathBread}
                            wiki={true}
                        />
                    </div>
                    <ListOfContent
                        links={contentLinks}
                        readingTime={readingTime}
                        tags={allTags}
                        selectedTags={tags}
                        onAddTagClick={onAddTagClick}
                        tagsSelectorDisabled={tagCreationInProgress}
                        setSelectedTags={(e) => {
                            if (Object.keys(apiData).length) setContentChanged(true)
                            setTags(e)
                        }}
                        views={views}
                        publicationDate={publicationDate}
                        author={author}
                    />
                </div>
            }
            {/* Проверка SEO */}
            {
                pageValue === 'seo' &&
                <div id={portalId} className='article-template article-template__content'>
                    <div className='article-template__content-article'>
                        <div
                            className='container'
                            style={{
                                background: '#fff', borderRadius: 12, padding: '1em', display: 'flex', flexDirection: 'column',
                                gap: '1em'
                            }}
                        >
                            <h2>Статистика текста</h2>
                            <TableBox
                                loading={tableLoading}
                                fetchedData={tableData}
                                headers={tableHeaders}
                                hideHeader
                            />
                        </div>
                    </div>
                </div>
            }
        </>

    )
}

export { Article }