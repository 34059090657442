import { useDataTable } from '../../common/hooks';
import { FilterContainer } from '../../components/FilterContainer/FilterContainer';
import { useState, useEffect, useCallback, useRef } from 'react';
import { BtnDropCheckbox } from '../../components/BtnDropCheckbox/BtnDropCheckbox';
import { useParams } from 'react-router-dom';
import {
  DropDownSelector,
  Tabs,
  TableBox,
  Checkbox,
  ButtonBasic,
  RangeBox,
} from '@lk-gtcom/ecomlab-components';
import './CheckingOVH.scss';
import useGeneralStore from '../../store/general';
import customHeaders, { getSpace } from '../../common/headers';
import {
  fetchApiFilter,
  fetchBrandFilter,
  fetchCampaignFilter,
  fetchCategoryFilter,
  fetchMPOfferIdFilter,
  fetchWarehouseFilter,
} from '../../common/filtersFunc';
import { OlegHttp, OlegHttps } from '../../fetchUrls';
import { useDebounce } from '../../hooks/useDebounce';

const list_select = [
  {
    label: 'Площадка',
  },
  {
    label: 'Магазин',
  },
  {
    label: 'Склад',
  },
  {
    label: 'Бренд',
  },
  {
    label: 'Категория',
  },
  {
    label: 'Кампания',
  },
  {
    label: 'OfferId',
  },
];

const btn_tab = [
  {
    label: 'Все товары',
    value: 'all_table',
  },
  {
    label: 'Товары с отклонением по периметру > 100%',
    value: 'perimetr',
  },
  {
    label:
      'Один из параметров по отклонению габарита меньше -15%  или больше 5%',
    value: 'deviation',
  },
  {
    label: 'Отклонение объемного веса от среднего значения объемного веса',
    value: 'volumetric',
  },
];

const options_prop = [
  {
    label: 'Ozon',
    value: { name: 'ozon', id: 1 },
  },
  {
    label: 'Wildberries',
    value: { name: 'wb', id: 3 },
  },
  {
    label: 'Yandex',
    value: { name: 'yandex', id: 2 },
  },
];

const CheckingOVH = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [pageValue, setPageValue] = useState('all_table');

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);

  const [dateFrom, dateTo] = date;
  const activeTab = useParams()['*'];
  const [checkboxValues, setCheckboxValues] = useState([
    'Площадка',
    'Категория',
    'Склад',
    'Бренд',
    'Магазин',
    'Кампания',
    'OfferId',
  ]);
  const [apiData, setApiData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [campaignData, setCampaignData] = useState([]);

  const [selectedMarketplace, setSelectedMarketplace] = useState([
    options_prop[0],
  ]);

  const [apiErr, setApiErr] = useState(false);

  const [selectedApi, setSelectedApi] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [selectedOfferId, setSelectedOfferId] = useState([]);

  const [searchValue, setSearchValue] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [checkedProducts, setCheckedProducts] = useState([]);

  const [warehouseData, setWarehouseData] = useState([]);
  const [offerIdData, setOfferIdData] = useState([]);

  const [apiPage, setApiPage] = useState(1);
  const [productPage, setProductPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);
  const [warehousePage, setWarehousePage] = useState(1);
  const [campaignPage, setCampaignPage] = useState(1);
  const [offerIdPage, setOfferIdPage] = useState(1);

  const [apiQuery, setApiQuery] = useState('');
  const [productQuery, setProductQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');
  const [warehouseQuery, setWarehouseQuery] = useState('');
  const [campaignQuery, setCampaignQuery] = useState('');
  const [offerIdQuery, setOfferIdQuery] = useState('');

  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);
  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );

  const [sort, setSort] = useState(null);

  // const { loading, sort, filters } = tableProps;
  const { setLoading, setFetchedData, setHeaders, setFilters } = setTableFuncs;
  const [checkZero, setCheckZero] = useState(false);

  const fetchTableData = (params) => {
    let sort, filters;
    if (params) {
      [sort, filters] = params;
    }

    setLoading(true);

    const url = !window.location.hostname.match('localhost')
      ? `${OlegHttps}/api/v1/ovh/checking/table/${pageValue}${
          sort?.length > 0
            ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
            : ''
        }`
      : `${OlegHttp}/api/v1/ovh/checking/table/${pageValue}${
          sort?.length > 0
            ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
            : ''
        }`;

    const api_id_list =
      selectedApi?.length > 0
        ? selectedApi.map((el) => String(el.value))
        : [''];

    const category_id_list =
      selectedCategory?.length > 0
        ? selectedCategory.map((el) => el.value)
        : ['all'];

    const brand_id_list =
      selectedBrand?.length > 0 ? selectedBrand.map((el) => el.value) : ['all'];

    const warehouse_id_list =
      selectedWarehouse?.length > 0
        ? selectedWarehouse.map((el) => el.value)
        : ['all'];

    const campaign_id_list =
      selectedCampaign?.length > 0
        ? selectedCampaign.map((el) => el.value)
        : ['all'];

    const offer_id_list =
      selectedOfferId?.length > 0
        ? selectedOfferId.map((el) => el.value)
        : ['all'];

    const body = JSON.stringify({
      page: paginatorRef.current?.page,
      limit: paginatorRef.current?.limit,
      category_id_list: category_id_list,
      brand_id_list: brand_id_list,
      api_id_list: api_id_list,
      warehouse_id_list: warehouse_id_list,
      campaign_id_list: campaign_id_list,
      offer_id_list: offer_id_list,
      with_stock_filter: {},
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json, 'json');
        const { sort } = json;
        setTable(json);
        setSort(sort);
        setTableUrl(url);
        setTableBody(body);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchApiFilter({
      selectedSpace: selectedSpace?.value,
      page: apiPage,
      setApiData: (e) => setApiData(e),
      searchParam: apiQuery,
      type: selectedMarketplace?.value,
      selectedShop: [
        {
          label: selectedMarketplace?.value?.name,
          value: selectedMarketplace?.value?.id,
        },
      ],
    });
  }, [selectedSpace, apiPage, apiQuery, selectedMarketplace]);

  useEffect(() => {
    fetchCategoryFilter({
      selectedSpace: selectedSpace?.value,
      page: categoryPage,
      apiData,
      dateFrom,
      dateTo,
      selectedApi,
      setCategoryData: (e) => setCategoryData(e),
      searchParam: categoryQuery,
    });
  }, [
    selectedSpace,
    apiData,
    dateFrom,
    dateTo,
    selectedApi,
    categoryPage,
    categoryQuery,
  ]);

  useEffect(() => {
    fetchBrandFilter({
      selectedSpace: selectedSpace?.value,
      page: brandPage,
      apiData,
      categoryData,
      selectedCategory,
      dateFrom,
      dateTo,
      searchParam: brandQuery,
      selectedApi,
      setBrandData: (e) => setBrandData(e),
    });
  }, [selectedSpace, categoryData, selectedCategory, brandPage, brandQuery]);

  useEffect(() => {
    fetchCampaignFilter({
      selectedSpace: selectedSpace?.value,
      page: campaignPage,
      apiData,
      categoryData,
      selectedApi,
      selectedCategory,
      selectedBrand,
      selectedProduct,
      selectedShop: selectedMarketplace,
      selectedCampaign,
      brandData,
      dateFrom,
      dateTo,
      searchParam: campaignQuery,
      setCampaignData: (e) => setCampaignData(e),
    });
  }, [
    brandData,
    selectedApi,
    selectedCategory,
    selectedBrand,
    campaignPage,
    campaignQuery,
    selectedCampaign,
    selectedWarehouse,
    selectedSpace,
  ]);

  useEffect(() => {
    fetchWarehouseFilter({
      selectedSpace: selectedSpace?.value,
      page: warehousePage,
      apiData,
      categoryData,
      selectedApi,
      selectedCategory,
      selectedBrand,
      selectedProduct,
      selectedShop: selectedMarketplace,
      selectedCampaign,
      brandData,
      dateFrom,
      dateTo,
      searchParam: warehouseQuery,
      setWarehouseData: (e) => setWarehouseData(e),
    });
  }, [
    brandData,
    selectedApi,
    selectedCategory,
    selectedBrand,
    selectedCampaign,
    warehousePage,
    warehouseQuery,
    selectedWarehouse,
    selectedSpace,
  ]);

  useEffect(() => {
    fetchMPOfferIdFilter({
      selectedSpace: selectedSpace?.value,
      page: offerIdPage,
      apiData,
      categoryData,
      selectedCategory,
      brandData,
      selectedBrand,
      dateFrom,
      dateTo,
      searchParam: offerIdQuery,
      selectedApi,
      setOfferIdData: (e) => setOfferIdData(e),
    });
  }, [
    selectedSpace,
    brandData,
    selectedApi,
    selectedCategory,
    selectedBrand,
    offerIdPage,
    offerIdQuery,
  ]);

  useEffect(() => {
    setPageValue(activeTab);
  }, [activeTab]);

  const debouncedFetchTableData = useDebounce(fetchTableData, 2000);

  useEffect(() => {
    debouncedFetchTableData();
  }, [
    pageValue,
    selectedApi,
    selectedCategory,
    selectedBrand,
    selectedWarehouse,
    selectedCampaign,
    selectedOfferId,
    checkZero,
  ]);

  return (
    <>
      <div className="checking-OVH">
        <h1 className="title_main">Проверка ОВХ</h1>

        <div className="checking-OVH__toolbar-top">
          <FilterContainer
            onReset={(e) => {
              setSelectedProduct([]);
              setSelectedCategory([]);
              setSelectedBrand([]);
              setSelectedApi([]);
              setSelectedCampaign([]);
              setSelectedWarehouse([]);
              setSelectedOfferId([]);
            }}
          >
            {checkboxValues.includes('Площадка') && (
              <DropDownSelector
                options_prop={options_prop}
                state={selectedMarketplace}
                setState={(e) => {
                  setSelectedMarketplace(e);
                }}
                className="connections_page_selector"
                placeholder="Площадка"
                defaultValue={options_prop[0]}
              />
            )}
            {checkboxValues.includes('Магазин') && (
              <DropDownSelector
                err={apiErr}
                state={selectedApi}
                options_prop={apiData}
                setState={(e) => setSelectedApi(e)}
                className="connections_page_selector"
                placeholder="Магазин"
                fetchCallback={(e) => setApiPage(e)}
                setInputChange={(e) => setApiQuery(e)}
                multi
              />
            )}
            {checkboxValues.includes('Склад') && (
              <DropDownSelector
                options_prop={warehouseData}
                state={selectedWarehouse}
                setState={(e) => setSelectedWarehouse(e)}
                placeholder="Склад"
                fetchCallback={(e) => setWarehousePage(e)}
                setInputChange={(e) => setWarehouseQuery(e)}
                defaultValue={[{ label: 'Все', value: 'all' }]}
                multi
              />
            )}
            {checkboxValues.includes('Категория') && (
              <DropDownSelector
                options_prop={categoryData}
                state={selectedCategory}
                setState={(e) => setSelectedCategory(e)}
                placeholder="Категория"
                fetchCallback={(e) => setCategoryPage(e)}
                setInputChange={(e) => setCategoryQuery(e)}
                defaultValue={[{ label: 'Все', value: 'all' }]}
                multi
              />
            )}

            {checkboxValues.includes('Бренд') && (
              <DropDownSelector
                options_prop={brandData}
                state={selectedBrand}
                setState={(e) => setSelectedBrand(e)}
                placeholder="Бренд"
                fetchCallback={(e) => setBrandPage(e)}
                setInputChange={(e) => setBrandQuery(e)}
                defaultValue={[{ label: 'Все', value: 'all' }]}
                multi
              />
            )}

            {checkboxValues.includes('Кампания') && (
              <DropDownSelector
                options_prop={campaignData}
                state={selectedCampaign}
                setState={(e) => setSelectedCampaign(e)}
                className="connections_page_selector"
                placeholder="Кампания"
                fetchCallback={(e) => setCampaignPage(e)}
                setInputChange={(e) => setCampaignQuery(e)}
                defaultValue={[{ label: 'Все', value: 'all' }]}
                multi
              />
            )}
            {checkboxValues.includes('OfferId') && (
              <DropDownSelector
                options_prop={offerIdData}
                state={selectedOfferId}
                setState={(e) => setSelectedOfferId(e)}
                placeholder="OfferId"
                multi={true}
                fetchCallback={(e) => setOfferIdPage(e)}
                setInputChange={(e) => setOfferIdQuery(e)}
                defaultValue={[{ label: 'Все', value: 'all' }]}
              />
            )}

            <Checkbox value={checkZero} onChange={(e) => setCheckZero(e)}>
              Без нулевого остатка
            </Checkbox>
            <BtnDropCheckbox
              checkboxValues={checkboxValues}
              setCheckboxValues={(e) => setCheckboxValues(e)}
              list={list_select}
            />
          </FilterContainer>

          <div className="checking-OVH__toolbar-top_right">
            {/* <BtnDropCheckbox
              checkboxValues={checkboxValues}
              setCheckboxValues={(e) => setCheckboxValues(e)}
              list={list_select}
            /> */}
            {/* <RangeBox setDate={(e) => setDate(e)} hideBtnGroup /> */}
          </div>
        </div>

        <div className="toolbar-medium">
          <Tabs tabs={btn_tab} pageValue={pageValue} />
        </div>

        <TableBox
          sort={sort}
          {...tableProps}
          paginator={true}
          ref={paginatorRef}
          onCheck={(e) => setCheckedProducts(e)}
          fetchCallback={(e) => fetchTableData(e)}
          sortingFunc={(e) => {
            fetchTableData(e);
          }}
          // tableUrl={tableUrl}
          tableUrl={`/ovh/extract/to_excel/table/${pageValue}`}
          tableBody={tableBody}
          child={
            <>
              <ButtonBasic green text="action1" size="32px" width="auto" />

              <ButtonBasic grey text="action2" size="32px" width="auto" />
            </>
          }
        />
      </div>
    </>
  );
};

export { CheckingOVH };
