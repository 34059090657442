import { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { TableBox, Tabs } from "@lk-gtcom/ecomlab-components"
import "./CrossAnalyticscGeneralIndicators.scss"


const CrossAnalyticscGeneralIndicators = ({
    tableData,
    tableCallback,
    paginatorRef,
    pageValue
}) => {

    return (
        <div className='cross-analyticsc-general-indicators'>
            <TableBox
                {...tableData}
                ref={paginatorRef}
                paginator={pageValue == 'compare_period' ? false : true }
                fetchCallback={tableCallback}
                sortingFunc={tableCallback}
            />
        </div>
    )
}

export { CrossAnalyticscGeneralIndicators }