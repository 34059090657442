import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ButtonBasic, InputDinamycPlaceholder } from "@lk-gtcom/ecomlab-components";
import './ModalNewNameScenario.scss'

const ModalNewNameScenario = ({
    setIsModal,
    setValueName,
    valuename,
    isEdit,
    createRuleCategory,
    changeRuleCat,
    fetchRuleNew,
    setIsCreateCategory,
    setIsEditId,
    isEditId
}) => {

    return (
        <div className='blackout'
            // style={{ zIndex: 11 }}
            onClick={(e) => {
                let el = e.target
                if (el.classList.contains('blackout')) {
                    setIsCreateCategory(false)
                    setIsEditId(null)
                    setIsModal(false)
                }
            }}>
            <div className='modal-new-name-scenario'>
                <h2 className='title'>{!isEditId ? 'Создание категории' : 'Редактирование категории'}</h2>
                <InputDinamycPlaceholder
                    changeValue={valuename ? valuename : ''}
                    onChange={(e) => setValueName(e)}
                    placeholder='Введите название правила'
                />
                < ButtonBasic
                    green
                    width='100%'
                    text='Сохранить правило'
                    onClick={(e) => {
                        if(isEditId) {
                            changeRuleCat(e)
                            setTimeout(() => fetchRuleNew(), 500)
                            setIsCreateCategory(false)
                        } else {
                            createRuleCategory()
                        }
                        setIsEditId(null)
                        setIsModal(false)
                    }}
                />
            </div>
        </div>
    )
}

export { ModalNewNameScenario }