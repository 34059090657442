import React, { useState, useEffect } from 'react'
import close from './img/close.svg'
import file_icon from './img/bx-file-blank.svg'
import download_icon from './img/bx-download.svg'
import dayjs from 'dayjs'
import './FileItemComponent.scss'

const FileItemComponent = ({
    created_at,
    file_size_kb,
    new_filename,
    url,
    authorEmail,
    filesdata,
    setFilesData,
    isEdit
}) => {

    const email = localStorage.getItem('email')

    return (
        <div className='file-item-component' key={created_at + url}>
            <p className='file-item-component__link-file'>
                <img className='file-item-component__link-file-img' src={file_icon} />
                <span>{new_filename}</span>

                {((email == authorEmail) && isEdit) && <img className='file-item-component__icon'
                    onClick={(e) => {
                        e.stopPropagation()
                        setFilesData(filesdata?.filter((el) => el.new_filename !== new_filename))
                    }}
                    src={close} />}
            </p>

            <a className='file-item-component__link-btn'
                target='_blank'
                href={url}
                download={'download'}
                onClick={(e) => e.stopPropagation()}
            >
                <img className='file-item-component__link-file-img'  src={download_icon} /> Скачать файл
            </a>
            <div className='file-item-component__link-info'>
                <span>{(file_size_kb/1024).toFixed(2)} МБ</span>
                <span>{dayjs(created_at).format('DD.MM.YY HH:mm')}</span>
            </div>
        </div>
    )
}

export { FileItemComponent }