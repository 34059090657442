// import React, { useState, useEffect, useRef } from 'react';
// import './ModalMiltilevelSidebarSettings.scss';
// import customHeaders, { getSpace } from '../../../common/headers';
// import { RavshanHttps } from '../../../fetchUrls';
// import useGeneralStore from '../../../store/general';

// const initialMenu = [];

// const MenuItem = ({
//   item,
//   onDragStart,
//   onDragOver,
//   onDrop,
//   onDropBetween,
//   toggleExpand,
//   handleAddChild,
//   handleSelect,
//   handleDelete,
//   selectedItemId,
//   fetchChildren,
// }) => {
//   const [isHovered, setIsHovered] = useState(false);
//   const [isHoveredForDrop, setIsHoveredForDrop] = useState(false);
//   console.log(isHoveredForDrop);

//   return (
//     <>
//       <div
//         onDragOver={(e) => onDragOver(e)}
//         onDrop={(e) => onDropBetween(e, item.id, 'above')}
//       />
//       <div
//         className={`menu-item ${isHoveredForDrop ? 'hovered-for-drop' : ''}`}
//         draggable
//         onDragStart={(e) => {
//           onDragStart(e, item.id);
//         }}
//         onDragOver={(e) => {
//           onDragOver(e);
//           e.dataTransfer.dropEffect = 'move';
//           setIsHoveredForDrop(true);
//         }}
//         onDrop={(e) => {
//           onDrop(e, item.id);
//           setIsHoveredForDrop(false);
//         }}
//         onDragLeave={() => setIsHoveredForDrop(false)}
//         onClick={(e) => {
//           e.stopPropagation();
//           handleSelect(item.id);
//         }}
//       >
//         <div
//           className="item-label"
//           style={{
//             backgroundColor: selectedItemId === item.id ? '#e6f7ff' : '',
//           }}
//         >
//           {(item.has_child || item.children.length > 0) && (
//             <span
//               className={`arrow ${item.expanded ? 'open' : ''}`}
//               onClick={(e) => {
//                 e.stopPropagation();
//                 if (item.expanded) {
//                   toggleExpand(item.id);
//                 } else {
//                   fetchChildren(item.id);
//                 }
//               }}
//             >
//               ▶
//             </span>
//           )}
//           {item.label}
//           <button
//             className="add-child-btn"
//             onClick={(e) => {
//               e.stopPropagation();
//               handleAddChild(item.id);
//             }}
//           >
//             +
//           </button>
//           <button
//             className="delete-btn"
//             onClick={(e) => {
//               e.stopPropagation();
//               handleDelete(item.id);
//             }}
//           >
//             🗑️
//           </button>
//         </div>
//         {item.expanded && item.children.length > 0 && (
//           <div className="children">
//             {item.children.map((child) => (
//               <MenuItem
//                 key={child.id}
//                 item={child}
//                 onDragStart={onDragStart}
//                 onDragOver={onDragOver}
//                 onDrop={onDrop}
//                 onDropBetween={onDropBetween}
//                 toggleExpand={toggleExpand}
//                 handleAddChild={handleAddChild}
//                 handleDelete={handleDelete}
//                 handleSelect={handleSelect}
//                 selectedItemId={selectedItemId}
//                 fetchChildren={fetchChildren}
//               />
//             ))}
//           </div>
//         )}
//       </div>
//       <div
//         style={{
//           height: '5px',
//           backgroundColor: isHovered ? '#5282ff' : 'transparent',
//         }}
//         onDragEnter={() => setIsHovered(true)}
//         onDragLeave={() => setIsHovered(false)}
//         onDragOver={(e) => {
//           onDragOver(e);
//           e.dataTransfer.dropEffect = 'move';
//         }}
//         onDrop={(e) => {
//           onDropBetween(e, item.id, 'below');
//           setIsHovered(false);
//         }}
//       />
//     </>
//   );
// };

// const ModalMiltilevelSidebarSettings = () => {
//   const [menu, setMenu] = useState(initialMenu);
//   const [draggedItemId, setDraggedItemId] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [newItemLabel, setNewItemLabel] = useState('');
//   const [selectedItemId, setSelectedItemId] = useState(null);
//   const [isLoadingChildren, setIsLoadingChildren] = useState(false);

//   const selectedSpace = useGeneralStore((state) => state.selectedSpace);
//   const headers = { ...customHeaders, ...getSpace(selectedSpace) };

//   const menuRef = useRef(null);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (menuRef.current && !menuRef.current.contains(event.target)) {
//         setSelectedItemId(null);
//       }
//     };

//     document.addEventListener('mousedown', handleClickOutside);
//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [menuRef]);

//   const findItemById = (items, id) => {
//     for (let item of items) {
//       if (item.id === id) return item;
//       if (item.children) {
//         const found = findItemById(item.children, id);
//         if (found) return found;
//       }
//     }
//   };

//   const findAndRemoveItem = (items, id) => {
//     for (let i = 0; i < items.length; i++) {
//       if (items[i].id === id) return items.splice(i, 1)[0];
//       if (items[i].children) {
//         const found = findAndRemoveItem(items[i].children, id);
//         if (found) return found;
//       }
//     }
//   };

//   const addItemToParent = (items, targetId, item) => {
//     for (let i = 0; i < items.length; i++) {
//       if (items[i].id === targetId) {
//         if (!items[i].children) items[i].children = [];
//         items[i].children.push(item);
//         items[i].expanded = true;
//         return;
//       }
//       if (items[i].children) {
//         addItemToParent(items[i].children, targetId, item);
//       }
//     }
//   };

//   const isChildOf = (childId, parentId, items) => {
//     for (let item of items) {
//       if (item.id === parentId) {
//         return item.children.some((child) => child.id === childId);
//       }
//       if (item.children) {
//         if (isChildOf(childId, parentId, item.children)) return true;
//       }
//     }
//     return false;
//   };

//   const addItemAsSibling = (items, targetId, item, position) => {
//     for (let i = 0; i < items.length; i++) {
//       if (items[i].id === targetId) {
//         const index = i;
//         if (position === 'above') {
//           items.splice(index, 0, item);
//         } else if (position === 'below') {
//           items.splice(index + 1, 0, item);
//         }
//         return;
//       }
//       if (items[i].children) {
//         addItemAsSibling(items[i].children, targetId, item, position);
//       }
//     }
//   };

//   const fetchArticlesByLevel = (parent = null) => {
//     setIsLoadingChildren(true);
//     const url = `${RavshanHttps}/api/wiki/levels`;
//     const parentsHeader = parent ? { 'parent-id': parent } : '';
//     const getHeaders = { ...headers, ...parentsHeader };

//     fetch(url, { headers: getHeaders })
//       .then(async (res) => {
//         if (res.ok) {
//           return res.json();
//         } else {
//           const err = await res.json();
//           throw new Error(JSON.stringify(err));
//         }
//       })
//       .then((json) => {
//         const transformedData = json.map(
//           ({ id, name, has_child, subItems }) => ({
//             id,
//             label: name,
//             has_child,
//             children:
//               subItems && subItems.length > 0
//                 ? subItems.map((subItem) => ({
//                     id: subItem.id,
//                     label: subItem.name,
//                     children: [],
//                     has_child: subItem.has_child,
//                   }))
//                 : [],
//             expanded: false,
//           })
//         );
//         if (parent) {
//           setMenu((prevMenu) => {
//             const newMenu = [...prevMenu];
//             const parentItem = findItemById(newMenu, parent);
//             if (parentItem) {
//               parentItem.children = transformedData;
//               parentItem.expanded = true;
//             }
//             return newMenu;
//           });
//         } else {
//           setMenu(transformedData);
//         }
//       })
//       .catch((err) => console.error(err))
//       .finally(() => {
//         setIsLoadingChildren(false);
//       });
//   };

//   const fetchChildren = (parentId) => {
//     fetchArticlesByLevel(parentId);
//   };

//   useEffect(() => {
//     fetchArticlesByLevel();
//   }, []);

//   const handleSelect = (itemId) => {
//     setSelectedItemId(itemId);
//   };

//   const toggleExpand = (id) => {
//     const newMenu = [...menu];
//     toggleExpandItem(newMenu, id);
//     setMenu(newMenu);
//   };

//   const toggleExpandItem = (items, id) => {
//     for (let i = 0; i < items.length; i++) {
//       if (items[i].id === id) {
//         items[i].expanded = !items[i].expanded;
//         return;
//       }
//       if (items[i].children) {
//         toggleExpandItem(items[i].children, id);
//       }
//     }
//   };

//   const openModalToAdd = () => {
//     setIsModalOpen(true);
//     setNewItemLabel('');
//   };

//   // const addNewItem = () => {
//   //   if (!newItemLabel) return;

//   //   const parentId = selectedItemId || null;
//   //   fetchAddLevel(newItemLabel, parentId, null)
//   //     .then((newItem) => {
//   //       const newMenu = [...menu];
//   //       const newMenuItem = {
//   //         id: newItem.id,
//   //         label: newItemLabel,
//   //         children: [],
//   //       };

//   //       if (parentId) {
//   //         addItemToParent(newMenu, parentId, newMenuItem);
//   //       } else {
//   //         newMenu.push(newMenuItem);
//   //       }

//   //       setMenu(newMenu);
//   //       setIsModalOpen(false);
//   //       setSelectedItemId(newMenuItem.id);
//   //       // fetchArticlesByLevel();
//   //     })
//   //     .catch((err) => console.error(err));
//   // };
//   // const addNewItem = () => {
//   //   if (!newItemLabel) return;

//   //   const parentId = selectedItemId || null;
//   //   fetchAddLevel(newItemLabel, parentId, null)
//   //     .then((newItem) => {
//   //       const newMenu = [...menu];
//   //       const newMenuItem = {
//   //         id: newItem.id,
//   //         label: newItemLabel,
//   //         children: [],
//   //       };

//   //       if (parentId) {
//   //         addItemToParent(newMenu, parentId, newMenuItem);
//   //       } else {
//   //         newMenu.unshift(newMenuItem); //в начало
//   //       }

//   //       setMenu(newMenu);
//   //       setIsModalOpen(false);
//   //       setSelectedItemId(newMenuItem.id);
//   //       fetchArticlesByLevel();
//   //     })
//   //     .catch((err) => console.error(err));
//   // };

//   const addNewItem = () => {
//     if (!newItemLabel) return;

//     const parentId = selectedItemId || null;
//     fetchAddLevel(newItemLabel, parentId, null)
//       .then((newItem) => {
//         const newMenu = [...menu];
//         const newMenuItem = {
//           id: newItem.id,
//           label: newItemLabel,
//           children: [],
//         };

//         if (parentId) {
//           addItemToParent(newMenu, parentId, newMenuItem);
//         } else {
//           newMenu.unshift(newMenuItem); // Add to root
//         }

//         setMenu(newMenu);
//         setIsModalOpen(false);
//         setSelectedItemId(newMenuItem.id); // Optionally select the new item
//         fetchArticlesByLevel();
//       })
//       .catch((err) => console.error(err));
//   };

//   const fetchAddLevel = (name, parent_id, place) => {
//     const url = `${RavshanHttps}/api/wiki/level`;
//     const body = JSON.stringify({
//       name,
//       parent_id,
//       before: place,
//     });

//     return fetch(url, { body, method: 'POST', headers })
//       .then((res) => {
//         if (res.ok) {
//           return res.json();
//         } else {
//           throw new Error(res.json());
//         }
//       })
//       .then(fetchArticlesByLevel())
//       .catch((err) => console.error(err));
//   };

//   const handleDragOver = (e) => {
//     e.preventDefault();
//   };

//   const handleDragStart = (e, id) => {
//     setDraggedItemId(id);
//     e.stopPropagation();
//   };

//   const handleDrop = (e, targetId) => {
//     e.preventDefault();
//     e.stopPropagation();

//     if (draggedItemId === targetId) return;

//     const newMenu = [...menu];
//     const draggedItem = findAndRemoveItem(newMenu, draggedItemId);
//     addItemToParent(newMenu, targetId, draggedItem);

//     setMenu(newMenu);
//     fetchChangeLevel(draggedItem.label, targetId, null, draggedItem.id);
//   };

//   const handleDropBetween = (e, targetId, position) => {
//     e.preventDefault();
//     e.stopPropagation();

//     if (draggedItemId === targetId) return;

//     const newMenu = [...menu];
//     const draggedItem = findAndRemoveItem(newMenu, draggedItemId);

//     let newParentId = null;
//     let before = targetId;

//     if (isChildOf(draggedItemId, targetId, newMenu)) {
//       addItemAsSibling(newMenu, targetId, draggedItem, position);
//       newParentId = findItemParent(newMenu, targetId)?.id || null;
//     } else {
//       addItemAsSibling(newMenu, targetId, draggedItem, position);
//     }

//     setMenu(newMenu);
//     fetchChangeLevel(draggedItem.label, newParentId, before, draggedItem.id);
//   };

//   const findItemParent = (items, id) => {
//     for (let item of items) {
//       if (item.children.some((child) => child.id === id)) return item;
//       if (item.children) {
//         const found = findItemParent(item.children, id);
//         if (found) return found;
//       }
//     }
//   };

//   const fetchChangeLevel = (name, parent_id, place, wiki_id) => {
//     const url = `${RavshanHttps}/api/wiki/level`;
//     const body = JSON.stringify({
//       name,
//       parent_id,
//       before: place,
//       wiki_id,
//     });

//     fetch(url, { body, method: 'PUT', headers })
//       .then((res) => {
//         if (res.ok) {
//           fetchArticlesByLevel();
//           return res.json();
//         } else {
//           throw new Error(res.json());
//         }
//       })
//       .catch((err) => {
//         console.error(err);
//       });
//   };

//   const fetchDeleteLevel = (level_id) => {
//     const url = `${RavshanHttps}/api/wiki/level`;

//     const _headers = {
//       ...headers,
//       'level-id': level_id,
//     };

//     fetch(url, { method: 'DELETE', headers: _headers })
//       .then((res) => {
//         if (res.ok) {
//           if (res.status === 200) {
//             setMenu((prevState) => {
//               const updatedState = removeItemFromMenu(prevState, level_id);
//               return updatedState;
//             });
//             fetchArticlesByLevel();
//           } else {
//             return res.json();
//           }
//         } else {
//           throw new Error(res.json());
//         }
//       })
//       .catch((err) => {
//         console.error(err);
//       });
//   };

//   const removeItemFromMenu = (items, id) => {
//     return items.reduce((acc, item) => {
//       if (item.id === id) return acc;
//       if (item.children) {
//         item.children = removeItemFromMenu(item.children, id);
//       }
//       acc.push(item);
//       return acc;
//     }, []);
//   };

//   return (
//     <div className="modal-miltilevel-sidebar-settings" ref={menuRef}>
//       <h2 className="modal-miltilevel-sidebar-settings__title">Wiki</h2>
//       <button onClick={openModalToAdd} className="add-root-item-btn">
//         +
//       </button>
//       <div className="menu">
//         {menu.map((item) => (
//           <MenuItem
//             key={item.id}
//             item={item}
//             onDragStart={handleDragStart}
//             onDragOver={handleDragOver}
//             onDrop={handleDrop}
//             onDropBetween={handleDropBetween}
//             toggleExpand={toggleExpand}
//             handleAddChild={openModalToAdd}
//             handleDelete={fetchDeleteLevel}
//             handleSelect={handleSelect}
//             selectedItemId={selectedItemId}
//             fetchChildren={fetchChildren}
//           />
//         ))}
//       </div>
//       {isModalOpen && (
//         <div className="modal-overlay">
//           <div className="modal">
//             <h3>Добавить подстраницу</h3>
//             <input
//               type="text"
//               value={newItemLabel}
//               onChange={(e) => setNewItemLabel(e.target.value)}
//               placeholder="Введите название"
//             />
//             <button
//               onClick={(e) => {
//                 addNewItem();
//               }}
//             >
//               Добавить
//             </button>
//             <button onClick={() => setIsModalOpen(false)}>Отмена</button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export { ModalMiltilevelSidebarSettings };
import React, { useState, useEffect, useRef } from 'react';
import './ModalMiltilevelSidebarSettings.scss';
import customHeaders, { getSpace } from '../../../common/headers';
import { RavshanHttps } from '../../../fetchUrls';
import useGeneralStore from '../../../store/general';

const initialMenu = [];

const MenuItem = ({
  item,
  onDragStart,
  onDragOver,
  onDrop,
  onDropBetween,
  toggleExpand,
  handleAddChild,
  handleSelect,
  handleDelete,
  selectedItemId,
  fetchChildren,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredForDrop, setIsHoveredForDrop] = useState(false);

  return (
    <>
      <div
        onDragOver={(e) => onDragOver(e)}
        onDrop={(e) => onDropBetween(e, item.id, 'above')}
      />
      <div
        className={`menu-item ${isHoveredForDrop ? 'hovered-for-drop' : ''}`}
        draggable
        onDragStart={(e) => {
          onDragStart(e, item.id);
        }}
        onDragOver={(e) => {
          onDragOver(e);
          e.dataTransfer.dropEffect = 'move';
          setIsHoveredForDrop(true);
        }}
        onDrop={(e) => {
          onDrop(e, item.id);
          setIsHoveredForDrop(false);
        }}
        onDragLeave={() => setIsHoveredForDrop(false)}
        onClick={(e) => {
          e.stopPropagation();
          handleSelect(item.id);
        }}
      >
        <div
          className="item-label"
          style={{
            backgroundColor: selectedItemId === item.id ? '#e6f7ff' : '',
          }}
        >
          {(item.has_child || item.children.length > 0) && (
            <span
              className={`arrow ${item.expanded ? 'open' : ''}`}
              onClick={(e) => {
                e.stopPropagation();
                if (item.expanded) {
                  toggleExpand(item.id, false);
                } else {
                  fetchChildren(item.id);
                  toggleExpand(item.id, true);
                }
              }}
            >
              ▶
            </span>
          )}
          {item.label}
          <button
            className="add-child-btn"
            onClick={(e) => {
              e.stopPropagation();
              handleAddChild(item.id);
            }}
          >
            +
          </button>
          <button
            className="delete-btn"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(item.id);
            }}
          >
            🗑️
          </button>
        </div>
        {item.expanded && item.children.length > 0 && (
          <div className="children">
            {item.children.map((child) => (
              <MenuItem
                key={child.id}
                item={child}
                onDragStart={onDragStart}
                onDragOver={onDragOver}
                onDrop={onDrop}
                onDropBetween={onDropBetween}
                toggleExpand={toggleExpand}
                handleAddChild={handleAddChild}
                handleDelete={handleDelete}
                handleSelect={handleSelect}
                selectedItemId={selectedItemId}
                fetchChildren={fetchChildren}
              />
            ))}
          </div>
        )}
      </div>
      <div
        style={{
          height: '5px',
          backgroundColor: isHovered ? '#5282ff' : 'transparent',
        }}
        onDragEnter={() => setIsHovered(true)}
        onDragLeave={() => setIsHovered(false)}
        onDragOver={(e) => {
          onDragOver(e);
          e.dataTransfer.dropEffect = 'move';
        }}
        onDrop={(e) => {
          onDropBetween(e, item.id, 'below');
          setIsHovered(false);
        }}
      />
    </>
  );
};

const ModalMiltilevelSidebarSettings = () => {
  const [menu, setMenu] = useState(initialMenu);
  const [draggedItemId, setDraggedItemId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newItemLabel, setNewItemLabel] = useState('');
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isLoadingChildren, setIsLoadingChildren] = useState(false);

  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };

  const menuRef = useRef(null);

  const getExpandedFromStorage = () => {
    const expandedItems = localStorage.getItem('expandedItems');
    return expandedItems ? JSON.parse(expandedItems) : [];
  };

  const saveExpandedToStorage = (expandedItems) => {
    localStorage.setItem('expandedItems', JSON.stringify(expandedItems));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setSelectedItemId(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  const findItemById = (items, id) => {
    for (let item of items) {
      if (item.id === id) return item;
      if (item.children) {
        const found = findItemById(item.children, id);
        if (found) return found;
      }
    }
  };

  const findAndRemoveItem = (items, id) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].id === id) return items.splice(i, 1)[0];
      if (items[i].children) {
        const found = findAndRemoveItem(items[i].children, id);
        if (found) return found;
      }
    }
  };

  const addItemToParent = (items, targetId, item) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].id === targetId) {
        if (!items[i].children) items[i].children = [];
        items[i].children.push(item);
        items[i].expanded = true;
        return;
      }
      if (items[i].children) {
        addItemToParent(items[i].children, targetId, item);
      }
    }
  };

  const isChildOf = (childId, parentId, items) => {
    for (let item of items) {
      if (item.id === parentId) {
        return item.children.some((child) => child.id === childId);
      }
      if (item.children) {
        if (isChildOf(childId, parentId, item.children)) return true;
      }
    }
    return false;
  };

  const addItemAsSibling = (items, targetId, item, position) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].id === targetId) {
        const index = i;
        if (position === 'above') {
          items.splice(index, 0, item);
        } else if (position === 'below') {
          items.splice(index + 1, 0, item);
        }
        return;
      }
      if (items[i].children) {
        addItemAsSibling(items[i].children, targetId, item, position);
      }
    }
  };

  const fetchArticlesByLevel = (parent = null) => {
    setIsLoadingChildren(true);
    const url = `${RavshanHttps}/api/wiki/levels`;
    const parentsHeader = parent ? { 'parent-id': parent } : '';
    const getHeaders = { ...headers, ...parentsHeader };

    fetch(url, { headers: getHeaders })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        const expandedItems = getExpandedFromStorage();
        const transformedData = json.map(
          ({ id, name, has_child, subItems }) => ({
            id,
            label: name,
            has_child,
            children:
              subItems && subItems.length > 0
                ? subItems.map((subItem) => ({
                    id: subItem.id,
                    label: subItem.name,
                    children: [],
                    has_child: subItem.has_child,
                  }))
                : [],
            expanded: expandedItems.includes(id),
          })
        );

        transformedData.forEach((item) => {
          if (item.expanded) {
            fetchChildren(item.id);
          }
        });

        if (parent) {
          setMenu((prevMenu) => {
            const newMenu = [...prevMenu];
            const parentItem = findItemById(newMenu, parent);
            if (parentItem) {
              parentItem.children = transformedData;
              parentItem.expanded = true;
            }
            return newMenu;
          });
        } else {
          setMenu(transformedData);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoadingChildren(false);
      });
  };

  const fetchChildren = (parentId) => {
    fetchArticlesByLevel(parentId);
  };

  useEffect(() => {
    fetchArticlesByLevel();
  }, []);

  const handleSelect = (itemId) => {
    setSelectedItemId(itemId);
  };

  const toggleExpand = (id, expanded) => {
    const newMenu = [...menu];
    const expandedItems = getExpandedFromStorage();

    if (expanded) {
      if (!expandedItems.includes(id)) {
        expandedItems.push(id);
      }
    } else {
      const index = expandedItems.indexOf(id);
      if (index > -1) {
        expandedItems.splice(index, 1);
      }
    }

    saveExpandedToStorage(expandedItems);

    toggleExpandItem(newMenu, id, expanded);
    setMenu(newMenu);
  };

  const toggleExpandItem = (items, id, expanded) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].id === id) {
        items[i].expanded = expanded;
        return;
      }
      if (items[i].children) {
        toggleExpandItem(items[i].children, id, expanded);
      }
    }
  };

  const openModalToAdd = () => {
    setIsModalOpen(true);
    setNewItemLabel('');
  };

  const addNewItem = () => {
    if (!newItemLabel) return;

    const parentId = selectedItemId || null;
    fetchAddLevel(newItemLabel, parentId, null)
      .then((newItem) => {
        const newMenu = [...menu];
        const newMenuItem = {
          id: newItem.id,
          label: newItemLabel,
          children: [],
        };

        if (parentId) {
          addItemToParent(newMenu, parentId, newMenuItem);
        } else {
          newMenu.unshift(newMenuItem);
        }

        setMenu(newMenu);
        setIsModalOpen(false);
        setSelectedItemId(newMenuItem.id);
        fetchArticlesByLevel();
      })
      .catch((err) => console.error(err));
  };

  const fetchAddLevel = (name, parent_id, place) => {
    const url = `${RavshanHttps}/api/wiki/level`;
    const body = JSON.stringify({
      name,
      parent_id,
      before: place,
    });

    return fetch(url, { body, method: 'POST', headers })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.json());
        }
      })
      .then(fetchArticlesByLevel())
      .catch((err) => console.error(err));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragStart = (e, id) => {
    setDraggedItemId(id);
    e.stopPropagation();
  };

  const handleDrop = (e, targetId) => {
    e.preventDefault();
    e.stopPropagation();

    if (draggedItemId === targetId) return;

    const newMenu = [...menu];
    const draggedItem = findAndRemoveItem(newMenu, draggedItemId);
    addItemToParent(newMenu, targetId, draggedItem);

    setMenu(newMenu);
    fetchChangeLevel(draggedItem.label, targetId, null, draggedItem.id);
  };

  const handleDropBetween = (e, targetId, position) => {
    e.preventDefault();
    e.stopPropagation();

    if (draggedItemId === targetId) return;

    const newMenu = [...menu];
    const draggedItem = findAndRemoveItem(newMenu, draggedItemId);

    let newParentId = null;
    let before = targetId;

    if (isChildOf(draggedItemId, targetId, newMenu)) {
      addItemAsSibling(newMenu, targetId, draggedItem, position);
      newParentId = findItemParent(newMenu, targetId)?.id || null;
    } else {
      addItemAsSibling(newMenu, targetId, draggedItem, position);
    }

    setMenu(newMenu);
    fetchChangeLevel(draggedItem.label, newParentId, before, draggedItem.id);
  };

  const findItemParent = (items, id) => {
    for (let item of items) {
      if (item.children.some((child) => child.id === id)) return item;
      if (item.children) {
        const found = findItemParent(item.children, id);
        if (found) return found;
      }
    }
  };

  const fetchChangeLevel = (name, parent_id, place, wiki_id) => {
    const url = `${RavshanHttps}/api/wiki/level`;
    const body = JSON.stringify({
      name,
      parent_id,
      before: place,
      wiki_id,
    });

    fetch(url, { body, method: 'PUT', headers })
      .then((res) => {
        if (res.ok) {
          fetchArticlesByLevel();
          return res.json();
        } else {
          throw new Error(res.json());
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchDeleteLevel = (level_id) => {
    const url = `${RavshanHttps}/api/wiki/level`;

    const _headers = {
      ...headers,
      'level-id': level_id,
    };

    fetch(url, { method: 'DELETE', headers: _headers })
      .then((res) => {
        if (res.ok) {
          if (res.status === 200) {
            setMenu((prevState) => {
              const updatedState = removeItemFromMenu(prevState, level_id);
              return updatedState;
            });
            fetchArticlesByLevel();
            setSelectedItemId(null);
          } else {
            return res.json();
          }
        } else {
          throw new Error(res.json());
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const removeItemFromMenu = (items, id) => {
    return items.reduce((acc, item) => {
      if (item.id === id) return acc;
      if (item.children) {
        item.children = removeItemFromMenu(item.children, id);
      }
      acc.push(item);
      return acc;
    }, []);
  };

  return (
    <div className="modal-miltilevel-sidebar-settings" ref={menuRef}>
      <h2 className="modal-miltilevel-sidebar-settings__title">Wiki</h2>
      <button onClick={openModalToAdd} className="add-root-item-btn">
        +
      </button>
      <div className="menu">
        {menu.map((item) => (
          <MenuItem
            key={item.id}
            item={item}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onDropBetween={handleDropBetween}
            toggleExpand={toggleExpand}
            handleAddChild={openModalToAdd}
            handleDelete={fetchDeleteLevel}
            handleSelect={handleSelect}
            selectedItemId={selectedItemId}
            fetchChildren={fetchChildren}
          />
        ))}
      </div>
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h3>Добавить подстраницу</h3>
            <input
              type="text"
              value={newItemLabel}
              onChange={(e) => setNewItemLabel(e.target.value)}
              placeholder="Введите название"
            />
            <button
              onClick={(e) => {
                addNewItem();
              }}
            >
              Добавить
            </button>
            <button onClick={() => setIsModalOpen(false)}>Отмена</button>
          </div>
        </div>
      )}
    </div>
  );
};

export { ModalMiltilevelSidebarSettings };
