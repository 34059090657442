import customHeaders, { getSpace } from '../../../common/headers';
import { ModalNewNameScenario } from './ModalNewNameScenario/ModalNewNameScenario';
import { useState, useEffect, memo, useMemo } from 'react';
import { useRef } from 'react';
import drop from './img/drop.svg'
import drop_hide from './img/drop_hide.svg'
import {
  DropDownSelector,
  ButtonBasic,
  InputDinamycPlaceholder,
  TextareaDynamicPlaceholder,
  CheckboxToggle,
  CheckboxToggleSmile,
} from '@lk-gtcom/ecomlab-components';
import { FilterContainer } from '../../FilterContainer/FilterContainer';
import {
  fetchApiReviewsFilter,
  fetchFeedbacksBrandFilter,
  fetchFeedbacksCategoryFilter,
} from '../../../common/filtersFunc';
import {
  KonstantinFeedbackHttps,
  KonstantinFeedbackHttp,
} from './../../../fetchUrls';
import edit_icon from './img/bx-edit.svg.svg';
import close_icon from './img/bx-x.svg.svg';
import useGeneralStore from '../../../store/general';
import plus_circle from './img/bx-plus-circle.svg'
import check from './img/bx-check.svg'
import { ButtonActionDrop } from '../../Buttons/ButtonActionDrop/ButtonActionDrop';
import { ModalExitReadyScenario } from './ModalExitReadyScenario/ModalExitReadyScenario'
import info_circle from './img/bx-info-circle.svg'
import { MoonLoader } from 'react-spinners';
import './ModalAddingReadyScenario.scss';

const mpData = [
  {
    label: 'Ozon',
    value: 1,
    id: 1,
  },
  {
    label: 'Wildberries',
    value: 3,
    id: 3,
  },
  {
    label: 'Yandex',
    value: 2,
    id: 2,
  },
];

const answerTemplate2 = [
  {
    label: 'Ответ по шаблону',
    value: true,
  },
  {
    label: 'Ответ с помощью ИИ',
    value: false,
  },
  {
    label: 'Не отвечать',
    value: false,
  },
];

const template_selection_option = [
  {
    label: 'На отзывы с одинаковой оценкой для одного товара ответы чередуются (не повторяются 2 раза подряд)',
    value: true
  },
  {
    label: 'Шаблоны для ответа выбираются рандомно',
    value: false
  }
]
const CategoryItem = (el, ind) => {
  if (!el) throw new Error('No el');
  const {
    group_id,
    name,
    id,
    groupList,
    groupsCallback,
    answer_type,
    positive,
    answer_delay,
    answer_group_id,
    rotate_answer_for_same_product_and_rating,
    stop_brand_id_list,
    stop_word_list,
    stop_category_id_list,
    isCreate,
    setIsCreate,
    on_moderation,
    isNew = false,
    isModalNewNameScenario,
    setIsNewNameScenario,
    isEditId,
    setIsEditId,
    ruleId,
    isCreateCategory,
    fetchRuleNew,
    setIsCreateCategory,
    setSelectedCategoryProperties,
    setIsModalScenarioSettings,
    setIsAddCategory,
    headers,
    loading, 
    setLoading,
  } = el;

  let currentAnswerType = answerTemplate2.filter((el) => el.label == answer_type);

  const [selectedAnswerTemplate, setSelectedAnswerTemplate] = useState(currentAnswerType[0] ? currentAnswerType[0] : {});
  const [selectedGroup, setSelectedGroup] = useState(answer_group_id?.value ? answer_group_id : null);


  const [groupsPage, setGroupsPage] = useState(0);
  const [groupsSearch, setGroupsSearch] = useState('');

  const [isEditName, setIsEditname] = useState(false);
  const [isPositive, setIsPositive] = useState(positive ? positive : false);
  const [isModeration, setIsModeration] = useState(on_moderation ? on_moderation : false);
  const [responseDelay, setResponseDelay] = useState(answer_delay);
  const [valuename, setValueName] = useState(name);
  const [initial, setInitial] = useState(true)

  const currentSelectedTemplate = template_selection_option.filter(({ label, value }) => value == rotate_answer_for_same_product_and_rating)
  const [selectedTemplate, setSelectedTemplate] = useState(currentSelectedTemplate[0] ? currentSelectedTemplate[0] : null)

  const createRuleCategory = () => {
    setLoading(true)
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/category-rules/${ruleId}/categories`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/category-rules/${ruleId}/categories`;
    }

    const body = JSON.stringify({
      group_id: isCreateCategory,
      name: valuename,
      positive: isPositive,
      on_moderation: isModeration,
      // active: ,
      answer_type: selectedAnswerTemplate?.label ? selectedAnswerTemplate?.label : null,
      answer_group_id: selectedGroup,
      // add_customer_name: false,
      rotate_answer_for_same_product_and_rating: selectedTemplate?.value ? selectedTemplate?.value : false,
      answer_delay: responseDelay,
      // stop_category_id_list: [],
      // stop_brand_id_list: [],
      // stop_word_list: []
    });

    fetch(url, { body, method: 'POST', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        fetchRuleNew();
        setIsAddCategory(false);
        setIsCreateCategory(false)
        setIsCreate(false)
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setTimeout(() => setLoading(false), 1000)
      })
  };

  const changeRuleCat = () => {
    setLoading(true)
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/category-rules/${ruleId}/categories/${id}`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/category-rules/${ruleId}/categories/${id}`;
    }

    const body = JSON.stringify({
      group_id: group_id,
      name: valuename,
      positive: isPositive,
      on_moderation: isModeration,
      answer_type: selectedAnswerTemplate?.label ? selectedAnswerTemplate?.label : null,
      answer_group_id: selectedGroup ? selectedGroup : answer_group_id,
      answer_delay: responseDelay ? responseDelay : null,
      stop_category_id_list: stop_category_id_list,
      stop_brand_id_list: stop_brand_id_list,
      stop_word_list: stop_word_list,
      rotate_answer_for_same_product_and_rating: selectedTemplate?.value ? selectedTemplate?.value : false, // проверить почему не уходит false
    });

    fetch(url, { body, method: 'PUT', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then((json) => { })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setTimeout(() => setLoading(false), 1000)
      })
  };

  const deleteRuleCat = (category_id) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/category-rules/${ruleId}/categories/${category_id}`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/category-rules/${ruleId}/categories/${category_id}`;
    }

    fetch(url, { method: 'DELETE', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then(() => {
        setTimeout(() => fetchRuleNew(), 500)
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setTimeout(() => fetchRuleNew(), 500)
      });

  };

  useEffect(() => {
    if (isCreate && !id && isCreateCategory) {
      createRuleCategory(isCreateCategory);
      setIsCreate(false)
      setIsCreateCategory(false)
    }
  }, [isCreateCategory]);

  useEffect(() => {
    setInitial(false)
  }, []);

  useEffect(() => {
    if (valuename)
      if (!initial) {
        if (!id) {
          //createRuleCategory();
        } else {
          changeRuleCat();
        }
      }
  }, [
    //valuename,
    selectedGroup,
    selectedAnswerTemplate,
    isPositive,
    responseDelay,
    selectedTemplate,
    isModeration
  ]);

  const btns = [
    { btn: 'Удалить', func: (e) => deleteRuleCat(e) }
  ]

  try {
    return (
      <div key={name + id}>
        {(isModalNewNameScenario && ((id == isEditId || isNew))) && <ModalNewNameScenario
          isEdit={isEditName}
          valuename={valuename}
          createRuleCategory={(e) => createRuleCategory(isCreateCategory)}
          changeRuleCat={(e) => changeRuleCat(e)}
          setIsModal={(e) => setIsNewNameScenario(e)}
          setValueName={(e) => setValueName(e)}
          fetchRuleNew={(e) => fetchRuleNew(e)}
          setIsCreateCategory={(e) => setIsCreateCategory(e)}
          setIsEditId={(e) => setIsEditId(e)}
          isEditId={isEditId}
        />}

        {
          isNew ? <></> :
            <div className="row-item" key={id}>
              <div className="category-item">
                <span>{name}</span>

                {/* <input
              autoFocus={true}
              className="category-item__input"
              onChange={(e) => {
                setValueName(e.target.value)
              }}
              value={valuename}
            /> */}

                <img src={edit_icon}
                  onClick={(e) => {
                    setIsEditname(true)
                    setIsEditId(id)
                    setIsNewNameScenario(true)
                  }} />
              </div>
              <DropDownSelector
                minMenuHeight={'auto'}
                options_prop={answerTemplate2}
                state={selectedAnswerTemplate}
                setState={(e) => setSelectedAnswerTemplate(e)}
                placeholder="Как отвечать?"
              />
              <DropDownSelector
                minMenuHeight={'auto'}
                err={
                  (selectedAnswerTemplate[0]?.label == true || selectedAnswerTemplate?.value == true)
                  && !(selectedGroup?.label)}
                options_prop={groupList ? groupList : []}
                state={selectedGroup}
                setState={(e) => setSelectedGroup(e)}
                placeholder="Выберите группу"
                fetchCallback={(e) => setGroupsPage(e)}
                setInputChange={(e) => {
                  setGroupsSearch(e)
                }}
                disabled={
                  selectedAnswerTemplate[0]?.value == false ||
                  selectedAnswerTemplate?.value == false
                }
              />

              <DropDownSelector
                minMenuHeight={'auto'}
                err={
                  (selectedAnswerTemplate[0]?.label == true || selectedAnswerTemplate?.value == true)
                  && !(selectedTemplate?.label)}
                options_prop={template_selection_option}
                state={selectedTemplate}
                setState={(e) => setSelectedTemplate(e)}
                placeholder="Выберите шаблон"
                disabled={
                  selectedAnswerTemplate[0]?.label == false ||
                  selectedAnswerTemplate?.value == false
                }
              />

              <input
                type="number"
                value={responseDelay}
                onChange={(e) => {
                  setResponseDelay(Number(e.target.value))
                }}
                className="category-item__input"
                style={{
                  width: 120,
                  minWidth: 120,
                  height: '40px',
                  outline: 'none',
                  border: '1px solid lightgrey',
                  borderRadius: '8px',
                  textAlign: 'center',
                }}
              />

              <div className='category-item__toggle'>
                <CheckboxToggle
                  color='blue'
                  value={isModeration}
                  onChange={(e) => {
                    setIsModeration(e);
                  }}
                />
              </div>

              <div className="row-item__container">
                <button
                  className="btn-setting"
                  onClick={(e) => {
                    setSelectedCategoryProperties({
                      id: id,
                      ruleId: ruleId,
                      group_id: group_id,
                      name: valuename,
                      positive: isPositive,
                      on_moderation: isModeration,
                      answer_type: selectedAnswerTemplate?.label ? selectedAnswerTemplate?.label : null,
                      answer_group_id: selectedGroup ? selectedGroup : answer_group_id,
                      answer_delay: responseDelay ? responseDelay : null,
                      stop_category_id_list: stop_category_id_list,
                      stop_brand_id_list: stop_brand_id_list,
                      stop_word_list: stop_word_list,
                      rotate_answer_for_same_product_and_rating: selectedTemplate?.value ? selectedTemplate?.value : false, // проверить почему не уходит false                
                    });
                    setIsModalScenarioSettings(true);
                  }}
                ></button>

                {/* <button
                  className="btn-more"
                  onClick={(e) => setIsCreateCategory(false)}
                ></button> */}

                <ButtonActionDrop
                  id={id}
                  btns={btns}
                />
              </div>
            </div>}
        {/* {isNew && <ButtonBasic
          grey
          text='Создать'
          onClick={(e) => {
            if (valuename) {
              createRuleCategory(e)
              setIsCreateCategory(false)
            }
          }}
        />} */}
      </div>

    );
  } catch (error) {
    return 'Что-то пошло не так';
  }
};

const ModalAddingReadyScenario = ({
  setIsEdit,
  isEdit,
  setIsModal,
  setIsModalScenarioSettings,
  selectedCategoryProperties,
  setSelectedCategoryProperties,
  fetchRuleTable,
  scenarioData,
  setScenarioData,
}) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);
  const [dateFrom, dateTo] = date;

  const [pageValue, setPageValue] = useState();

  const [apiData, setApiData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [isOpenItems, setIsOpenItems] = useState(new Set([]))

  const [selectedMarketplace, setSelectedMarketplace] = useState({
    label: 'Ozon',
    value: 1,
  });
  const [selectedApi, setSelectedApi] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);

  const [apiPage, setApiPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);

  const [apiQuery, setApiQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');

  // const [titleValue, setTitleValue] = useState('');
  const [title, setTitle] = useState('');
  const [changeValue, setChangeValue] = useState('')

  const [description, setDescription] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');

  const [isCheck, setIsCheck] = useState(false);
  const [ruleId, setRuleId] = useState(
    scenarioData?.id ? scenarioData?.id : -1
  );
  const [categoriesList, setCategoriesList] = useState([]);

  const [groupList, setGroupList] = useState([]);
  const [categoriesListMemo, setCategoriesListMemo] = useState(<></>);
  const [isAddCategory, setIsAddCategory] = useState(false);
  const [isCreateCategory, setIsCreateCategory] = useState(false);
  const [isCreate, setIsCreate] = useState(false)
  const [isModalNewNameScenario, setIsNewNameScenario] = useState(false)
  const [isEditId, setIsEditId] = useState(null)
  const [isEditNameScenario, setIsEditNameScenario] = useState(!isEdit)
  const [initData, setInitData] = useState({})
  const refContainer = useRef()
  const [isCreateRule, setIsCreateRule] = useState(!isEdit)
  const [loading, setLoading] = useState(false)

  const createRule = () => {
    // if (title?.length < 1) {
    //   return;
    // }
    setLoading(true)
    let url;
    if (!window.location.hostname.match('localhost')) {
      if (isEdit) {
        url = `${KonstantinFeedbackHttps}/feedbacks/category-rules/${ruleId}`;
      } else {
        url = `${KonstantinFeedbackHttps}/feedbacks/category-rules`;
      }
    } else {
      if (isEdit) {
        url = `${KonstantinFeedbackHttp}/feedbacks/category-rules/${ruleId}`;
      } else {
        url = `${KonstantinFeedbackHttp}/feedbacks/category-rules`;
      }
    }

    const body = JSON.stringify({
      active: isCheck,
      name: title,
      description: description,
      mp_id: selectedMarketplace?.value,
      api_id_list: Array.isArray(selectedApi) ? selectedApi : [selectedApi],
      category_id_list: Array.isArray(selectedCategory)
        ? selectedCategory
        : selectedCategory ? [selectedCategory] : [],
      brand_id_list: Array.isArray(selectedBrand)
        ? selectedBrand
        : selectedBrand ? [selectedBrand] : [],
    });

    fetch(url, { body, method: isEdit ? 'PUT' : 'POST', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setRuleId(json?.id);
        setIsEdit(true);
        fetchRule(json?.id)
        // setTimeout(() => {
        //     fetchRuleTable()
        // }, 600)
        // setScenarioData({})
        // setIsModal(false)
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setTimeout(() => setLoading(false), 1000)
      })
  };

  const fetchRule = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/category-rules/${ruleId}`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/category-rules/${ruleId}`;
    }

    fetch(url, { method: 'GET', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        const {
          id,
          name,
          mp_id,
          active,
          api_id_list,
          category_id_list,
          brand_id_list,
          description,
        } = json;
        setRuleId(id);
        setIsCheck(active);
        setTitle(name)
        setChangeValue(name)
        // setTitleValue(name);
        setDescriptionValue(description);
        setSelectedMarketplace(...mpData?.filter((el) => el?.value === mp_id));
        setSelectedApi(api_id_list);
        setSelectedCategory(category_id_list);
        setSelectedBrand(brand_id_list);
        setInitData({
          ruleId: id,
          isCheck: active,
          title: name,
          descriptionValue: description,
          selectedMarketplace: JSON.stringify(...mpData?.filter((el) => el?.value === mp_id)),
          selectedApi: JSON.stringify(api_id_list),
          selectedCategory: JSON.stringify(category_id_list),
          selectedBrand: JSON.stringify(brand_id_list)
        })
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchRuleNew = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/category-rules/${ruleId}/group-categories`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/category-rules/${ruleId}/group-categories`;
    }

    fetch(url, { method: 'GET', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setCategoriesList(json);
        console.log(json)
        if (json?.length) {
          const newState = isOpenItems
          newState.add(json[0].group_id);
          setIsOpenItems(newState)
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchRuleCat = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/category-rules/${ruleId}/categories`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/category-rules/${ruleId}/categories`;
    }

    fetch(url, { method: 'GET', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setCategoriesList(json);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchGroupList = (page, searchInput) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/answers/groups?limit=100&offset=${page ? page : 0
        }${searchInput?.length > 0 ? `&search_param=${searchInput}` : ''}`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/answers/groups?limit=100&offset=${page ? page : 0
        }${searchInput?.length > 0 ? `&search_param=${searchInput}` : ''}`;
    }

    fetch(url, { method: 'GET', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setGroupList(
          json?.map(({ group_name, id }) => ({ label: group_name, value: id }))
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (isEdit) {
      if (ruleId) {
        fetchRule();
        fetchRuleNew()
        //  fetchRuleCat();
      }
    } else {
      setCategoriesList([]);
    }
  }, [isEdit, scenarioData, ruleId]);

  useEffect(() => {
    fetchApiReviewsFilter({
      selectedSpace: selectedSpace?.value,
      selectedMarketplace: selectedMarketplace,
      withAll: false,
      page: apiPage,
      setApiData: (e) => setApiData(e),
      searchParam: apiQuery,
      type: 'wb',
    });
  }, [apiPage, apiQuery, selectedMarketplace]);

  useEffect(() => {
    fetchFeedbacksCategoryFilter({
      selectedSpace: selectedSpace?.value,
      page: categoryPage,
      apiData,
      dateFrom,
      dateTo,
      selectedApi,
      pageValue: 'feedbacks',
      selectedBrand,
      selectedCategory,
      setCategoryData: (e) => setCategoryData(e),
      searchParam: categoryQuery,
    });
  }, [
    selectedSpace,
    apiData,
    dateFrom,
    dateTo,
    selectedApi,
    pageValue,
    selectedBrand,
    categoryPage,
    categoryQuery,
  ]);

  useEffect(() => {
    fetchFeedbacksBrandFilter({
      selectedSpace: selectedSpace?.value,
      page: brandPage,
      apiData,
      categoryData,
      selectedCategory,
      pageValue: 'feedbacks',
      selectedBrand,
      selectedApi,
      dateFrom,
      dateTo,
      searchParam: brandQuery,
      setBrandData: (e) => setBrandData(e),
    });
  }, [
    selectedSpace,
    categoryData,
    selectedApi,
    selectedCategory,
    pageValue,
    selectedBrand,
    brandPage,
    brandQuery,
  ]);

  useEffect(() => {
    fetchGroupList();
  }, []);

  const [isModalExit, setIsModalExit] = useState(false)

  const onClose = () => {
    setRuleId(-1);
    setScenarioData({})
    setIsEdit(false);
    setIsModal(false);
    setTimeout(() => fetchRuleTable(), 500)
  }

  const onSave = () => {
    createRule()
    setTimeout(() => onClose(), 500)
  }

  const isChanges = () => {
    if (initData.ruleId == ruleId &&
      initData.isCheck == isCheck &&
      initData.title == title &&
      initData.descriptionValue == description &&
      initData.selectedMarketplace == JSON.stringify(selectedMarketplace) &&
      initData.selectedApi == JSON.stringify(selectedApi) &&
      initData.selectedCategory == JSON.stringify(selectedCategory) &&
      initData.selectedBrand == JSON.stringify(selectedBrand)) {
      return true
    } else return false
  }

  return (
    <div>
      {isModalExit && <ModalExitReadyScenario
        onClose={(e) => onClose(e)}
        onSave={(e) => onSave(e)}
        setIsModal={(e) => setIsModalExit(e)}
      />}

      <div
        className="blackout blackout-modal"
        onClick={(e) => {
          let el = e.target;
          if (el.classList.contains('blackout-modal')) {
            if (isEdit) {
              if (isChanges()) {
                onClose()
              } else {
                setIsModalExit(true)
              }
            } else {
              onClose()
            }
          }
        }}
      >
        <div className="modal-adding-ready-scenario">
          <div className="modal-adding-ready-scenario__header">
            <div className="modal-adding-ready-scenario__header-left">
              <div className="modal-adding-ready-scenario__title-box">
                <h2 className="modal-adding-ready-scenario__title">
                  {isEdit ? `Редактирование сценария` : `Добавление готового сценария`}
                  {!isEditNameScenario && <span> {changeValue} {((isEdit && !isEditNameScenario)) &&
                    <button className='modal-adding-ready-scenario__btn-edit'
                      onClick={(e) => setIsEditNameScenario(e)}>
                      <img src={edit_icon}>
                      </img>
                    </button>}
                  </span>}
                </h2>

                {(isEditNameScenario) &&
                  <div className='modal-adding-ready-scenario__input-and-btn'>
                    <InputDinamycPlaceholder
                      position="left"
                      maxWidth="100%"
                      isOpen={true}
                      onChange={(value) => setTitle(value)}
                      changeValue={changeValue}
                      placeholder="Введите название сценария "
                      size="s"
                      required={true}
                    />
                    {/* <button className='modal-adding-ready-scenario__btn-done'
                    onClick={(e) => setIsEditNameScenario(false)}
                  >
                    <img src={check} />
                  </button> */}
                  </div>
                }
              </div>
            </div>

            <div className="modal-adding-ready-scenario__header-right">
              {isEdit &&
                <div className='modal-adding-ready-scenario__btn-loader'>
                  <ButtonBasic
                    green
                    width="180px"
                    text={<div className="content-btn">{
                      (!(selectedMarketplace?.value || selectedMarketplace[0]?.value) || !(selectedApi?.value || selectedApi[0]?.value) || !(title) || isChanges()) ?
                      loading ? 'Сохраняется...' :
                        'Сохранено' : 'Сохранить сценарий'
                    }</div>}
                    size="40px"
                    onClick={(e) => createRule()}
                    disabled={!(selectedMarketplace?.value || selectedMarketplace[0]?.value) || !(selectedApi?.value || selectedApi[0]?.value) || !(title) || isChanges() || loading}
                  />
                  <div className='modal-adding-ready-scenario__loader-box'>
                   {loading && <MoonLoader
                      color="#7b7b7b"
                      speedMultiplier={0.5}
                      size={24}
                    />}
                    </div>
                </div>
              }

              <label className="modal-adding-ready-scenario__label">
                <span className='modal-adding-ready-scenario__label-placeholder'>{isCheck ? 'Активен' : 'Не активен'}</span>
                <CheckboxToggle
                  value={isCheck}
                  onChange={(e) => setIsCheck(!isCheck)}
                />
              </label>

              <button
                className="modal-adding-ready-scenario__btn-close"
                onClick={(e) => {
                  if (isEdit) {
                    if (isChanges()) {
                      onClose()
                    } else {
                      setIsModalExit(true)
                    }
                  } else {
                    onClose()
                  }
                }}
              >
                <img src={close_icon} />
              </button>
            </div>
          </div>



          <div className="modal-adding-ready-scenario__main-content">
            {(!isEdit && ruleId === -1) && <div className='modal-adding-ready-scenario__step-item'>
              <p className='modal-adding-ready-scenario__step-info'>
                <img src={info_circle} />
                Шаг 1: Заполните основную информацию</p>
              <p className='modal-adding-ready-scenario__step-description'>
                Заполните все обязательные поля, которые находятся ниже и нажмите на кнопку “Следующий шаг”.
                <span style={{ color: 'red' }}> Красными звездочками</span> выделены обязательные пункты для заполнения
              </p>
            </div>}

            <div className="modal-adding-ready-scenario__toolbar-top">
              <div className="filters-container">
                <DropDownSelector
                  required={true}
                  state={selectedMarketplace}
                  options_prop={mpData}
                  setState={(e) => setSelectedMarketplace(e)}
                  placeholder="Площадка"
                />
                <DropDownSelector
                  state={selectedApi}
                  options_prop={apiData}
                  setState={(e) => setSelectedApi(e)}
                  placeholder="Магазин"
                  fetchCallback={(e) => setApiPage(e)}
                  setInputChange={(e) => setApiQuery(e)}
                  required={true}
                />
                <DropDownSelector

                  state={selectedCategory}
                  options_prop={categoryData}
                  setState={(e) => setSelectedCategory(e)}
                  placeholder="Категория"
                  fetchCallback={(e) => setCategoryPage(e)}
                  setInputChange={(e) => setCategoryQuery(e)}
                  multi
                />
                <DropDownSelector

                  state={selectedBrand}
                  options_prop={brandData}
                  setState={(e) => setSelectedBrand(e)}
                  placeholder="Бренд"
                  fetchCallback={(e) => setBrandPage(e)}
                  setInputChange={(e) => setBrandQuery(e)}
                  multi
                />
              </div>
              <TextareaDynamicPlaceholder
                width="100%"
                changeValue={descriptionValue}
                placeholder="Описание"
                onChange={(value) => {
                  setDescription(value);
                }}
              />
            </div>

            {(!isEdit && ruleId === -1) && <ButtonBasic
              green
              size='40px'
              text='Следующий шаг'
              style={{
                minHeight: '40px'
              }}
              onClick={(e) => createRule()}
              disabled={!selectedMarketplace?.value || !selectedApi?.value || !(title)}
            />}

            {(isCreateRule && ruleId > 0) && <div className='modal-adding-ready-scenario__step-item'>
              <p className='modal-adding-ready-scenario__step-info'>
                <img src={info_circle} />
                Шаг 2: Выберите категории для которых хотите давать ответ</p>
            </div>}

            <div className="prepared_scenarios" ref={refContainer}
              style={{ maxHeight: isCreateCategory ? 'calc(100% - 180px)' : '' }}
            >
              <div className="prepared_scenarios__content">

                {
                  categoriesList.map(({ group_id, group_name, categories }, ind) => (
                    <div className='prepared_scenarios__group'>
                      <div className='prepared_scenarios__group-header'>
                        <img
                          onClick={(e) => {
                            const newState = new Set(isOpenItems);
                            if (isOpenItems.has(group_id)) {
                              newState.delete(group_id);
                            } else {
                              newState.add(group_id);
                            }
                            setIsOpenItems(newState)
                          }}
                          src={isOpenItems.has(group_id) ? drop_hide : drop} />
                        <h3
                          onClick={(e) => {
                            const newState = new Set(isOpenItems);
                            if (isOpenItems.has(group_id)) {
                              newState.delete(group_id);
                            } else {
                              newState.add(group_id);
                            }
                            setIsOpenItems(newState)
                          }}
                        >{group_name}</h3>
                      </div>

                      <div className={isOpenItems.has(group_id) ? 'prepared_scenarios__group-content' : 'prepared_scenarios__group-content_hide'}>
                        {(isCreateCategory == group_id) && (
                          <CategoryItem
                            isCreate={isCreate}
                            setIsCreate={(e) => setIsCreate(e)}
                            groupList={groupList}
                            groupsCallback={fetchGroupList}
                            isNew={true}
                            isModalNewNameScenario={isModalNewNameScenario}
                            setIsNewNameScenario={(e) => setIsNewNameScenario(e)}
                            isEditId={isEditId}
                            setIsEditId={(e) => setIsEditId(e)}
                            ruleId={ruleId}
                            isCreateCategory={isCreateCategory}
                            fetchRuleNew={fetchRuleNew}
                            setIsCreateCategory={(e) => setIsCreateCategory(e)}
                            setSelectedCategoryProperties={(e) => setSelectedCategoryProperties(e)}
                            setIsModalScenarioSettings={(e) => setIsModalScenarioSettings(e)}
                            setIsAddCategory={(e) => setIsAddCategory(e)}
                            headers={headers}
                            loading={loading} 
                            setLoading={(e) => setLoading(e)}
                          />
                        )}

                        <header className="prepared_scenarios__header">
                          <h2 className="title_category">Категория отзывов
                            <ButtonBasic
                              whiteGreen
                              onClick={(e) => {
                                setIsCreateCategory(group_id)
                                setIsNewNameScenario(true)
                              }}
                              text={<div className='prepared_scenarios__btn'><img src={plus_circle} />Добавить</div>}
                              width='auto'
                              size='22px'
                              style={{
                                minHeight: '22px',
                                padding: '0px 8px',
                                color: '#00B45E',
                                border: 'none'
                              }}
                            />
                          </h2>
                          <h2 className="title_col">Написание ответа</h2>
                          <h2 className="title_col">Выбор группы шаблонов</h2>
                          <h2 className="title_col">Вариант выбора шаблона</h2>
                          <h2 className="title_response-delay">Задержка ответа</h2>
                          <h2 className="title_col">Отправка на модерацию</h2>
                        </header>

                        {(categories?.length > 0 || (isCreateCategory == group_id)) ? categories.map((el, ind) => {
                          return <CategoryItem
                            {...el}
                            isCreate={isCreate}
                            setIsCreate={(e) => setIsCreate(e)}
                            groupList={groupList}
                            groupsCallback={fetchGroupList}
                            isModalNewNameScenario={isModalNewNameScenario}
                            setIsNewNameScenario={(e) => setIsNewNameScenario(e)}
                            isEditId={isEditId}
                            setIsEditId={(e) => setIsEditId(e)}
                            ruleId={ruleId}
                            isCreateCategory={isCreateCategory}
                            fetchRuleNew={fetchRuleNew}
                            setIsCreateCategory={(e) => setIsCreateCategory(e)}
                            setSelectedCategoryProperties={(e) => setSelectedCategoryProperties(e)}
                            setIsModalScenarioSettings={(e) => setIsModalScenarioSettings(e)}
                            setIsAddCategory={(e) => setIsAddCategory(e)}
                            headers={headers}
                            loading={loading} 
                            setLoading={(e) => setLoading(e)}
                          />
                        })
                          :
                          <div className='prepared_scenarios__no-group'>
                            <p className='prepared_scenarios__no-group-title'>Нет категорий в группе</p>
                            <p className='prepared_scenarios__no-group-description'>Создайте новую категорию в этой группе</p>
                            <ButtonBasic
                              green
                              onClick={(e) => {
                                setIsCreateCategory(group_id)
                                setIsNewNameScenario(true)
                              }}
                              text='Создать категорию'
                              width='auto'
                            />
                          </div>
                        }
                      </div>
                    </div>

                  ))}
              </div>
            </div>
          </div>
        </div>
      </div >
    </div>
  );
};

export { ModalAddingReadyScenario };
