import customHeaders, { getSpace } from '../../common/headers';
import icon_tab from './img/icon_tab.svg'
import { ModalLoader } from '../Modal/ModalLoader/ModalLoader';
import { SliderRange } from '../Slider/Slider';
import { useChartData, useDataTable } from '../../common/hooks';
import { ModalWithTable } from '../Modal/ModalWithTable/ModalWithTable';
import { TooltipLightBlue } from '../Tooltip/TooltipLightBlue/TooltipLightBlue';
import { useParams, useSearchParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { AndreySkuHttps, AndreySkuHttp } from '../../fetchUrls';
import { TableBox, Tabs, RangeBox, SearchGlobalInput, CheckboxToggleBlue, Checkbox, DropContainer, LineChart } from "@lk-gtcom/ecomlab-components";
import useGeneralStore from '../../store/general';
import "./AnalysisByBrand.scss"

const btn_tab = [
    {
        label: 'Запросы',
        value: 'queries',
        icon: icon_tab,
    },
    // {
    //     label: 'Товары',
    //     value: 'goods',
    //     icon: icon_tab
    // },
    // {
    //     label: 'Категории',
    //     value: 'category',
    //     icon: icon_tab
    // },
    // {
    //     label: 'Продавцы',
    //     value: 'sellers',
    //     icon: icon_tab
    // },
    // {
    //     label: 'Ценовые сегменты',
    //     value: 'price_segments',
    //     icon: icon_tab
    // },
    {
        label: 'По датам',
        value: 'days',
        icon: icon_tab
    }
]

const title_list = [
    {
        label: 'По бренду',
        path: `by-brand`
    },
    {
        label: 'По брендовому запросу',
        path: `by_brand_request`
    }
]


const AnalysisByBrand = () => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }

    const activeTab = useParams()['*']
    const [searchParams, setSearchParams] = useSearchParams()

    const [pageValue, setPageValue] = useState(btn_tab[0].value)
    const [modalLoader, setModalLoader] = useState(false)
    const [dynamic, setDynamic] = useState(0)
    const [date, setDate] = useState([new Date().getTime() - 604800000, new Date().getTime()])

    const [search, setSearch] = useState('')
    const [searchValue, setSearchValue] = useState('')
    const [searchData, setSearchData] = useState([])


    const [tableUrl, setTableUrl] = useState('')
    const [tableBody, setTableBody] = useState(null)

    const [minValue, setMinValue] = useState(0)
    const [maxValue, setMaxValue] = useState(20000)
    const [price, setPrice] = useState([minValue, maxValue])
    const [segments, setSegments] = useState(0)

    const paginatorRef = useRef()
    const [setTable, tableProps, setTableFuncs] = useDataTable(paginatorRef.current?.setTotal, paginatorRef)
    const { loading, sort, filters } = tableProps
    const { setLoading, setFetchedData, setHeaders, setSort, setFilters } = setTableFuncs
    const [checkFbs, setCheckFbs] = useState(false)

    const [selectedItems, setSelectedItems] = useState([])

    const [currentTitle, setCurrentTitle] = useState(title_list[0])
    const [queryNameFilter, setQueryNameFilter] = useState('')
    const [actionColumn, setActionColumn] = useState('')
    const [isModalAction, setIsModalAction] = useState(false)
    const [isReverse, setIsReverse] = useState(false)


    const [tableMetrics, setTableMetrics] = useState([])
    const [tableMetric, setTableMetric] = useState('IDC')
    const [showDropBox, setShowDropBox] = useState(false)

    const [setChartData, chartData, setChartFuncs] = useChartData()
    const { setChartLoading, resetChart } = setChartFuncs

    const onHandleSliderChange = (minMax) => {
        setPrice(minMax)
    }

    const resetTable = () => {
        paginatorRef.current.reset()
        paginatorRef.current.setTotal(0)
        // setCheckedProducts([])
        setFetchedData([])
        setHeaders([])
        setSort({})
        setFilters([])
    }

    const getModalTableData = () => {
        const pathUrl = currentTitle?.path === title_list?.[0]?.path ? 'brands' : title_list?.[1]?.path
        const _url = `${AndreySkuHttps}/api/v1/ecomru/keywords/${pathUrl}/${actionColumn}/table/`
        let [date_from, date_to] = date

        date_from = new Date(date_from + 10)?.toISOString().split('T')?.[0]
        date_to = new Date(date_to)?.toISOString().split('T')?.[0]

        const brandData = pathUrl === 'brands' ? { brand_id_filter: [search] } : { query_name_filter: [search] }

        const body = {
            fbofbs_filter: checkFbs,
            date_from,
            date_to,
            ...brandData,
            query_name_filter: [queryNameFilter]
        }

        return { _url, body }
    }

    const fetchTableData = (params, abortController = new AbortController()) => {
        if (!!pageValue & search?.length >= 3) {
            let sort
            let filtersParam

            if (params) {
                [sort, filtersParam] = params
            }

            setLoading(true)
            const pathUrl = currentTitle?.path === title_list?.[0]?.path ? 'brands' : title_list?.[1]?.path

            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${AndreySkuHttps}/api/v1/ecomru/keywords/${pathUrl}/${pageValue}/table/`
            } else {
                url = `${AndreySkuHttps}/api/v1/ecomru/keywords/${pathUrl}/${pageValue}/table/`
            }

            let [date_from, date_to] = date;
            date_from = new Date(date_from + 86401000)
                ?.toISOString()
                ?.split('T')?.[0];
            date_to = new Date(date_to)?.toISOString()?.split('T')?.[0];

            const priceData = pageValue == 'price_segments'
                ?
                {
                    max_price: price[1],
                    min_price: price[0],
                    number_of_segments: +segments
                }
                : {}

            const brandData = pathUrl === 'brands' ? { brand_id_filter: [search] } : { brand_name_filter: [search] }
            const metricName = pageValue === 'days' ? { metric_name: tableMetric } : {}

            const body = JSON.stringify({
                limit: paginatorRef.current.limit,
                page: paginatorRef.current.page,
                fbofbs_filter: checkFbs,
                date_from,
                date_to,
                sort_name: sort ? sort[0] : 'Сред. запросов в неделю',
                sort_order: sort ? sort[1] : 'DESC',
                filters: filtersParam,
                reverse: isReverse,
                ...metricName,
                ...brandData,
                ...priceData
            })


            fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        throw Error(JSON.stringify(res.json()))
                    }
                })
                .then(json => {
                    setTable(json)
                    setTableUrl(url)
                    setTableBody(body)
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    if (abortController.signal.aborted) return
                    setLoading(false)
                })
        }
    }

    const fetchChartData = (abortController = new AbortController()) => {
        if (search?.length > 0) {
            setLoading(true)

            const pageTitle = currentTitle?.path === title_list?.[0]?.path ? 'brands' : `by_brand_request`

            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${AndreySkuHttps}/api/v1/ecomru/keywords/${pageTitle}/${pageValue}/graph/`
            } else {
                url = `${AndreySkuHttps}/api/v1/ecomru/keywords/${pageTitle}/${pageValue}/graph/`
            }

            let searchId;
            let [date_from, date_to] = date;
            date_from = new Date(date_from + 86401000)
                ?.toISOString()
                ?.split('T')?.[0];
            date_to = new Date(date_to)?.toISOString()?.split('T')?.[0];

            const id_arr = selectedItems?.map(el => {
                if (el) {
                    if (pageValue === 'queries' && el?.['Поисковой запрос']) {
                        if (Array.isArray(el?.['Поисковой запрос'])) {
                            const [img, name] = el?.['Поисковой запрос']
                            return el?.['Поисковой запрос'][0]
                        }
                    }

                    if (pageValue === 'days' && el?.['Поисковый запрос']) {
                        if (Array.isArray(el?.['Поисковый запрос'])) {
                            const [img, name] = el?.['Поисковый запрос']
                            return el?.['Поисковый запрос'][0]
                        }
                    }
                }
            })
            searchId = { query: id_arr }

            let brandBody = { query: [search] }
            const metricName = { metric_name: tableMetric }
            const brand_id = currentTitle?.path === 'by-brand' ? { brand_id_filter: [search] } : ''


            const body = JSON.stringify({
                ...brandBody,
                date_from,
                date_to,
                fbofbs_filter: checkFbs,
                // page: paginatorRef.current.page,
                // limit: paginatorRef.current.limit,
                ...metricName,
                //  initial: !(chartData?.selectedLegend?.length > 0),
                metrics: chartData?.selectedLegend,
                metric_name: tableMetric,
                ...brand_id,
                ...searchId
            })

            fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        throw Error(JSON.stringify(res.json()))
                    }
                })
                .then(json => {
                    setChartData(json)
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    // if (abortController.signal.aborted) return
                    setLoading(false)
                })
        }
    }

    const fetchSearchValue = ([search, page = 1], abortController = new AbortController()) => {
        if (search?.length >= 3) {
            setLoading(true)

            const pathUrl = currentTitle?.path === title_list?.[0]?.path ? 'brands' : 'by_brand_request'

            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${AndreySkuHttps}/api/v1/ecomru/keywords/${pathUrl}/search_string/`
            } else {
                url = `${AndreySkuHttp}/api/v1/ecomru/keywords/${pathUrl}/search_string/`
            }

            // let [date_from, date_to] = date

            // date_from = new Date(date_from+86400000)?.toISOString().split('T')?.[0]
            // date_to = new Date(date_to)?.toISOString().split('T')?.[0]

            const body = JSON.stringify({
                page,
                limit: 15,
                search_text: search
            })


            fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        throw Error(JSON.stringify(res.json()))
                    }
                })
                .then(json => {
                    let result = []
                    if (json?.Brand) {
                        result = json?.Brand.map(({ brand, icon, extra }, ind) => {
                            return ({ brand, extra, icon })
                        }
                        )
                    } else {
                        result = json?.Brand_request.map(({ request_name }, ind) => {
                            return ({ brand: request_name, extra: request_name })
                        }
                        )
                    }
                    setSearchData({ brand: result });
                    // setSearchData({brand: Object?.values(json)?.[0]})
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    setLoading(false)
                    if (abortController.signal.aborted) return

                })
        }
    }

    const fetchMinMaxPrice = () => {
        if (search.length >= 3) {

            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${AndreySkuHttps}/api/v1/ecomru/keywords/brands/price_range/`
            } else {
                url = `${AndreySkuHttps}/api/v1/ecomru/keywords/brands/price_range/`
            }

            let [date_from, date_to] = date
            date_from = new Date(date_from + 10)?.toISOString().split('T')?.[0]
            date_to = new Date(date_to)?.toISOString().split('T')?.[0]

            const body = JSON.stringify({
                brands_name: [search],
                fbofbs_filter: checkFbs,
                date_from,
                date_to,
            })

            fetch(url, { body, method: 'POST', headers })
                .then(async res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    const { min_price, max_price } = json
                    setMinValue(min_price)
                    setMaxValue(max_price)
                    setPrice([min_price, max_price])
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    const onActionHandler = (el) => {
        const [dataObj, targetData, targetColumn] = el
        const query_name_filter = dataObj?.['Поисковой запрос']
        setQueryNameFilter(String(query_name_filter)?.replace(',', ''))

        if (targetColumn === 'Подкатегорий, шт') {
            setActionColumn('category')
        }

        if (targetColumn === 'Товаров, шт') {
            setActionColumn('goods')
        }

        if (targetColumn === 'Продавцов, шт') {
            setActionColumn('sellers')
        }

        setIsModalAction(true)
    }

    const fetchMetricsList = () => {
        if (pageValue === 'days' || pageValue === 'queries') {
            let url
            const pathUrl = currentTitle?.path === title_list?.[0]?.path ? 'brands' : title_list?.[1]?.path

            if (!window.location.hostname.match('localhost')) {
                url = `${AndreySkuHttps}/api/v1/ecomru/keywords/${pathUrl}/${pageValue}/metric_list/`
            } else {
                url = `${AndreySkuHttps}/api/v1/ecomru/keywords/${pathUrl}/${pageValue}/metric_list/`
            }


            fetch(url, { method: 'GET', headers })
                .then(async res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    setTableMetrics(json);
                    setTableMetric(json[0]?.value)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    const handleCheck = (data) => {
        setSelectedItems(data)
    }

    useEffect(() => {
        if (pageValue === 'days' || pageValue === 'queries') {
            fetchMetricsList()
        } else {
            setTableMetrics([])
            setTableMetric(-1)
        }
    }, [pageValue, currentTitle, selectedSpace])

    useEffect(() => {
        setDynamic(100)
    }, []);

    useEffect(() => {
        setPageValue(activeTab)
        const query = searchParams.get('query')

        if (query) {
            setSearch(query)
            setSearchValue(query)
        }
    }, [activeTab])

    useEffect(() => {
        resetTable()
        const abortController = new AbortController()

        let defaultSort
        if (!sort?.length > 0) {
            defaultSort = ['Сред. запросов в неделю', 'DESC']
            setSort(defaultSort)
        } else {
            defaultSort = sort
        }

        if (pageValue !== 'price_segments') {
            fetchTableData([defaultSort, filters], abortController)
        }

        if (pageValue === 'price_segments') {
            fetchMinMaxPrice()
        }

        return () => abortController.abort()
    }, [selectedSpace, pageValue, date, search, currentTitle, checkFbs, isReverse, tableMetric])

    useEffect(() => {
        const abortController = new AbortController()

        if (selectedItems?.length > 0 && search?.length > 0) {
            fetchChartData(abortController)
        }

        // return () => abortController.abort()
    }, [selectedSpace, chartData?.selectedLegend, selectedItems, date, pageValue, search, checkFbs, tableMetric])

    useEffect(() => {
        resetChart()
    }, [pageValue, currentTitle])

    return (
        <>
            {modalLoader &&
                <ModalLoader
                    setIsModal={(e) => setModalLoader(e)}
                    dynamic={dynamic}
                    title='Формируется отчет'
                    text='Не закрывайте страницу, загрузка может занять до 2 минут'
                />}

            {
                isModalAction &&
                <ModalWithTable
                    {...getModalTableData()}
                    setIsModal={setIsModalAction}
                />
            }

            <div className='analysis-by-brand'>
                <div className='content-1320'>

                    <div className='tabs-title'>
                        {title_list.map((el) =>
                            <h1 className={currentTitle.label == el.label ? 'title_active' : 'title'}
                                key={el.label}
                                onClick={() => {
                                    setCurrentTitle(el)
                                }}
                            >{el.label}</h1>
                        )}
                    </div>

                    <div className='tooltip-top'>
                        <div className='tooltip-top'>
                            <SearchGlobalInput
                                {...searchData}
                                fetchSearch={e => {
                                    const abortController = new AbortController()
                                    fetchSearchValue(e, abortController)
                                    return () => abortController.abort()
                                }}
                                setSearch={({ extra }) => setSearch(extra)}
                                value={searchValue}
                                setSearchData={e => setSearchData(e)}
                                onClearDependence={[currentTitle]}
                            />
                        </div>
                    </div>

                    <div className='toolbar-medium'>
                        <Tabs tabs={btn_tab} disabled={loading} pageValue={pageValue} />
                        <div className='range-group-btn-and-range-box'>
                            <TooltipLightBlue
                                text={<p className='text_tooltip'>FBO <span>(продажи со склада площадки)</span> по умолчанию.
                                    <br /><br />
                                    Нажмите на переключатель чтобы увидеть FBS <span>(продажи со склада продавца)</span>
                                </p>}
                                top='32px'
                                left='0px'
                                maxWidth='110px'
                                child={
                                    <label className='checkbox-container'>
                                        <CheckboxToggleBlue
                                            value={checkFbs}
                                            onChange={(e) => setCheckFbs(!checkFbs)}
                                        />
                                        <p className='text_label'>FBS</p>
                                    </label>
                                }
                            />

                            <RangeBox setDate={e => setDate(e)} />
                        </div>
                    </div>

                    {pageValue == 'price_segments' && <SliderRange
                        minValue={minValue} maxValue={maxValue}
                        setSegments={e => setSegments(e)}
                        setState={e => onHandleSliderChange(e)}
                        callbackHandler={e => {
                            fetchTableData()
                            //    fetchChartData()
                        }}
                    />}
                </div>

                <DropContainer
                    showDropBox={showDropBox}
                    setShowDropBox={(e) => setShowDropBox(e)}
                    selectedMetric={tableMetric}
                    setSelectedMetric={setTableMetric}
                    metricsList={tableMetrics}
                >
                    <LineChart
                        {...chartData}
                    />

                </DropContainer >

                <TableBox
                    onActionInfo
                    {...tableProps}
                    newTable={true}
                    paginator={true}
                    ref={paginatorRef}
                    onAction={onActionHandler}
                    fetchCallback={e => fetchTableData(e)}
                    onCheck={e => handleCheck(e)}
                    onUncheckAllDependence={[pageValue, search, currentTitle]}
                    tableUrl={tableUrl}
                    tableBody={tableBody}
                    child={
                        pageValue === 'days' &&
                        < Checkbox
                            value={isReverse}
                            onChange={checked => setIsReverse(checked)}>
                            Перевернуть
                        </Checkbox>
                    }
                />

            </div>
        </>

    )
}

export { AnalysisByBrand }