import customHeaders, { getSpace } from '../../../common/headers';
import { useState, useEffect } from 'react';
import { ButtonBasic } from '@lk-gtcom/ecomlab-components';
import { InputDinamycPlaceholder } from '@lk-gtcom/ecomlab-components';

import './ModalAddMpSimple.scss';
import useGeneralStore from '../../../store/general';
import { KonstantinCreateProductHttps } from '../../../fetchUrls';

const ModalAddMpSimple = ({ setIsModal }) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [name, setName] = useState('');
  const [offerId, setOfferId] = useState('');

  const [nameError, setNameError] = useState('');
  const [offerIdError, setOfferIdError] = useState('');

  const offerIdHint =
    'Разрешены английские и русские буквы (кроме ё), цифры, и символы .,/()[]-=_ (без пробелов). Максимальная длина — 50 знаков';

  const onInputChange = (setError, set) => {
    setError('');
    set();
  };

  const fetchTableData = () => {
    const url = `${KonstantinCreateProductHttps}/master_products/simple`;

    const body = JSON.stringify({
      offer_id: offerId,
      name: name,
    });

    fetch(url, {
      method: 'POST',
      headers,
      body,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        console.log(json);
      })
      .catch((e) => console.error(e))
      .finally(() => {});
  };

  return (
    <div
      className="blackout"
      onClick={(e) => {
        let el = e.target;
        if (el.classList.contains('blackout')) {
          setIsModal(false);
        }
      }}
    >
      <div className="modal-add-product">
        <h2 className="title">Добавление товара</h2>
        <InputDinamycPlaceholder
          classname="autorization__input-field"
          placeholder="Название *"
          maxLength={150}
          onChange={(e) => onInputChange(setNameError, () => setName(e))}
          type="text"
          textErr={nameError}
          err={!!nameError}
          autoComplete={false}
          required
          size={'small'}
        />
        <InputDinamycPlaceholder
          classname="autorization__input-field"
          placeholder="Артикул *"
          onChange={(e) => onInputChange(setOfferIdError, () => setOfferId(e))}
          type="text"
          maxLength={50}
          textErr={offerIdError}
          err={!!offerIdError}
          infoHeader="Артикул товара в магазине (нельзя изменить после создания)"
          info={offerIdHint}
          autoComplete={false}
          required
        />
        <ButtonBasic
          violet
          size="56px"
          text={'Создать'}
          onClick={(e) => {
            fetchTableData();
            setName('');
            setOfferId('');
            setIsModal(false);
          }}
        />
      </div>
    </div>
  );
};

export { ModalAddMpSimple };
