import { Link } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';
import { DynamicElement } from '../DynamicElement/DynamicElement';
import rating from './img/rating.svg'
import './CardMetricVersion2.scss'

const CardMetricVersion2 = ({ title, value, loading, units }) => {

    return (
        <div className='сard-metrics-ver-2' key={title + value}>
            {
                loading ?
                    <div className='center' style={{ width: '100%', height: '86px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <MoonLoader
                            size={20}
                            color="#7b7b7b"
                            speedMultiplier={0.5}
                        />
                    </div>
                    :
                    <>
                        <h3 className='сard-metrics-ver-2__title'>{title ? title : '-'}</h3>
                        <div className='сard-metrics-ver-2__data'>
                            <p className='сard-metrics-ver-2__value'>
                                <img src={rating} />
                                {value[0] ? value[0]?.toLocaleString() : '-'} <span>{units ? units : ''}</span></p>
                            <DynamicElement
                                data={value[2]}
                            />
                        </div>
                        <p className='сard-metrics-ver-2__data-prev'>{value[1] ? value[1]?.toLocaleString() : ''} за прошлый период</p>
                    </>
            }
        </div>
    )
}

export { CardMetricVersion2 }