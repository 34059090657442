import { useState, useEffect } from "react"
import { useChartData } from "../../../common/hooks";
import headers from "../../../common/headers";
import { DoughnutChart } from "../../DoughnutChart/DoughnutChart";
import "./WrapperDoughnutChart.scss"

const WrapperDoughnutChart = ({
    url,
    body,
    nameChart = ''
}) => {

    const [chartData, setChartData] = useState([])
    const [loading, setLoading] = useState(false)

    const fetchChartData = (abortController = new AbortController()) => {
        setLoading(true)

        fetch(url, {
            body: body,
            method: 'POST',
            headers,
            signal: abortController.signal,
        })
            .then(async (res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    const err = await res.json();
                    throw new Error(JSON.stringify(err));
                }
            })
            .then((json) => {
                setChartData(json);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false)
                if (abortController.signal.aborted) {
                    return;
                }
            });
    };

    useEffect(() => {
        fetchChartData()
    }, [body]);

    return (
        <DoughnutChart
            loading={loading}
            nameChart={nameChart}
            datasets_prop={chartData?.datasets?.data[0]}
            labels_prop={chartData?.datasets?.label}
        />
    )
}

export { WrapperDoughnutChart }