import add_icon from './img/add_icon.svg';
import customHeaders, { getSpace } from '../../../common/headers';
import { OlegRulesHttps, RavshanHttps } from '../../../fetchUrls';
import { useEffect, useState } from 'react';
import {
  DropDownSelector,
  ButtonBasic,
  InputDinamycPlaceholder,
  CheckboxToggle,
  Checkbox,
} from '@lk-gtcom/ecomlab-components';
import settings_icon from './img/settings.svg';
import close_icon from './img/close.svg';
import useGeneralStore from '../../../store/general';
import './ModalRules.scss';

// Карточка
// status == 'Активно'/ 'Не активно'
const CardDrop = ({ ...props }) => {
  const {
    id,
    name,
    status = false,
    onDragStart,
    onDragOver,
    onStrategieToggle,
    onGetParams,
    active,
  } = props;
  const currentItem = active?.find((item) => item.id === id);
  const hasAttributes =
    Array.isArray(currentItem?.attributes) && currentItem.attributes.length > 0;

  return (
    <div
      className="col-item__card"
      draggable={true}
      onDragStart={(e) => onDragStart(props)}
      onDragOver={(e) => onDragOver(e)}
    >
      <p className="text">{name}</p>
      <div
        className="status-content"
        style={{
          justifyContent: status && hasAttributes ? 'space-between' : 'end',
        }}
      >
        {status && hasAttributes && (
          <button
            className="col-item__btn_settings"
            onClick={(e) => {
              e.stopPropagation();
              onGetParams(id, name, status);
            }}
          >
            <img src={settings_icon} />
          </button>
        )}
        <Checkbox
          green
          value={status}
          onChange={(e) => {
            if (onStrategieToggle) {
              onStrategieToggle({ ...props, status: e });
            }
          }}
        />
        {/* <div className={`status ${statusCard(status)}`}>{status}</div> */}
      </div>
    </div>
  );
};

// Колонка
const ColItemDrop = ({
  title = '',
  arr = [],
  onDragStart,
  onDragOver,
  onDrop,
  loading = false,
  onStrategieToggle,
  onGetParams,
  active,
}) => {
  return (
    <div className="col-item">
      <div className="col-item__header">
        <h3 className="title">
          {title} <span>{arr?.length ? arr.length : 0}</span>
        </h3>
      </div>
      <div
        className="col-item__list"
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => onDrop(e)}
        draggable={true}
      >
        {!loading
          ? arr?.map((props, ind) => {
              // Карточка
              const { id } = props;
              return (
                <CardDrop
                  key={id}
                  {...props}
                  onDragStart={(e) => onDragStart(e)}
                  onDragOver={(e) => onDragOver(e)}
                  onStrategieToggle={(e) => onStrategieToggle(e)}
                  onGetParams={(e) => onGetParams(e)}
                  active={active}
                />
              );
            })
          : 'Loading...'}
        {arr?.length < 1 && (
          <div className="no-list">
            <img src={add_icon} />
            <p className="text">Добавьте правило</p>
          </div>
        )}
      </div>
    </div>
  );
};

const FiltersWrapper = ({
  id,
  type,
  placeholder,
  selectedData,
  onFilterChange,
  service_id,
}) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [localSelectedData, setLocalSelectedData] = useState();
  const [optionsData, setOptionsData] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const fetchFilters = (filter_id) => {
    const url = `${OlegRulesHttps}/api/v1/${service_id}/filter`;

    // const filters = Object?.entries(selectedData)?.map(([key, value]) => ({
    //   id: key,
    //   data: value.length ? value?.map((item) => item.value) : ['all'],
    // }));

    // const filters = [
    //   {
    //     id: 1,
    //     data: selectedData[1]?.length
    //       ? selectedData[1].map((item) => item.value)
    //       : ['all'],
    //   },
    //   {
    //     id: 2,
    //     data: selectedData[2]?.length
    //       ? selectedData[2].map((item) => item.value)
    //       : ['all'],
    //   },
    //   {
    //     id: 3,
    //     data: selectedData[3]?.length
    //       ? selectedData[3].map((item) => item.value)
    //       : ['all'],
    //   },
    //   {
    //     id: 4,
    //     data: selectedData[4]?.length
    //       ? selectedData[4].map((item) => item.value)
    //       : ['all'],
    //   },
    //   // {
    //   //   id: 5,
    //   //   data: selectedData[5]?.length
    //   //     ? selectedData[5].map((item) => item.value)
    //   //     : ['all'],
    //   // },
    //   // {
    //   //   id: 6,
    //   //   data: selectedData[6],
    //   // },
    // ];

    const filters = Object.keys(selectedData).map((key) => {
      const value = selectedData[key];
      const data =
        Array.isArray(value) && value.length > 0
          ? value.map((item) => item.value)
          : ['all'];

      return {
        id: Number(key),
        data: data,
      };
    });

    let filtersWithoutLast = filters.slice(0, -1);

    const body = JSON.stringify({
      id: filter_id,
      page: page,
      limit: 50,
      filters: filtersWithoutLast,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        const transformedData = json.map((item) => ({
          label: item.name,
          value: item.id,
        }));
        setOptionsData(transformedData);
      })
      .catch((err) => console.error(err))
      .finally(() => {});
  };

  const [initial, setInitial] = useState(true);

  useEffect(() => {
    setInitial(false);
  }, []);

  useEffect(() => {
    if (!initial) {
      fetchFilters(id);
    }
  }, [id, page, search, selectedData]);

  useEffect(() => {
    if (selectedData[id] !== localSelectedData) {
      setLocalSelectedData(selectedData[id]);
    }
  }, [selectedData, id]);

  const handleSetSelectedData = (data) => {
    setLocalSelectedData(data);
    onFilterChange(id, data);
  };

  const handleSetPage = (newPage) => {
    setPage(newPage);
  };

  const handleSetSearch = (newSearch) => {
    setSearch(newSearch);
  };

  return (
    <>
      {type === 'string' ? (
        <InputDinamycPlaceholder
          onChange={handleSetSelectedData}
          textErr={'Неверное название правила'}
          placeholder={placeholder}
          style={{ maxWidth: '500px', minWidth: '500px' }}
          changeValue={selectedData[id]}
        />
      ) : (
        type.includes('array') && (
          <DropDownSelector
            style={{ maxWidth: '210px' }}
            options_prop={optionsData}
            state={localSelectedData}
            setState={handleSetSelectedData}
            className="connections_page_selector"
            placeholder={placeholder}
            fetchCallback={handleSetPage}
            setInputChange={handleSetSearch}
            multi={true}
          />
        )
      )}
    </>
  );
};

export default FiltersWrapper;

// Модальное окно
const ModalRules = ({ ...props }) => {
  const {
    setIsModal,
    status,
    rule_id,
    service_id,
    isEdit,
    setIsEdit,
    isCreate,
    setRuleInfo,
    name,
    ruleName,
    handleSaveSuccess,
    setRuleIdPar,
  } = props;
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [edit, setEdit] = useState(isEdit ? isEdit : false);
  const [create, setCreate] = useState(isCreate ? isCreate : false);

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);
  const [dateFrom, dateTo] = date;
  const [currentCard, setCurrentCard] = useState(null);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState([]);
  const [disactive, setDisactive] = useState([]);
  const [isModalParametr, setIsModalParametr] = useState(false);
  const [ruleId, setRuleId] = useState(rule_id ? rule_id : -1);

  const [strategyInfo, setStrategyInfo] = useState(status ? { status } : {});
  const [strategyParams, setStrategyParams] = useState([]);
  const [strategyParamValue, setStrategyParamValue] = useState([]);
  const [filterData, setFilterData] = useState(null);

  const [filterValues, setFilterValues] = useState([]);

  useEffect(() => {
    if (isCreate) {
      setRuleIdPar(1);
    }
    return () => {
      setRuleIdPar(1);
    };
  }, []);

  const handleFilterChange = (id, data) => {
    setFilterValues((prevValues) => ({
      ...prevValues,
      [id]: data,
    }));
  };

  const fetchRuleModel = (service_id) => {
    const url = `${OlegRulesHttps}/api/v1/${service_id}/rule/model`;

    fetch(url, {
      method: 'GET',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        console.log('Rule model:', json);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        console.log('Fetch rule model completed');
      });
  };

  const fetchRuleAttrsModel = (service_id, rule_id) => {
    const url = `${OlegRulesHttps}/api/v1/${service_id}/rule_attrs/model/${service_id}`;

    fetch(url, {
      method: 'GET',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setFilterData(json);
      })
      .catch((err) => console.error(err))
      .finally(() => {});
  };

  useEffect(() => {
    fetchRuleModel(service_id);
    fetchRuleAttrsModel(service_id, rule_id);
  }, []);

  const onCreate = () => {
    const url = `${OlegRulesHttps}/api/v1/${service_id}/rule`;

    const id = edit ? { id: ruleId } : { rule_id };

    const attributes = filterData.map((attr) => {
      const values = filterValues[attr.id] || [];
      let valueData, labelData;

      if (attr.type.includes('array')) {
        if (attr.type === 'array[integer]') {
          valueData = Array.isArray(values)
            ? values.map((item) => parseInt(item.value, 10))
            : [];
          labelData = Array.isArray(values)
            ? values.map((item) => item.label)
            : [];
        } else {
          valueData = Array.isArray(values)
            ? values.map((item) => item.value)
            : [];
          labelData = Array.isArray(values)
            ? values.map((item) => item.label)
            : [];
        }
      } else {
        valueData = values;
        labelData = values;
      }

      return {
        attr_id: attr.id,
        value: {
          type: attr.type,
          value: valueData,
          label: labelData,
        },
      };
    });

    const body = JSON.stringify({
      ...id,
      is_active: true,
      attributes: attributes,
    });

    fetch(url, {
      body,
      method: edit ? 'PUT' : 'POST',
      headers: headers,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((err) => {
            throw new Error(JSON.stringify(err));
          });
        }
      })
      .then(({ id }) => {
        setCreate(false);
        setRuleId(id);
        setIsEdit(true);
        setEdit(true);
        handleSaveSuccess();
      })
      .catch((err) => console.error('Error:', err))
      .finally(() => {
        setLoading(false);
      });
  };

  const onDeleteStrategy = (id) => {
    const url = `${OlegRulesHttps}/api/v1/${service_id}/strategy`;

    const body = JSON.stringify({
      id,
    });

    fetch(url, {
      body,
      method: 'DELETE',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then(() => {
        fetchStrategies();
        fetchClientStrategies();
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const onChangeStrategy = ({ status, id }) => {
    const url = `${OlegRulesHttps}/api/v1/${1}/strategy`;

    const param = Object.keys(strategyParamValue).map((id) => ({
      attr_id: id,
      value: strategyParamValue[id],
    }));

    const statusCheckBody = status
      ? { strategy_id: id, client_rule_id: rule_id, is_active: true }
      : { id };

    const body = JSON.stringify({
      ...statusCheckBody,
      attributes: param,
    });

    fetch(url, {
      body,
      method: status ? 'POST' : 'PUT',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setStrategyInfo({});
        setStrategyParamValue({});
        fetchStrategies();
        fetchClientStrategies();
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchStrategies = () => {
    const url = `${OlegRulesHttps}/api/v1/${service_id}/strategy/model/${rule_id}/list`;

    fetch(url, {
      method: 'GET',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setDisactive(json?.map((el) => ({ ...el, status: false })));
        // setActive(active?.map(el => ({ ...el, status: true })))
        // setDisactive(disactive?.map(el => ({ ...el, status: false })))
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchClientStrategies = () => {
    const url = `${OlegRulesHttps}/api/v1/${service_id}/strategy/client/${rule_id}/list`;

    fetch(url, {
      method: 'GET',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setActive(json?.map((el) => ({ ...el, status: true })));
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const onGetParams = async (id, name, status) => {
    setStrategyParams([]);
    setStrategyParamValue([]);

    let url;
    if (status) {
      url = `${OlegRulesHttps}/api/v1/${service_id}/strategy_attrs/${
        status ? 'model' : 'client'
      }/${id}`;
    } else {
      url = `${OlegRulesHttps}/api/v1/${service_id}/strategy_attrs/${
        status ? 'model' : 'client'
      }/${id}`;
    }

    await fetch(url, {
      method: 'GET',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        if (json?.length > 0) {
          setStrategyInfo({ name, status, id });
          setStrategyParams(json);
        } else {
          setStrategyInfo({
            name: 'Для этой стратегии нет параметров, она будет применена для всех товаров',
            status,
            id,
          });
        }
        setIsModalParametr(true);
      })
      .catch((err) => {
        setStrategyParams([]);
        setStrategyInfo({
          name: 'Для этой стратегии нет параметров, она будет применена для всех товаров',
          status,
          id,
        });
        setIsModalParametr(true);
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onGetRuleParams = () => {
    const url = `${OlegRulesHttps}/api/v1/${service_id}/rule_attrs/client/${ruleId}`;

    fetch(url, {
      method: 'GET',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        console.log(json);
        const newFilterValues = json.reduce((acc, attr) => {
          if (attr.value.type.includes('array')) {
            acc[attr.attr_id] = attr.value.value.map((value, index) => ({
              label: attr.value.label[index],
              value: value,
            }));
          } else {
            acc[attr.attr_id] = attr.value.value;
          }
          return acc;
        }, {});
        setTimeout(() => {
          setFilterValues(newFilterValues);
        }, [500]);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getClientParams = async (id, name, status) => {
    await onGetParams(id, name, status);
    // onGetStrategyParams(id, name, status)
  };

  const onReset = () => {
    setRuleInfo({});
    setStrategyInfo({});
    setStrategyParamValue({});
    setStrategyParams([]);
    // setRuleId();
    setFilterValues([]);
  };

  const onStrategieToggle = (props) => {
    const { id, name, status, param } = props;

    if (!status) {
      onDeleteStrategy(id);
    } else {
      setStrategyInfo({ name, status, id });
      onGetParams(id, name, status);
    }
  };

  const dragStartHandler = (e, card) => {
    // Перетаскиваемая карточка
    setCurrentCard(e);
  };

  const dragOverHandler = (e, card) => {
    e.preventDefault();
  };

  const dropHandler = (e, status) => {
    e.preventDefault();
    if (!currentCard) return;
    onStrategieToggle({ ...currentCard, status });
  };

  useEffect(() => {
    if (!edit) {
      onReset();
    }
  }, [selectedSpace]);

  useEffect(() => {
    // if (edit) {
    onGetRuleParams();
    // }
  }, [edit, selectedSpace]);

  useEffect(() => {
    if (edit) {
      fetchStrategies();
      fetchClientStrategies();
    }
  }, [ruleId, selectedSpace]);

  return (
    <div
      style={{ overflow: 'hidden' }}
      className="blackout"
      onClick={(e) => {
        let el = e.target;
        if (el.classList.contains('blackout')) {
          setIsModal(false);
          setIsEdit(false);
          setEdit(false);
          onReset();
        }
      }}
    >
      <div
        className="modal-promotion-rules container"
        onClick={(e) => {
          let el = e.target;
          if (!el.closest('.promotion-rules-parametr')) {
            setIsModalParametr(false);
            // onChangeStrategy()
          }
        }}
      >
        <div className="modal-promotion-rules__header">
          <h1 className="title">
            {edit ? 'Редактирование правила' : 'Создание правила'}
          </h1>
          <div
            className="modal-promotion-rules__btn-close"
            onClick={(e) => {
              setIsModal(false);
              setIsEdit(false);
              setEdit(false);
              onReset();
            }}
          >
            <img src={close_icon} />
          </div>
        </div>

        <div className="modal-promotion-rules__toolbar-top">
          <div className="modal-promotion-rules__toogle-checkbox">
            <p className="modal-promotion-rules__text_label">
              Правило:
              {/* класс для красного цвета */}
              {/* modal-promotion-rules__text_status_disactive */}
              <span className="modal-promotion-rules__text_status_active">
                {' '}
                Активно
              </span>
            </p>
            <CheckboxToggle checked />
          </div>

          <ButtonBasic
            // disabled={
            //   !((filterValues[6]?.length > 0) & (filterValues[1]?.length > 0))
            // }
            green
            minWidth="172px"
            width="172px"
            maxWidth="172px"
            text={create ? 'Создать' : 'Сохранить'}
            style={{ height: '100%' }}
            onClick={(e) => {
              onCreate();
              if (!create) {
                onReset();
                setIsModal(false);
              }
            }}
          />
        </div>

        <div className="modal-promotion-rules__toolbar-medium">
          {filterData?.map((item) => (
            <FiltersWrapper
              key={item.id}
              id={item.id}
              type={item.type}
              service_id={service_id}
              placeholder={item.portal_name}
              selectedData={filterValues}
              onFilterChange={handleFilterChange}
            />
          ))}
        </div>

        <div className="modal-promotion-rules__content">
          <div className="rules-content">
            {!create || isEdit ? (
              <section className="rules-content__content">
                <div className="rules-content__tooltip">
                  Активируйте нужное правило переключателем или просто
                  перенесетие его вправо
                  <CheckboxToggle checked />
                </div>
                <div className="column-group">
                  <ColItemDrop
                    title="Доступные"
                    loading={loading}
                    arr={disactive}
                    onDragStart={(e) => dragStartHandler(e)}
                    onDragOver={(e) => dragOverHandler(e)}
                    onDrop={(e) => dropHandler(e, false)}
                    onStrategieToggle={(e) => onStrategieToggle(e)}
                    onGetParams={(e) => getClientParams(e)}
                  />

                  <ColItemDrop
                    title="Активные"
                    arr={active}
                    loading={loading}
                    onDragStart={(e) => dragStartHandler(e)}
                    onDragOver={(e) => dragOverHandler(e)}
                    onDrop={(e) => dropHandler(e, true)}
                    onStrategieToggle={(e) => onStrategieToggle(e)}
                    onGetParams={(e) => getClientParams(e)}
                    active={active}
                  />
                </div>
              </section>
            ) : null}
          </div>
        </div>

        {isModalParametr && (
          <div className="promotion-rules-parametr">
            <div className="promotion-rules-parametr__header">
              <h1 className="title_main">Параметры</h1>
            </div>

            <div className="promotion-rules-parametr__content">
              <h1 className="title">
                {strategyInfo?.name ? strategyInfo?.name : 'Название стратегии'}
              </h1>
              {strategyParams?.map(
                ({
                  portal_name,
                  name,
                  type,
                  description,
                  id,
                  attr_id,
                  value = '',
                }) => {
                  return (
                    <InputDinamycPlaceholder
                      onChange={(e) => {
                        if (e) {
                          setStrategyParamValue((prev) => {
                            let paramValue = value ? value : value?.value;
                            let paramType = type ? type : value?.type;

                            if (paramType === 'integer') {
                              paramValue = Number(e);
                            }
                            if (paramType === 'string') {
                              paramValue = String(e);
                            }

                            return {
                              ...prev,
                              [+id ? +id : attr_id]: {
                                type: paramType,
                                value: paramValue,
                              },
                            };
                          });
                        }
                      }}
                      placeholder={portal_name ? portal_name : name ? name : ''}
                      autoComplete={false}
                      changeValue={value?.value ? String(value?.value) : value}
                      size="s"
                    />
                  );
                }
              )}
              <ButtonBasic
                grey
                width="100%"
                text={strategyParams?.length < 1 ? 'ОК' : 'Сохранить'}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsModalParametr(false);
                  onChangeStrategy(strategyInfo);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { ModalRules };
